import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridColDef, GridRowParams, GridCellParams } from '@mui/x-data-grid-pro';
import md5 from "md5";
import Gravatar from "react-gravatar";
import { useLazyGetMarketingCohortSampleQuery } from '../Redux/Services/OhWaiter';
import { useCreateMarketingCampaignMutation } from '../Redux/Services/OhWaiter';
import { MarketingCampaignCreate } from '../Types';
import { CohortGenerator, CohortGuest } from '../Types';
import Main from '../Layouts/Main';
import LocationSelector from '../Components/LocationSelector';
import CohortWizard from '../Components/CohortWizard';
import CohortEditor from '../Components/CohortEditor';
import CampaignMonitor from '../Components/Campaigns';
import GuestView from '../Components/Modals/GuestView';
import { getRandomString } from '../Helpers/StringUtils';
import { apiSuccess, apiError } from '../Helpers/Toaster';
import { dataGridSx } from '../Constants';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

interface CohortGeneratorObject {
    type: string | null;
    values: CohortGenerator | null;
    rowKey: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
    >{value === index && (<Box mt={3}>{children}</Box>)}
    </div>;
}

export default function Marketing() {

    const blankGenerator: CohortGeneratorObject = {
        type: null,
        values: null,
        rowKey: getRandomString()
    };

    const [locationId, setLocationId] = useState<string>('');
    const [campaignName, setCampaignName] = useState<string>('');
    const [campaignDescription, setCampaignDescription] = useState<string>('');
    const [campaignMsgTemplate, setCampaignMsgTemplate] = useState<string>('');
    const [campaignIncludeOptOuts, setCampaignIncludeOptOuts] = useState<boolean>(false);
    const [cohortGenerators, setCohortGenerators] = useState<CohortGeneratorObject[]>([blankGenerator]);
    const [sampledGuests, setSampledGuests] = useState<CohortGuest[]>([]);
    const [guestViewId, setGuestViewId] = useState<string | null>(null);
    const closeGuestView = () => setGuestViewId(null);

    // Redux Selectors
    const [getMarketingCohortSample] = useLazyGetMarketingCohortSampleQuery();
    const [createMarketingCampaign] = useCreateMarketingCampaignMutation();

    // Tabs Setup
    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => setTabIndex(newValue);
    const changeTabIndex = (index: number) => setTabIndex(index);

    const addWizardRow = (index: number) => {
        let newGeneratorObject = [
            ...cohortGenerators.slice(0, index),
            { ...blankGenerator, rowKey: getRandomString() },
            ...cohortGenerators.slice(index)
        ];
        setCohortGenerators(newGeneratorObject);
    }

    const removeWizardRow = (index: number) => {
        let newGeneratorObject = [...cohortGenerators];
        newGeneratorObject.splice(index, 1);
        setCohortGenerators(newGeneratorObject);
    }

    const updateWizardRow = (index: number, type: string, values: any) => {
        let newGeneratorObject = [...cohortGenerators];
        newGeneratorObject[index] = { type, values, rowKey: cohortGenerators[index].rowKey };
        setCohortGenerators(newGeneratorObject);
    }

    const getSample = (event: any) => {
        const generatorsArray: CohortGenerator[] = cohortGenerators.map(obj => obj.values as CohortGenerator);
        getMarketingCohortSample({ locationId, cohortGenerators: generatorsArray })
            .unwrap()
            .then(result => setSampledGuests(result as CohortGuest[]))
    }

    const createCampaign = async () => {
        const generatorsArray: CohortGenerator[] = cohortGenerators.map(obj => obj.values as CohortGenerator);
        const newCampaignObject: MarketingCampaignCreate = {
            locationId,
            name: campaignName,
            desciption: campaignDescription,
            includeOptedout: campaignIncludeOptOuts,
            messageTemplate: campaignMsgTemplate,
            cohortGenerators: generatorsArray
        };
        clearCampaignForm();
        await createMarketingCampaign(newCampaignObject)
            .then(() => apiSuccess("Campaign Created!"))
            .catch((err) => apiError(err));
        changeTabIndex(1);
    }

    const changeCampaignName = (e: React.ChangeEvent<HTMLInputElement>) => setCampaignName(e.target.value);
    const changeCampaignDescription = (e: React.ChangeEvent<HTMLInputElement>) => setCampaignDescription(e.target.value);
    const changeCampaignMessageTemplate = (e: React.ChangeEvent<HTMLInputElement>) => setCampaignMsgTemplate(e.target.value);
    const toggleCampaignOptOutOverride = (e: React.ChangeEvent<HTMLInputElement>) => setCampaignIncludeOptOuts(!campaignIncludeOptOuts);

    const clearCampaignForm = () => {
        setCampaignName('');
        setCampaignDescription('');
        setCampaignMsgTemplate('');
    }

    const campaignCreateOK = (!!cohortGenerators[0].type && !!campaignName && !!campaignMsgTemplate);

    const clickSampleGuest = (params: GridRowParams) => {
        const { guestId } = params.row;
        setGuestViewId(guestId as string);
    }

    const avatarCell = (params: GridCellParams) => {
        const { guestId } = params.row;
        return <React.Fragment>
            <span><Gravatar
                size={30}
                style={{ margin: "7px 10px 0 6px", borderRadius: "50%" }}
                md5={md5(guestId)}
            /></span>
            <span>{guestId}</span>
        </React.Fragment>
    };

    const optInCell = (params: GridCellParams) => {
        const { optInStatus } = params.row;
        return <Grid container justifyContent="flex-end" mx={1}>
            <Stack direction="row" alignItems="center" spacing={3}>
                {optInStatus === 'opted-in'
                    ? <CheckCircleIcon fontSize="small" color="success" />
                    : <DoNotDisturbOnIcon fontSize="small" color="error" />
                }
            </Stack>
        </Grid>
    }

    const sampleColumns: GridColDef[] = [
        {
            field: 'guestId',
            headerName: 'Guest ID',
            flex: 1,
            renderCell: avatarCell
        },
        {
            field: 'optInStatus',
            headerName: 'Opt-In Status',
            width: 140,
            renderCell: optInCell
        }
    ];

    return <Main header="Marketing Campaigns">

        <GuestView
            guestId={guestViewId}
            open={!!guestViewId}
            handleClose={closeGuestView}
        />

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector selectionHandler={setLocationId} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        {/* Placeholder */}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>

                <Tabs value={tabIndex} onChange={handleTabChange} centered>
                    <Tab label="Campaign Builder" sx={{ fontWeight: "bold" }} />
                    <Tab label="Campaign Monitor" sx={{ fontWeight: "bold" }} />
                    <Tab label="Marketing Groups" sx={{ fontWeight: "bold" }} disabled={!!!locationId} />
                </Tabs>

                <SwipeableViews axis="x" index={tabIndex} onChangeIndex={changeTabIndex}>

                    {/* Build Campaign */}
                    <TabPanel value={tabIndex} index={0}><Grid container>
                        <Grid item xs={12}>

                            <Box my={2}>
                                <Typography variant="subtitle1" className="onboarding-header">Select Marketing Groups</Typography>
                            </Box>

                            <Stack my={2} direction="column" spacing={2}>
                                {cohortGenerators.map((generator, index) => <Grid container key={generator.rowKey}>
                                    <Grid container item xs={10.5} alignItems="center">
                                        <CohortWizard
                                            rowIndex={index}
                                            type={generator.type}
                                            values={generator.values}
                                            locationId={locationId}
                                            updater={updateWizardRow}
                                        />
                                    </Grid>
                                    <Grid container item xs={1.5} alignItems="center" justifyContent="flex-end">
                                        <Stack spacing={0} direction="row-reverse">
                                            <Box><IconButton onClick={() => addWizardRow(index + 1)} disabled={!!!generator.type}>
                                                <AddCircleIcon />
                                            </IconButton></Box>
                                            <Box><IconButton onClick={() => removeWizardRow(index)} disabled={index < 1}>
                                                <RemoveCircleIcon />
                                            </IconButton></Box>
                                        </Stack>
                                    </Grid>
                                </Grid>)}
                            </Stack>

                            <Box my={2}>
                                <Button
                                    variant="outlined"
                                    startIcon={<PeopleAltIcon />}
                                    fullWidth
                                    onClick={getSample}
                                >Sample Guests</Button>
                            </Box>

                            {sampledGuests && !!sampledGuests.length && <Box my={2}>
                                <DataGridPro
                                    columns={sampleColumns}
                                    rows={sampledGuests}
                                    getRowId={(row) => row.guestId}
                                    onRowClick={clickSampleGuest}
                                    disableSelectionOnClick
                                    sx={dataGridSx}
                                    autoHeight
                                    hideFooter
                                />
                            </Box>}

                            <Box my={2}>
                                <Typography variant="subtitle1" className="onboarding-header">Create Campaign</Typography>
                            </Box>

                            <Box my={2}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Campaign Name"
                                                variant="outlined"
                                                fullWidth
                                                value={campaignName}
                                                onChange={changeCampaignName}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Campaign Description"
                                                variant="outlined"
                                                fullWidth
                                                value={campaignDescription}
                                                onChange={changeCampaignDescription}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} container justifyContent="flex-end">
                                        <FormControlLabel
                                            label={<Typography variant="body2">Include Opted Out</Typography>}
                                            control={<Checkbox
                                                checked={campaignIncludeOptOuts}
                                                onChange={toggleCampaignOptOutOverride}
                                            />}
                                            sx={{ marginRight: "2px" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Message Template"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                value={campaignMsgTemplate}
                                                onChange={changeCampaignMessageTemplate}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box my={2}>
                                <Button
                                    variant="contained"
                                    endIcon={<ArrowCircleRightIcon />}
                                    fullWidth
                                    onClick={createCampaign}
                                    disabled={!campaignCreateOK}
                                >Create Campaign</Button>
                            </Box>

                        </Grid>
                    </Grid></TabPanel>

                    {/* Campaign Monitor */}
                    <TabPanel value={tabIndex} index={1}><Grid container>

                        <Grid item xs={12}>
                            <Stack mb={2}>
                                <Typography variant="subtitle1" className="onboarding-header">Campaigns</Typography>
                                {!!locationId && <CampaignMonitor
                                    locationId={locationId}
                                />}
                            </Stack>
                        </Grid>

                    </Grid></TabPanel>

                    {/* Marketing Groups */}
                    <TabPanel value={tabIndex} index={2}><Grid container>

                        <Grid item xs={12}>
                            {!!locationId && <CohortEditor
                                locationId={locationId}
                                guestId={null}
                            />}
                        </Grid>

                    </Grid></TabPanel>

                </SwipeableViews>

            </Grid>

        </Grid>

    </Main >

};