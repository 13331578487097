import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useGetLocationByIdQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Location as LocationType } from '../Types';
import Main from '../Layouts/Main';
import ZoneCard from '../Components/ZoneCard';
import ZoneEdit from '../Components/Modals/ZoneEdit';
import HelperText from '../Components/HelperText';

// Note: This page is currently unlinked. Its nature was too confusing per JC.

export default function Location() {

    let { location_id } = useParams();

    const dispatch = useDispatch();
    const [zoneEditOpen, setZoneEditOpen] = useState(false);
    const openZoneEdit = () => setZoneEditOpen(true);
    const closeZoneEdit = () => setZoneEditOpen(false);

    // Redux Selectors
    const id = (location_id) ? parseInt(location_id) : null;
    const locationId = location_id || null;
    const { data: locationData = null, isLoading: locationLoading } = useGetLocationByIdQuery({ locationId }, { skip: !!!locationId });
    const location = locationData as LocationType || {};
    const location_name = (location && location.name) ? location.name : '';
    const { zones, devices } = location;

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Location", status: locationLoading }));
    }, [dispatch, locationLoading]);

    // let sortedZones = [];
    // if (zones) sortedZones = zones
    //     .filter((item): item is any => !!item) // No undefineds
    //     .sort((a, b) => b.qrCount - a.qrCount); // Sort by qrCount

    return <Main header={location_name}>

        {id && <ZoneEdit locationId={id} open={zoneEditOpen} handleClose={closeZoneEdit} />}

        {id && <Grid container spacing={3}>

            {/* Helper Text */}
            <Grid item sm={12}>
                <HelperText>
                    <Typography variant="body2">On this page, you can configure OhWaiter for your location <strong>{location_name}</strong>. First, you'll want to create some <strong>Zones</strong>, which could be restaurant seating sections, club recreation areas, or any other general area in which guests might be found using OhWaiter at this location. Within each zone, you'll create <strong>Tags</strong>, which are the QR codes used to locate specific guests. A tag could be a table number, a room number, or any other identifier that will help you locate a specific guest. In the <strong>Sign Templates</strong> section, you can design and generate printable signs that these QR codes will then be embedded into.</Typography>
                </HelperText>
            </Grid>

            {/* Main Column */}
            <Grid item lg={9} md={10} sm={12}>

                {/* Zones */}
                <Grid container spacing={2}>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Typography variant="h6" className="bold-header">Zones</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Button variant="contained" startIcon={<AddCircleIcon />} onClick={openZoneEdit}>
                            Add Zone
                        </Button>
                    </Grid>
                    {zones && zones.map(zone => (
                        <Grid item xs={12} sm={12} md={4} key={zone.zoneId}>
                            <ZoneCard locationId={id} zoneId={zone.zoneId} zoneName={zone.name} qrCount={zone.qrCount} />
                        </Grid>
                    ))}
                </Grid>

                <Divider sx={{ my: 4 }} />

            </Grid>

            {/* Right Column */}
            <Grid item lg={3} md={2} sm={12}>

                {/* Devices Panel */}
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" className="bold-header">Devices</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {devices && devices.map(device => {
                            const { deviceId, deviceName, statusName, statusValue } = device;
                            let status_string = '';
                            if (statusName) switch (statusName.toLowerCase()) {
                                case 'charge level':
                                    status_string = ` (${statusValue}%)`;
                                    break;
                                case 'unknown':
                                    status_string = ` (Offline)`;
                                    break;
                            }
                            return <React.Fragment key={deviceId}>
                                <Divider />
                                <Box my={1}>
                                    {`${deviceName}${status_string}`}
                                </Box>
                            </React.Fragment>
                        })}
                    </AccordionDetails>
                </Accordion>

            </Grid>

        </Grid>}
    </Main>

};