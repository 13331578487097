import { createSlice } from '@reduxjs/toolkit';

export const recentLocation = createSlice({
    name: "recentLocation",
    initialState: {
        locationId: null,
        zoneId: null
    },
    reducers: {
        setRecentLocation: (state, action) => {
            state.locationId = action.payload
        },
        setRecentZone: (state, action) => {
            state.zoneId = action.payload
        }
    }
});

export const { setRecentLocation, setRecentZone } = recentLocation.actions;

export default recentLocation.reducer;