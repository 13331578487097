import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useGetMarketingCohortsQuery } from '../Redux/Services/OhWaiter';
import { useAddToMarketingCohortMutation } from '../Redux/Services/OhWaiter';
import { useRemoveFromMarketingCohortMutation } from '../Redux/Services/OhWaiter';
import { useCreateMarketingCohortMutation, useDeleteMarketingCohortMutation } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { CohortGuest } from '../Types';
import { apiSuccess, apiError } from '../Helpers/Toaster';

const CohortEditor: React.FC<{
    locationId: string;
    guestId: string | null;
}> = ({ locationId, guestId }) => {

    const dispatch = useDispatch();

    const [newGroupName, setNewGroupName] = useState<string>('');

    const [createMarketingCohort] = useCreateMarketingCohortMutation();
    const { data: cohortsData = null, isLoading: cohortsLoading } = useGetMarketingCohortsQuery({ locationId }, { skip: !!!locationId });
    const cohorts = useMemo(() => cohortsData as any[] || [], [cohortsData]);

    const [addToMarketingCohort] = useAddToMarketingCohortMutation();
    const [removeFromMarketingCohort] = useRemoveFromMarketingCohortMutation();
    const [deleteMarketingCohort] = useDeleteMarketingCohortMutation();

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Marketing Cohorts", status: cohortsLoading }));
    }, [dispatch, cohortsLoading]);

    const changeNewGroupName = (e: React.ChangeEvent<HTMLInputElement>) => setNewGroupName(e.target.value);

    const handleSaveCohort = async () => {
        const cohortObject = {
            locationId,
            name: newGroupName,
            guestIds: []
        };
        setNewGroupName('');
        await createMarketingCohort(cohortObject)
            .then(() => apiSuccess("Marketing Group Saved!"))
            .catch((err) => apiError(err));
    };

    const addGuestToCohort = async (cohortId: string) => {
        await addToMarketingCohort({ cohortId, guestIds: [guestId] })
            .then(() => apiSuccess("Added to Group!"))
            .catch((err) => apiError(err));
    };

    const removeGuestFromCohort = async (cohortId: string) => {
        await removeFromMarketingCohort({ cohortId, guestIds: [guestId] })
            .then(() => apiSuccess("Removed from Group!"))
            .catch((err) => apiError(err));
    };

    const handleDeleteCohort = async (cohortId: string) => {
        await deleteMarketingCohort({ locationId, cohortId })
            .then(() => apiSuccess("Marketing Group Deleted!"))
            .catch((err) => apiError(err));
    };

    const countCell = (params: GridCellParams) => params.row.guests.length;

    const actionsCell = (params: GridCellParams) => {
        const { cohortId, name } = params.row;
        const isInCohort = params.row.guests.map((obj: CohortGuest) => obj.guestId).includes(guestId);
        const addToGroup = () => addGuestToCohort(cohortId);
        const removeFromGroup = () => removeGuestFromCohort(cohortId);
        const deleteGroup = () => handleDeleteCohort(cohortId);
        const inColor = "#2e7d32";
        const outColor = "rgba(0, 0, 0, 0.26)";
        return <Grid container justifyContent="flex-end" mx={1}>
            <Stack direction="row" alignItems="center" spacing={3}>
                {!!guestId && <React.Fragment>{isInCohort
                    ? <Tooltip title={`Remove ${guestId} from ${name}`} placement="top" arrow>
                        <CheckBoxIcon fontSize="small" style={{ color: inColor, cursor: "pointer" }} onClick={removeFromGroup} />
                    </Tooltip>
                    : <Tooltip title={`Add ${guestId} to ${name}`} placement="top" arrow>
                        <CheckBoxIcon fontSize="small" style={{ color: outColor, cursor: "pointer" }} onClick={addToGroup} />
                    </Tooltip>
                }</React.Fragment>}
                <Tooltip title="Delete Group" placement="top" arrow>
                    <DeleteForeverIcon fontSize="small" color="error" style={{ cursor: "pointer" }} onClick={deleteGroup} />
                </Tooltip>
            </Stack>
        </Grid>
    }

    const cohortColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'guestIds',
            headerName: 'Guests Count',
            width: 120,
            renderCell: countCell
        },
        {
            field: 'cohortId',
            headerName: '',
            width: 120,
            renderCell: actionsCell
        }
    ];

    return <React.Fragment>

        <Stack direction="column" spacing={2}>

            <Box>
                <Box my={1}>
                    <Typography variant="subtitle1" className="onboarding-header">Create Group</Typography>
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={10}>
                        <FormControl fullWidth>
                            <TextField
                                label="Group Name"
                                variant="outlined"
                                fullWidth
                                value={newGroupName}
                                onChange={changeNewGroupName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} display="flex" flexDirection="row-reverse">
                        <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<AddCircleIcon />}
                            onClick={handleSaveCohort}
                        >Add</Button>
                    </Grid>
                </Grid>
            </Box>

            {!!cohorts && <Box>
                <Box my={1}>
                    <Typography variant="subtitle1" className="onboarding-header">Marketing Groups</Typography>
                </Box>
                <DataGridPro
                    columns={cohortColumns}
                    rows={cohorts || []}
                    getRowId={(row) => row.cohortId}
                    autoHeight
                    hideFooter
                />
            </Box>}

        </Stack>

    </React.Fragment>

};

export default CohortEditor;