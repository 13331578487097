import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CableIcon from '@mui/icons-material/Cable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Main from '../Layouts/Main';
import LocationSelector from '../Components/LocationSelector';
import HelperText from '../Components/HelperText';
import { useGetSquareStatusQuery, useGetSquareAuthUrlQuery } from '../Redux/Services/OhWaiter';
import { useGetSquareLocationInfoQuery, useGetSquareLocationsQuery } from '../Redux/Services/OhWaiter';
import { useSetSquareConnectionMutation } from '../Redux/Services/OhWaiter';
import { useAssociateSquareLocationMutation } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { SquareStatus, SquareLocationStatus, SquareAuthURL, SquareLocation } from '../Types';
import { apiSuccess, apiError } from '../Helpers/Toaster';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function OauthSquare() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = useQuery();
    const code = query.get("code");

    const [locationId, setLocationId] = useState<string>('');
    const [authURL, setAuthURL] = useState<string>('');

    // Redux Selectors
    const { data: OauthStatus = null, isLoading: StatusLoading } = useGetSquareStatusQuery({ locationId }, { skip: !!!locationId });
    const { data: OauthURLReturn = null, isLoading: URLLoading } = useGetSquareAuthUrlQuery({ locationId }, { skip: !!!locationId });
    const { data: SquareLocationsData = null, isLoading: LocationsLoading } = useGetSquareLocationsQuery({ locationId }, { skip: !!!locationId });
    const { data: SquareStatus = null, isLoading: LocationLoading } = useGetSquareLocationInfoQuery({ locationId }, { skip: !!!locationId });
    const squareStatus = useMemo(() => OauthStatus as SquareStatus || [], [OauthStatus]);
    const squareLocations = useMemo(() => SquareLocationsData as SquareLocation[] || [], [SquareLocationsData]);
    const squareLocationStatus = useMemo(() => SquareStatus as SquareLocationStatus || {}, [SquareStatus]);
    const [setSquareConnection] = useSetSquareConnectionMutation();
    const [associateSquareLocation] = useAssociateSquareLocationMutation();

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Square Status", status: StatusLoading }));
        dispatch(addLoader({ loader: "Square Auth System", status: URLLoading }));
        dispatch(addLoader({ loader: "Location Info", status: LocationLoading }));
        dispatch(addLoader({ loader: "Square Locations", status: LocationsLoading }));
    }, [dispatch, StatusLoading, URLLoading, LocationLoading, LocationsLoading]);

    useEffect(() => {
        const ret = OauthURLReturn as SquareAuthURL;
        if (ret && ret.url) setAuthURL(ret.url);
    }, [OauthURLReturn]);

    useEffect(() => {
        if (!!locationId && !!code) {
            setSquareConnection({ locationId, code })
                .then(() => navigate('./'))
                .catch((err) => apiError(err));
        }
    }, [locationId, code]); // eslint-disable-line react-hooks/exhaustive-deps

    const doSquareAssociation = async (squareLocationId: string) => {
        if (!!locationId && !!squareLocationId) {
            await associateSquareLocation({ locationId, squareLocationId })
                .then(() => apiSuccess("Location Connected!"))
                .catch((err) => apiError(err));
        }
    };

    return <Main header="Square Connect">

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector selectionHandler={setLocationId} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        {/* Placeholder */}
                    </Grid>
                </Grid>
            </Grid>

            {(squareStatus.isConnected && squareLocationStatus.isReady)
                ? <Grid item xs={12}>
                    <Box mt={1}>
                        <HelperText variant="success">
                            <Typography variant="body2">Your Square location is now synchronized with OhWaiter!</Typography>
                        </HelperText>
                    </Box>
                </Grid>
                : <Grid item xs={12}>
                    <Box mt={1}>
                        <HelperText>
                            <Typography variant="body2"><strong>Synchronizing OhWaiter and Square is a two-step process.</strong><br />
                                First, connect your Square account to OhWaiter.<br />
                                Then, associate this OhWaiter location with a location that you have configured in Square.</Typography>
                        </HelperText>
                    </Box>
                </Grid>
            }

            <Grid item xs={12}>
                {squareStatus.isConnected
                    ? <Button
                        fullWidth
                        variant="outlined"
                        color="success"
                        startIcon={<CheckCircleIcon />}
                        href={authURL}
                        disabled={!!!authURL}
                        className="disable-click"
                    >Account Connected!</Button>
                    : <Button
                        fullWidth
                        variant="contained"
                        startIcon={<CableIcon />}
                        href={authURL}
                        disabled={!squareStatus.isReadyToConnect}
                    >Connect Square Account</Button>
                }
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h6" className="bold-header">Associate this Location to a Square Location:</Typography>
                {(!!!squareLocations || squareLocations.length < 1) && <Box mt={1}>
                    <HelperText variant="error">
                        <Typography variant="body2">No Square locations available. Connect your Square account to see your Square locations.</Typography>
                    </HelperText>
                </Box>}
            </Grid>

            {squareLocations && squareLocations.map(location => {

                let isDisabled = false;
                let isGrey = false;
                const isConnected = (squareLocationStatus.isReady && (squareLocationStatus.squareLocationId === location.squareLocationId));
                if (squareLocationStatus.isReady) isDisabled = true;
                if (squareLocationStatus.isReady && !isConnected) isGrey = true;

                return <Grid item xs={12} sm={12} md={4} key={location.squareLocationId}>
                    <Card className={isGrey ? "disabled-opacity" : ""}>
                        <CardActionArea disabled={isDisabled} onClick={() => doSquareAssociation(location.squareLocationId)}>
                            <CardContent>
                                <Typography variant="h6" className="bold-header">{location.name}</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {location.squareLocationId}
                                </Typography>
                                <Box mt={1}>{isConnected
                                    ? <Button
                                        fullWidth
                                        variant="outlined"
                                        color="success"
                                        startIcon={<CheckCircleIcon />}
                                        sx={{ pointerEvents: "none" }}
                                    >Associated!</Button>
                                    : <Button
                                        fullWidth
                                        variant="outlined"
                                        color="inherit"
                                        startIcon={<CableIcon />}
                                        sx={{ pointerEvents: "none" }}
                                    >Associate</Button>
                                }</Box>
                            </CardContent>

                        </CardActionArea>
                    </Card>
                </Grid>
            })}

        </Grid>

    </Main >

}