import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useGetMarketingCohortsQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { explodeInput } from '../Helpers/StringUtils';

const PaidOrderWithinDaysFields: React.FC<{
    fieldValues: any;
    update: (type: string, values: any) => void;
}> = ({ fieldValues, update }) => {

    const [daysValue, setDaysValue] = useState<string>((fieldValues && fieldValues.days) ? fieldValues.days : '5');
    const [menuItems, setMenuItems] = useState<string>((fieldValues && fieldValues.menuItems) ? fieldValues.menuItems : '');

    const changeDaysValue = (e: React.ChangeEvent<HTMLInputElement>) => setDaysValue(e.target.value);
    const changeMenuItems = (e: React.ChangeEvent<HTMLInputElement>) => setMenuItems(e.target.value);

    useEffect(() => {
        const itemNames = explodeInput(menuItems);
        update('PaidOrderWithinDays', { role: 'PaidOrderWithinDays', withInDays: parseInt(daysValue), itemNames });
    }, [daysValue, menuItems]);  // eslint-disable-line react-hooks/exhaustive-deps

    return <React.Fragment>

        <Grid item xs={2}>
            <FormControl fullWidth>
                <TextField
                    label="Days"
                    variant="outlined"
                    fullWidth
                    value={daysValue}
                    onChange={changeDaysValue}
                />
            </FormControl>
        </Grid>
        <Grid item xs={6}>
            <FormControl fullWidth>
                <TextField
                    label="Menu Items"
                    variant="outlined"
                    fullWidth
                    value={menuItems}
                    onChange={changeMenuItems}
                />
            </FormControl>
        </Grid>

    </React.Fragment>

};

const CustomCohortFields: React.FC<{
    fieldValues: any;
    locationId: string;
    update: (type: string, values: any) => void;
}> = ({ fieldValues, locationId, update }) => {

    const dispatch = useDispatch();
    const [selectedCohort, setSelectedCohort] = useState<string>((fieldValues && fieldValues.cohortId) ? fieldValues.cohortId : '');
    const changeCustomCohort = (e: any) => setSelectedCohort(e.target.value);

    const { data: cohortsData = null, isLoading: cohortsLoading } = useGetMarketingCohortsQuery({ locationId }, { skip: !!!locationId });
    const cohorts = useMemo(() => cohortsData as any[] || [], [cohortsData]);

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Marketing Groups", status: cohortsLoading }));
    }, [dispatch, cohortsLoading]);

    useEffect(() => {
        update('CustomCohort', { role: 'CustomCohort', cohortId: selectedCohort });
    }, [selectedCohort]);  // eslint-disable-line react-hooks/exhaustive-deps

    return <React.Fragment>

        <Grid item xs={8}>
            <FormControl fullWidth>
                <InputLabel>Custom Group</InputLabel>
                <Select label="Custom Cohort" value={selectedCohort} onChange={changeCustomCohort} disabled={!!!cohorts}>
                    {cohorts.map(cohort => <MenuItem value={cohort.cohortId} key={cohort.cohortId}>
                        {cohort.name} ({cohort.guests.length})
                    </MenuItem>)}
                </Select>
            </FormControl>
        </Grid>

    </React.Fragment>

};

const CohortWizard: React.FC<{
    type: string | null;
    values: any;
    locationId: string;
    rowIndex: number;
    updater: (rowIndex: number, type: string, values: any) => void;
}> = ({ type: cohortType, values: cohortValues, locationId, rowIndex, updater }) => {

    const [newType, setNewType] = useState<string>(cohortType ? cohortType : '');
    const changeCohortType = (e: any) => setNewType(e.target.value);

    const handleUpdate = (type: string, values: any) => updater(rowIndex, type, values);
    useEffect(() => { if (newType === '') updater(rowIndex, '', null); }, [newType]); // eslint-disable-line react-hooks/exhaustive-deps

    return <Grid container spacing={2}>
        <Grid item xs={!!newType ? 4 : 12}>
            <FormControl fullWidth>
                <InputLabel>Group Type</InputLabel>
                <Select label="Group Type" value={newType} onChange={changeCohortType}>
                    <MenuItem value="PaidOrderWithinDays">
                        Recent Paid Order
                    </MenuItem>
                    <MenuItem value="CustomCohort">
                        Custom Group
                    </MenuItem>
                </Select>
            </FormControl>
        </Grid>
        {(newType === 'PaidOrderWithinDays') && <PaidOrderWithinDaysFields fieldValues={cohortValues} update={handleUpdate} />}
        {(newType === 'CustomCohort') && <CustomCohortFields fieldValues={cohortValues} locationId={locationId} update={handleUpdate} />}
    </Grid>

};

export default CohortWizard;