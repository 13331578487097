import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Location from './Pages/Location';
import Zone from './Pages/Zone';
import Zones from './Pages/Zones';
import Tags from './Pages/Tags';
import Replies from './Pages/Replies';
import Buttons from './Pages/Buttons';
import Signs from './Pages/Signs';
import Pdfs from './Pages/PDFs';
import Marketing from './Pages/Marketing';
import QRGenerator from './Pages/QRGenerator';
import Menu from './Pages/Menu';
import Modifiers from './Pages/Modifiers';
import Guests from './Pages/Guests';
import GuestDetail from './Pages/Guest';
import Transcripts from './Pages/Transcripts';
import Transcript from './Pages/Transcript';
import Validation from './Pages/Validation';
import Settings from './Pages/Settings';
import Reports from './Pages/Reports';
import OauthSquare from './Pages/OauthSquare';
import OauthIACM from './Pages/OauthIACM';
import { LogIn, LogOut, LoggedOut } from './Pages/LoginOut';
import './App.scss';

export default function App() {

    return <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/logout" element={<LogOut />} />
            <Route path="/curb" element={<LoggedOut />} />
            <Route path="/zones" element={<Zones />} />
            <Route path="/tags" element={<Tags />} />
            <Route path="/replies" element={<Replies />} />
            <Route path="/buttons" element={<Buttons />} />
            <Route path="/signs" element={<Signs />} />
            <Route path="/pdfs" element={<Pdfs />} />
            <Route path="/marketing" element={<Marketing />} />
            <Route path="/qr" element={<QRGenerator />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/modifiers" element={<Modifiers />} />
            <Route path="/validate" element={<Validation />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/guests" element={<Guests />} />
            <Route path="/guests/:guest_id" element={<GuestDetail />} />
            <Route path="/transcripts" element={<Transcripts />} />
            <Route path="/transcripts/:location_id/:guest_id/:conversation_id" element={<Transcript />} />
            <Route path="/location/:location_id/zone/:zone_id" element={<Zone />} />
            <Route path="/location/:location_id" element={<Location />} />
            <Route path="/square/oauth" element={<OauthSquare />} />
            <Route path="/iacm/oauth" element={<OauthIACM />} />
        </Routes>
    </Router>

}