import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridToolbar, GridColDef, GridCellParams } from '@mui/x-data-grid-pro';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Tooltip from '@mui/material/Tooltip';
import { useGetMenuItemsByLocationIdQuery, useGetMenuMetaByLocationIdQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { MenuItem as MenuItemType, MenuMeta } from '../Types';
import Main from '../Layouts/Main';
import LocationSelector from '../Components/LocationSelector';
import SynonymEdit from '../Components/Modals/SynonymEdit';
import { moneyFormat } from '../Helpers/Money';

export default function Menu() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [locationId, setLocationId] = useState<string>('');
    const [selectedMenu, setSelectedMenu] = useState<string>('All');
    const [filteredMenu, setFilteredMenu] = useState<MenuItemType[]>([]);

    // Redux Selectors
    const { data: menuData = null, isLoading: menuLoading } = useGetMenuItemsByLocationIdQuery({ locationId }, { skip: !!!locationId });
    const { data: menusMetaData = null, isLoading: menuMetaLoading } = useGetMenuMetaByLocationIdQuery({ locationId }, { skip: !!!locationId });

    const menu = useMemo(() => menuData as MenuItemType[] || [], [menuData]);
    const menusMeta = useMemo(() => menusMetaData as MenuMeta[] || [], [menusMetaData]);
    const menuList = useMemo(() => menusMeta.map(m => m.name), [menusMeta]);

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Menu Items", status: menuLoading }));
        dispatch(addLoader({ loader: "Menu Info", status: menuMetaLoading }));
    }, [dispatch, menuLoading, menuMetaLoading]);

    // Filter Data Grid
    useEffect(() => {
        if (selectedMenu.toLowerCase() === 'all') setFilteredMenu(menu);
        else setFilteredMenu(menu.filter(item => item.menus?.includes(selectedMenu)));
    }, [menu, selectedMenu]);

    const [editMenuItem, setEditMenuItem] = useState<string>('');
    const [editSynonyms, setEditSynonyms] = useState<string[]>([]);
    const [editOpen, setEditOpen] = useState(false);
    const openEdit = () => setEditOpen(true);
    const closeEdit = () => setEditOpen(false);

    const handleMenuSelect = (event: SelectChangeEvent) => setSelectedMenu(event.target.value);

    const priceCell = (params: GridCellParams) => moneyFormat(params.row.priceInCents);
    const menusCell = (params: GridCellParams) => params.row.menus.join(", ");
    const synonymsCell = (params: GridCellParams) => params.row.synonyms.join(", ");

    const actionsCell = (params: GridCellParams) => {
        const validateAction = () => {
            const encodedName = encodeURIComponent(params.row.name);
            navigate(`/validate/?item=${encodedName}`, { replace: true })
        }
        const editAction = () => {
            setEditMenuItem(params.row.name);
            setEditSynonyms(params.row.synonyms);
            openEdit();
        }
        return <Grid container justifyContent="flex-end" mx={1}>
            <Stack direction="row" alignItems="center" spacing={3}>
                <Tooltip title="Test (Validation)" placement="top" arrow>
                    <FactCheckIcon fontSize="small" style={{ cursor: "pointer" }} onClick={validateAction} />
                </Tooltip>
                <Tooltip title="Edit Synonyms" placement="top" arrow>
                    <ModeEditIcon fontSize="small" style={{ cursor: "pointer" }} onClick={editAction} />
                </Tooltip>
            </Stack>
        </Grid>
    };

    const menuColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Menu Item',
            width: 350
        },
        {
            field: 'priceInCents',
            headerName: 'Price',
            width: 80,
            renderCell: priceCell
        },
        {
            field: 'menus',
            headerName: 'Menus',
            flex: 1,
            renderCell: menusCell
        },
        {
            field: 'synonyms',
            headerName: 'Synonyms',
            flex: 2,
            renderCell: synonymsCell
        },
        {
            field: 'templateId',
            headerName: '',
            width: 120,
            renderCell: actionsCell
        }
    ];

    return <Main header="Menu Items">

        {locationId && <SynonymEdit
            locationId={parseInt(locationId)}
            menuItem={editMenuItem}
            existingSynonyms={editSynonyms}
            open={editOpen}
            handleClose={closeEdit}
        />}

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector selectionHandler={setLocationId} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                {menuList.length > 0 && <FormControl variant="standard" sx={{ minWidth: 180 }}>
                                    <InputLabel>Menu</InputLabel>
                                    <Select label="Location" value={selectedMenu} onChange={handleMenuSelect}>
                                        <MenuItem value="All">All Items</MenuItem>
                                        {menuList.map(menu =>
                                            <MenuItem key={menu} value={menu}>{menu}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>}
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <DataGridPro
                            columns={menuColumns}
                            rows={(!locationId) ? [] : filteredMenu}
                            getRowId={(row) => row.name}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: "name", sort: "asc" }],
                                },
                            }}
                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 250 },
                                    csvOptions: { disableToolbarButton: true },
                                    printOptions: { disableToolbarButton: true }
                                }
                            }}
                            disableColumnSelector
                            disableColumnFilter
                            disableDensitySelector
                            autoHeight
                            hideFooter
                        />

                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    </Main >

};