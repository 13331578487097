import React, { useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import { useDeleteZoneMutation } from '../Redux/Services/OhWaiter';
import ZoneEdit from '../Components/Modals/ZoneEdit';
import { apiSuccess, apiError } from '../Helpers/Toaster';

type CardProps = {
    locationId: number;
    zoneId?: string;
    zoneName?: string;
    qrCount?: number;
};

const ZoneCard: React.FC<CardProps> = ({ locationId, zoneId, zoneName, qrCount }) => {

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const [zoneEditOpen, setZoneEditOpen] = React.useState(false);
    // const openZoneEdit = () => { handleClose(); setZoneEditOpen(true); }
    const closeZoneEdit = () => { handleClose(); setZoneEditOpen(false); }
    const goToZoneAdmin = useCallback(() => navigate(`/location/${locationId}/zone/${zoneId}`, { replace: true }), [navigate, locationId, zoneId]);

    const [deleteZone] = useDeleteZoneMutation();
    const disableDelete = !!(qrCount && qrCount > 0);
    const handleDelete = async () => {
        handleClose();
        await deleteZone({ locationId, zoneId })
            .then(() => apiSuccess("Zone Deleted!"))
            .catch((err) => apiError(err));
    };

    return <React.Fragment>
        <ZoneEdit locationId={locationId} zoneId={zoneId} open={zoneEditOpen} handleClose={closeZoneEdit} />
        <Card>
            <CardActionArea component={Link} to={`/tags?location=${locationId}&zone=${zoneId}`}>
                <CardContent>
                    <TableRestaurantIcon fontSize="medium" />
                    <TableRestaurantIcon fontSize="medium" />
                    <TableRestaurantIcon fontSize="medium" />
                    <Typography variant="h6">{zoneName}</Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`${qrCount} Tags`}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <IconButton onClick={handleClick} sx={{ marginLeft: "auto" }}>
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={goToZoneAdmin}>Admin</MenuItem>
                {/* <MenuItem onClick={openZoneEdit} disabled>Edit</MenuItem> */}
                <MenuItem onClick={handleDelete} disabled={disableDelete}>Delete</MenuItem>
            </Menu>
        </Card>
    </React.Fragment>

};

export default ZoneCard;