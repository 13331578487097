import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
    name: "loadingSlice",
    initialState: {
        loaders: {}
    },
    reducers: {
        addLoader: (state, action) => {
            const { loader, status } = action.payload;
            state.loaders[loader] = status;
        }
    }
});

export const { addLoader } = loadingSlice.actions;

export default loadingSlice.reducer;