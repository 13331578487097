import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { portalApi } from './Services/OhWaiter';
import { validationSvc } from './Services/Validation';
import adminSlice from './Slices/Admin';
import userSlice from './Slices/User';
import loadingSlice from './Slices/Loading';
import recentLocation from './Slices/RecentLocation';
import { signMiddleware } from './Middleware/SignMiddleware';

export const Store = configureStore({
    reducer: {
        [portalApi.reducerPath]: portalApi.reducer,
        [validationSvc.reducerPath]: validationSvc.reducer,
        adminSlice,
        userSlice,
        loadingSlice,
        recentLocation
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        portalApi.middleware,
        validationSvc.middleware,
        signMiddleware
    )
});

setupListeners(Store.dispatch);