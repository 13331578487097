import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useGetLocationByIdQuery } from '../../Redux/Services/OhWaiter';
import { useSaveZoneMutation } from '../../Redux/Services/OhWaiter';
import { Location } from '../../Types';
import { apiSuccess, apiError } from '../../Helpers/Toaster';
import { smallModalSx } from '../../Constants';

type ModalProps = {
    locationId: number;
    zoneId?: string | null;
    open: boolean;
    handleClose: (open: boolean) => void;
};

const ZoneEdit: React.FC<ModalProps> = ({ locationId, zoneId, open, handleClose }) => {

    // Component State
    const [zoneName, setZoneName] = useState('');

    // Redux Selectors
    const { data: locationData = null } = useGetLocationByIdQuery({locationId}, { skip: !!!locationId });
    const [saveZone] = useSaveZoneMutation();

    useEffect(() => {
        // Either edit a zone or create a new one, depending on presence of zoneId:
        const location = locationData as Location || null;
        if (location && location.zones && zoneId) {
            const thisZone = location.zones.find(zone => zone.zoneId === zoneId);
            if (thisZone && thisZone.name) setZoneName(thisZone.name);
        } else {
            setZoneName('');
        }
    }, [locationData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setZoneName(e.target.value);
    };

    const handleSave = async () => {
        await saveZone({ locationId: locationId.toString(), zoneName })
            .then(() => apiSuccess("Zone Created!"))
            .catch((err) => apiError(err));
        handleClose(false);
    };

    const handleDelete = async () => {
        console.log("Delete Zone"); // Placeholder
    };

    return <Modal open={open} onClose={handleClose}>
        <Box sx={smallModalSx}>
            <Stack spacing={2}>
                <Typography variant="h6" component="h2" className="bold-header">
                    {(!!zoneId) ? 'Edit Zone' : 'New Zone'}
                </Typography>
                <Box component="form" sx={{ my: 3 }} noValidate autoComplete="off">
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={zoneName}
                        onChange={handleNameChange}
                    />
                </Box>
                <Grid container sx={{ mt: 3 }}>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Stack direction="row" spacing={1}>
                            {zoneId && <Button variant="outlined" color="error" onClick={handleDelete}>
                                Delete
                            </Button>}
                        </Stack>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" onClick={() => handleClose(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={handleSave} disabled={!!zoneId}>
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    </Modal>

};

export default ZoneEdit;