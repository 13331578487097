import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import { useGetUserSettingsQuery, useSaveUserSettingsMutation } from '../Redux/Services/OhWaiter';
import { useGetCustomerSettingsQuery, useSaveCustomerSettingsMutation } from '../Redux/Services/OhWaiter';
import { useGetLocationSettingsQuery, useSaveLocationSettingsMutation } from '../Redux/Services/OhWaiter';
import { UserSettings } from '../Types';
import { explodeInput } from '../Helpers/StringUtils';
import HelperText from './HelperText';

type SettingProp = {
    settingName: string;
    settingLabel: string;
    jsonArray?: boolean;
    description?: React.ReactNode;
    locationId?: string;
    settingType?: string;
};

const Setting: React.FC<SettingProp> = ({ settingName, settingLabel, jsonArray, description, locationId, settingType }) => {

    const customerId = useSelector((state: any) => state.userSlice.customerId);
    const [currentValue, setCurrentValue] = useState('');
    const [fieldValue, setFieldValue] = useState('');
    const [fieldSaved, setFieldSaved] = useState('');
    const [fieldInitted, setFieldInitted] = useState(false);

    // Redux Selectors (User Settings)
    const { data: userSettingsData = null } = useGetUserSettingsQuery(null);
    const userSettings = useMemo(() => userSettingsData as UserSettings || {}, [userSettingsData]);
    const [saveUserSettings] = useSaveUserSettingsMutation();

    // Redux Selectors (Customer Settings)
    const { data: customerSettingsData = null } = useGetCustomerSettingsQuery({ customerId }, { skip: !!!customerId });
    const customerSettings = useMemo(() => customerSettingsData as UserSettings || {}, [customerSettingsData]);
    const [saveCustomerSettings] = useSaveCustomerSettingsMutation();

    // Redux Selectors (Location Settings)
    const { data: locationSettingsData = null } = useGetLocationSettingsQuery({ locationId }, { skip: !!!locationId });
    const locationSettings = useMemo(() => locationSettingsData as UserSettings || {}, [locationSettingsData]);
    const [saveLocationSettings] = useSaveLocationSettingsMutation();

    // Load Existing Value per Setting Type
    useEffect(() => {
        if (!!!settingType || settingType.toLowerCase() === 'user') {
            if (settingName in userSettings) {
                const curValue = !!jsonArray ? JSON.parse(userSettings[settingName]) : userSettings[settingName];
                setCurrentValue(curValue);
            } else clearField();
        } else if (settingType.toLowerCase() === 'customer' && !!customerId) {
            if (settingName in customerSettings) {
                const curValue = !!jsonArray ? JSON.parse(customerSettings[settingName]) : customerSettings[settingName];
                setCurrentValue(curValue);
            } else clearField();
        } else if (settingType.toLowerCase() === 'location' && !!locationId) {
            if (settingName in locationSettings) {
                const curValue = !!jsonArray ? JSON.parse(locationSettings[settingName]) : locationSettings[settingName];
                setCurrentValue(curValue);
            } else clearField();
        } else {
            clearField();
        }
    }, [locationSettings, customerSettings, userSettings, locationId, customerId, settingName, settingType, jsonArray]);

    // Init Field
    useEffect(() => {
        if (!!currentValue && fieldValue === '') {
            const initValue = (!!jsonArray && Array.isArray(currentValue)) ? currentValue.join(", ") : currentValue;
            setFieldValue(initValue);
        }
    }, [currentValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const clearField = () => {
        setFieldValue('');
        setCurrentValue('');
    }

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(e.target.value);

    const handleSave = async () => {
        let settingValue = fieldValue;
        if (!!jsonArray) {
            const explodedLines = explodeInput(fieldValue);
            settingValue = JSON.stringify(explodedLines);
        }
        if (!!!settingType || settingType.toLowerCase() === 'user') await saveUserSettings({ [settingName]: settingValue });
        else if (settingType.toLowerCase() === 'customer' && !!customerId)
            await saveCustomerSettings({ customerId, settingsObject: { [settingName]: settingValue } });
        else if (settingType.toLowerCase() === 'location' && !!locationId)
            await saveLocationSettings({ locationId, settingsObject: { [settingName]: settingValue } });
        setFieldInitted(true);
        setFieldSaved(fieldValue);
    };

    return <Box component="form" noValidate autoComplete="off">
        <Stack direction="column" spacing={1}>

            <Grid container>
                <Grid item xs={10.6} display="flex" flexDirection="row" pr={1}>
                    <TextField
                        label={settingLabel}
                        variant="outlined"
                        fullWidth
                        multiline
                        value={fieldValue}
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={1.4} display="flex" flexDirection="row-reverse">
                    {((fieldSaved === fieldValue) && fieldInitted)
                        ? <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<CheckIcon />}
                            color="success"
                            onClick={handleSave}
                        >Saved</Button>
                        : <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<SaveIcon />}
                            onClick={handleSave}
                        >Save</Button>
                    }
                </Grid>
            </Grid>

            {!!description && <HelperText>{description}</HelperText>}

        </Stack>
    </Box>

};

export default Setting;