import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';
import html2canvas from 'html2canvas';
import { CountedWord } from '../Types';
import { stripPunctuation } from '../Helpers/StringUtils';
import { wordCloudFiltered } from '../Constants';

import './WordCloud.scss';

interface WordData {
    text: string;
    value: number;
}

export default function WordCloudComponent({ width, height, words, filename }: {
    width: number;
    height: number;
    words: CountedWord[];
    filename: string;
}) {

    const downloadRef = React.useRef<HTMLDivElement>(null);

    let WordData: WordData[] = [];
    words.forEach(word => {
        const cleanWord = stripPunctuation(word.word);
        const numberCount = (cleanWord.match(/\d/g) || []).length;
        if (!wordCloudFiltered.includes(cleanWord)
            && (cleanWord.length > 2)
            && (numberCount < cleanWord.length / 2)
            && (numberCount <= 4)
        ) {
            const foundIndex = WordData.findIndex(word => word.text === cleanWord);
            if (foundIndex >= 0) WordData[foundIndex].value += word.count;
            else WordData.push({
                text: cleanWord,
                value: word.count
            });
        }
    });

    const fontScale = scaleLog({
        domain: [Math.min(...WordData.map((w) => w.value)), Math.max(...WordData.map((w) => w.value))],
        range: [10, 100],
    });

    const fontSizeSetter = (datum: WordData) => fontScale(datum.value);

    const fixedValueGenerator = () => 0.5;

    const colors = ['#125393', '#1976d2', '#5e9fe0', '#8cbbe9'];

    const downloadImage = async () => {
        const element = downloadRef.current as HTMLDivElement;
        const canvas = await html2canvas(element);
        const imageData = canvas.toDataURL("image/jpg");
        const linkElement = document.createElement("a");
        if (typeof linkElement.download === "string") {
            linkElement.href = imageData;
            linkElement.download = `${filename}.jpg`;
            document.body.appendChild(linkElement);
            linkElement.click();
            document.body.removeChild(linkElement);
        } else {
            window.open(imageData);
        }
    };

    return <React.Fragment>
        <Box mb={2}>
            <div className="headerWithIconButton">
                <FileDownloadIcon onClick={downloadImage} />
                <div><Typography variant="h6" className="bold-header">Word Cloud</Typography></div>
            </div>
        </Box>
        <Box>
            <div className="wordcloud" style={{ width: `${width}px` }} ref={downloadRef}>
                <Wordcloud
                    words={WordData}
                    width={width}
                    height={height}
                    fontSize={fontSizeSetter}
                    font={'Impact'}
                    padding={2}
                    spiral={'archimedean'}
                    rotate={0}
                    random={fixedValueGenerator}
                >{(WordData) => WordData.map((w, i) => (
                    <Text
                        key={w.text}
                        fill={colors[i % colors.length]}
                        textAnchor={'middle'}
                        transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                        fontSize={w.size}
                        fontFamily={w.font}
                    >{w.text}</Text>
                ))}</Wordcloud>
            </div>
        </Box>
    </React.Fragment>;
}