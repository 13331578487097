import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useGetCustomersQuery } from '../Redux/Services/OhWaiter';
import { useGetUserSettingsQuery, useSaveUserSettingsMutation } from '../Redux/Services/OhWaiter';
import { setCustomerId } from '../Redux/Slices/Admin';
import { addLoader } from '../Redux/Slices/Loading';
import { Customer, UserSettings } from '../Types';

export default function Impersonation() {

    const dispatch = useDispatch();
    const customerId = useSelector((state: any) => state.adminSlice.customerId);
    const [selectedCustomerId, setSelectedCustomerId] = useState<string>(!!customerId ? customerId.toString() : '');

    // Redux Selectors
    const { data: userSettingsData = null, isLoading: settingsLoading } = useGetUserSettingsQuery(null);
    const { data: customersData = null, isLoading: customersLoading } = useGetCustomersQuery(null);
    const settings = useMemo(() => userSettingsData as UserSettings || {}, [userSettingsData]);
    const customers = useMemo(() => customersData as Customer[] || [], [customersData]);
    const [saveSettings] = useSaveUserSettingsMutation();

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Settings", status: settingsLoading }));
        dispatch(addLoader({ loader: "Customers", status: customersLoading }));
    }, [dispatch, settingsLoading, customersLoading]);

    // Set & Remember Impersonation:
    useEffect(() => {
        if (!!selectedCustomerId) {
            dispatch(setCustomerId(parseInt(selectedCustomerId)));
            saveSettings({ "lastImpersonated": selectedCustomerId });
        }
    }, [selectedCustomerId]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!!!selectedCustomerId && !!customerId) setSelectedCustomerId(customerId.toString());
    }, [selectedCustomerId, customerId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Restore Recently Impersonated:
    useEffect(() => {
        if (!!!selectedCustomerId && settings && settings.lastImpersonated) {
            setSelectedCustomerId(settings.lastImpersonated);
        }
    }, [settings]); // eslint-disable-line react-hooks/exhaustive-deps

    // Update Drop-Down if Impersonation Changes Elsewhere:
    useEffect(() => {
        if (customerId && customerId.toString() !== selectedCustomerId) {
            setSelectedCustomerId(customerId.toString());
        }
    }, [customerId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCustomerSelect = (event: SelectChangeEvent) => {
        setSelectedCustomerId(event.target.value);
    };

    return <FormControl size="small" fullWidth>
        <InputLabel>Impersonate</InputLabel>
        <Select label="Impersonate" value={selectedCustomerId} onChange={handleCustomerSelect}>
            {customers.map(customer => <MenuItem key={customer.id} value={customer.id}>
                {customer.name}
            </MenuItem>)}
        </Select>
    </FormControl>

};