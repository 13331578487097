import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import { useGetLocationsQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Location } from '../Types';
import Main from '../Layouts/Main';
import Setting from '../Components/Setting';
import ObjectBuilder from '../Components/ObjectBuilder';

const CustomerSettings = () => <React.Fragment>

    <Typography variant="body1"><strong>Chat Transcripts</strong></Typography>

    <Setting
        settingLabel="Quick Bucket Buttons"
        settingName="transcriptUserTags"
        settingType="customer"
        jsonArray={true}
        description={<Typography variant="body2">Enter a list of comma separated bucket names here, and you'll be able to place chats into them with a single click while reviewing transcripts.</Typography>}
    />

    {/* <Divider /> */}

</React.Fragment>

const LocationSettings: React.FC<{ locationId: string }> = ({ locationId }) => <React.Fragment>

    <Typography variant="body1"><strong>Validation Lists</strong></Typography>

    <ObjectBuilder
        locationId={locationId}
        settingName="validationLists"
        settingType="location"
        description={<Typography variant="body2">Manage groups of menu items to test with the chatbot automated testing tools.</Typography>}
    />

</React.Fragment>

export default function Settings() {

    const dispatch = useDispatch();
    const [locationId, setLocationId] = useState<string>("Customer");

    // Redux Selectors
    const customerId = useSelector((state: any) => state.userSlice.customerId);
    const { data: locationsData = null, isLoading: locationsLoading } = useGetLocationsQuery(customerId);
    const locations = locationsData as Location[] || [];

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Locations", status: locationsLoading }));
    }, [dispatch, locationsLoading]);

    const handleLocationSelect = (event: SelectChangeEvent) => {
        setLocationId(event.target.value);
    };

    return <Main header="User Settings">
        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <FormControl variant="standard" sx={{ minWidth: 200 }}>
                                    <InputLabel>Location</InputLabel>
                                    <Select label="Location" value={locationId?.toString()} onChange={handleLocationSelect}>
                                        <MenuItem value="Customer">Global Settings</MenuItem>
                                        {locations.map(location =>
                                            <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            {/* Placeholder */}
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>

                            {locationId === "Customer"
                                ? <CustomerSettings />
                                : <LocationSettings locationId={locationId} />
                            }

                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    </Main >

};