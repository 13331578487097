import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CampaignIcon from '@mui/icons-material/Campaign';
import Tooltip from '@mui/material/Tooltip';
import { DataGridPro, GridColDef, GridRowParams, GridCellParams } from '@mui/x-data-grid-pro';
import { useGetLocationsQuery } from '../Redux/Services/OhWaiter';
import { useGetGuestByIdQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Location as LocationType, GuestData as GuestDataType } from '../Types';
import { ChatOrderItem } from '../Types';
import CohortEdit from '../Components/Modals/CohortEdit';
import { getDateTimeFromGMTISO } from '../Helpers/Temporal';
import { capitalizeWords } from '../Helpers/StringUtils';
import { organizeObjArray } from '../Helpers/ObjectUtils';
import { COMBINER_SUM, SORT_DESC } from '../Helpers/ObjectUtils';
import { moneyFormat } from '../Helpers/Money';
import { stdDateFormat, dataGridSx } from '../Constants';

const LocationDetail: React.FC<{
    dataObject: GuestDataType;
    collapsed?: boolean;
    cohortEditorOpener: (locationId: string) => void;
}> = ({ dataObject, collapsed, cohortEditorOpener }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { guestId, locationId, totalPaidInCents, orders } = dataObject;
    const [headerText, setHeaderText] = useState<string>('');
    const [orderedItems, setOrderedItems] = useState<ChatOrderItem[]>([]);
    const [averageCheck, setAverageCheck] = useState<number>(0);

    // Redux Selectors
    const customerId = useSelector((state: any) => state.userSlice.customerId);
    const { data: locationsData = null, isLoading: locationsLoading } = useGetLocationsQuery(customerId);
    const locations = useMemo(() => locationsData as LocationType[] || [], [locationsData]);
    const locationLookup = locations.find(l => locationId === l.id);

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Locations", status: locationsLoading }));
    }, [dispatch, locationsLoading]);

    useEffect(() => {
        if (locationLookup && locationLookup.name) setHeaderText(locationLookup.name);
    }, [locationLookup]);

    useEffect(() => {
        if (orders) {
            if (orders.some(order => !!order.items)) setOrderedItems(organizeObjArray(
                orders.flatMap(({ items }) => items) as ChatOrderItem[],
                "name",
                "quantity",
                COMBINER_SUM,
                SORT_DESC
            ));
            let checkAvg = totalPaidInCents / orders.filter(order => order.totalDueInCents && order.totalDueInCents > 0).length
            if (!isNaN(checkAvg)) setAverageCheck(checkAvg);
        }
    }, [totalPaidInCents, orders]);

    const handleOrderRowClick = (params: GridRowParams) => {
        const { transcriptId } = params.row;
        navigate(`/transcripts/${locationId}/${guestId}/${transcriptId}`);
    }

    const editCohorts = (e: any) => {
        e.stopPropagation();
        cohortEditorOpener(locationId);
    }

    const totalCell = (params: GridCellParams) => moneyFormat(params.row.totalDueInCents);
    const tipCell = (params: GridCellParams) => moneyFormat(params.row.tipInCents);

    const dateCell = (params: GridCellParams) => {
        const { createdAtUtc } = params.row;
        let timezone = null; if (locationLookup && locationLookup.timeZone) timezone = locationLookup.timeZone;
        const localFormatted = getDateTimeFromGMTISO(createdAtUtc, timezone, stdDateFormat);
        return <React.Fragment>
            {localFormatted}
        </React.Fragment>
    }

    const statusCell = (params: GridCellParams) => {

        const { orderStatus } = params.row;
        let iconColor = "rgba(0, 0, 0, 0.26)";
        if (orderStatus.toLowerCase() === "open") iconColor = "#ffbf00";
        if (orderStatus.toLowerCase() === "paid") iconColor = "#2e7d32";

        return <Grid container justifyContent="flex-end" mx={1}>
            <Stack direction="row" alignItems="center" spacing={3}>
                <Tooltip title={capitalizeWords(orderStatus)} placement="top" arrow>
                    <PointOfSaleIcon fontSize="small" sx={{ color: iconColor }} />
                </Tooltip>
                <Tooltip title="View Transcript" placement="top" arrow>
                    <QuestionAnswerIcon fontSize="small" />
                </Tooltip>
            </Stack>
        </Grid>
    };

    const favoritesColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Item',
            flex: 1
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 120
        },
    ];

    const orderColumns: GridColDef[] = [
        {
            field: 'createdAtUtc',
            headerName: 'Date',
            flex: 1,
            renderCell: dateCell
        },
        {
            field: 'paymentProvider',
            headerName: 'Provider',
            width: 150
        },
        {
            field: 'totalDueInCents',
            headerName: 'Total',
            width: 120,
            renderCell: totalCell
        },
        {
            field: 'tipInCents',
            headerName: 'Tip',
            width: 120,
            renderCell: tipCell
        },
        {
            field: 'status',
            headerName: '',
            width: 140,
            renderCell: statusCell
        }
    ];

    return <Accordion defaultExpanded={!collapsed}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Typography variant="h6" className="bold-header">{headerText}</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Box mr={2}>
                            <Button variant="outlined" startIcon={<CampaignIcon />} onClick={editCohorts}>
                                Marketing Groups
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </AccordionSummary>
        <AccordionDetails>

            <Stack spacing={2}>

                <Stack spacing={1}>
                    <Typography variant="h6">
                        Items Ordered
                    </Typography>
                    <DataGridPro
                        columns={favoritesColumns}
                        rows={orderedItems}
                        getRowId={(row) => row.name}
                        autoHeight
                        hideFooter
                    />
                </Stack>

                <Stack spacing={1}>
                    <Typography variant="h6">
                        {orders.length} Orders ({moneyFormat(totalPaidInCents)} Total, {moneyFormat(averageCheck)} Average)
                    </Typography>
                    <DataGridPro
                        columns={orderColumns}
                        rows={orders}
                        getRowId={(row) => row.orderId}
                        onRowClick={handleOrderRowClick}
                        disableSelectionOnClick
                        sx={dataGridSx}
                        autoHeight
                        hideFooter
                    />
                </Stack>

            </Stack>

        </AccordionDetails>
    </Accordion>

}

const GuestDetail: React.FC<{
    guestId: string | null;
    allCollapsed?: boolean;
}> = ({ guestId, allCollapsed }) => {

    const dispatch = useDispatch();
    const [cohortLocationId, setCohortLocationId] = useState<string | null>(null);
    const closeCohortEdit = () => setCohortLocationId(null);

    // Redux Selectors
    const { data: guestById = null, isLoading: guestLoading } = useGetGuestByIdQuery({ guestId }, { skip: !!!guestId });
    const guestData = useMemo(() => guestById as GuestDataType[] || [], [guestById]);
    const guestNames = useMemo(() => guestData.flatMap(({ names }) => names), [guestData]);

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Guest", status: guestLoading }));
    }, [dispatch, guestLoading]);

    const dateCell = (params: GridCellParams) => {
        const { lastUpdateDateUtc } = params.row;
        const localFormatted = getDateTimeFromGMTISO(lastUpdateDateUtc, null, stdDateFormat);
        return <React.Fragment>
            {localFormatted}
        </React.Fragment>
    }

    const nameColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 400,
        },
        {
            field: 'lastUpdateDateUtc',
            headerName: 'Last Seen',
            flex: 1,
            renderCell: dateCell
        }
    ];

    return <React.Fragment>

        {guestId && <CohortEdit
            guestId={guestId}
            locationId={cohortLocationId as string}
            open={!!cohortLocationId}
            handleClose={closeCohortEdit}
        />}

        <Grid container spacing={3}>

            <Grid item xs={12}>
                <Stack spacing={3}>

                    <Stack spacing={1}>
                        <Typography variant="h6" className="bold-header">Names Used</Typography>
                        <DataGridPro
                            columns={nameColumns}
                            rows={guestNames}
                            getRowId={(row) => row.name}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: "lastUpdateDateUtc", sort: "desc" }],
                                },
                            }}
                            autoHeight
                            hideFooter
                        />
                    </Stack>

                    {guestData && guestData.map(dataObject => <LocationDetail
                        key={dataObject.locationId}
                        dataObject={dataObject}
                        collapsed={(guestData.length > 1) && allCollapsed}
                        cohortEditorOpener={setCohortLocationId}
                    />)}

                </Stack>
            </Grid>

            {/* <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Stack direction="row" spacing={1}>
                            <Button variant="contained" onClick={openCohortEdit}>
                                Add to Cohort
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            {Placeholder}
                        </Stack>
                    </Grid>
                </Grid>
            </Grid> */}

        </Grid>

    </React.Fragment>

};

export default GuestDetail;