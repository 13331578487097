import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useSaveModifierSynonymsMutation } from '../../Redux/Services/OhWaiter';
import { Synonym } from '../../Types';
import { apiSuccess, apiError } from '../../Helpers/Toaster';
import { smallModalSx } from '../../Constants';

type ModalProps = {
    locationId: number;
    modifierItem: string;
    existingSynonyms: string[];
    open: boolean;
    handleClose: (open: boolean) => void;
};

const ModifierSynonymEdit: React.FC<ModalProps> = ({ locationId, modifierItem, existingSynonyms, open, handleClose }) => {

    const [synonymsText, setSynonymsText] = useState('');

    const [saveSynonyms] = useSaveModifierSynonymsMutation();

    useEffect(() => setSynonymsText(''), []);

    useEffect(() => {
        if (!!existingSynonyms.length && open) setSynonymsText(existingSynonyms.join("\n"));
        else setSynonymsText('');
    }, [existingSynonyms, open]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSynonymsText(e.target.value);
    };

    const handleSave = async () => {
        const newSynonyms: string[] = [];
        const lines = (synonymsText) ? synonymsText.split("\n").map(line => line.trim()) : [];
        lines.forEach(line => {
            const phrases = line.split(",").map(phrase => phrase.trim());
            phrases.forEach(phrase => newSynonyms.push(phrase));
        });
        const synonym: Synonym = {
            name: modifierItem,
            synonyms: newSynonyms
        };
        saveSynonyms({ locationId, synonym })
            .then(() => apiSuccess("Synonyms Updated!"))
            .catch((err) => apiError(err));
        setSynonymsText('');
        handleClose(false);
    };

    const handleDelete = () => {
        setSynonymsText('');
    };

    return <Modal open={open} onClose={handleClose}>
        <Box sx={smallModalSx}>
            <Stack spacing={2}>
                <Typography variant="h6" component="h2" className="bold-header">
                    {modifierItem}
                </Typography>
                <Box component="form" sx={{ my: 3 }} noValidate autoComplete="off">
                    <TextField
                        label="Synonyms"
                        variant="outlined"
                        fullWidth
                        multiline
                        value={synonymsText}
                        onChange={handleChange}
                    />
                </Box>
                <Grid container sx={{ mt: 3 }}>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" color="error" onClick={handleDelete}>
                                Clear
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" onClick={() => handleClose(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={handleSave}>
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    </Modal>

};

export default ModifierSynonymEdit;