import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridColDef, GridCellParams } from '@mui/x-data-grid-pro';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { portalApi } from '../Redux/Services/OhWaiter';
import { useGetMarketingCampaignsQuery } from '../Redux/Services/OhWaiter';
import { useStartMarketingCampaignMutation } from '../Redux/Services/OhWaiter';
import { useStopMarketingCampaignMutation } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { MarketingCampaign, CampaignGuest } from '../Types';
import { getDateTimeFromGMTISO } from '../Helpers/Temporal';
import { stdDateFormat, dataGridSx } from '../Constants';

const Campaigns: React.FC<{ locationId: string; }> = ({ locationId }) => {

    const dispatch = useDispatch();
    const pollInterval = 15; // Seconds

    // Redux Selectors
    const { data: campaignsData = null, isLoading: campaignsLoading } = useGetMarketingCampaignsQuery({ locationId }, { skip: !!!locationId });
    const campaigns = useMemo(() => campaignsData as MarketingCampaign[] || [], [campaignsData]);
    const [startMarketingCampaign] = useStartMarketingCampaignMutation();
    const [stopMarketingCampaign] = useStopMarketingCampaignMutation();

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Marketing Campaigns", status: campaignsLoading }));
    }, [dispatch, campaignsLoading]);

    const dateCell = (params: GridCellParams) => {
        const { createAtUtc } = params.row;
        const localFormatted = getDateTimeFromGMTISO(createAtUtc, null, stdDateFormat);
        return <React.Fragment>
            {localFormatted}
        </React.Fragment>
    }

    const getSentCount = (guests: CampaignGuest[]): number => {
        return guests.filter(g => g.status.some(s => {
            const status = s.status.toLowerCase();
            return (status !== 'pending')
        })).length;
    }

    const getErrorCount = (guests: CampaignGuest[]): number => {
        return guests.filter(g => g.status.some(s => {
            const status = s.status.toLowerCase();
            return (status === 'error')
        })).length;
    }

    const guestsCell = (params: GridCellParams) => {
        const guests: CampaignGuest[] = params.row.guests;
        const sentCount = getSentCount(guests);
        const errorCount = getErrorCount(guests);
        const totalGuests = guests.length || 0;
        return <React.Fragment>
            <span>{sentCount}/{totalGuests}</span>&nbsp;
            {errorCount > 0 && <span style={{ color: "#d32f2f" }}>({errorCount})</span>}
        </React.Fragment>
    }

    const actionsCell = (params: GridCellParams) => {
        const { state, id: campaignId } = params.row;
        let ControlIcon = <React.Fragment />;
        const startAction = async () => startMarketingCampaign({ locationId, campaignId });
        const stopAction = async () => stopMarketingCampaign({ locationId, campaignId });
        switch (state.toLowerCase()) {
            case "new":
            case "ready":
                ControlIcon = <PlayArrowIcon fontSize="small" style={{ cursor: "pointer" }} onClick={startAction} />;
                break;
            case "working":
                ControlIcon = <PauseIcon fontSize="small" style={{ cursor: "pointer" }} onClick={stopAction} />;
                break;
            case "finished":
                ControlIcon = <CheckCircleIcon fontSize="small" color="success" />
        }
        return <Grid container justifyContent="flex-end" mx={1}>
            <Stack direction="row" alignItems="center" spacing={3}>
                {ControlIcon}
            </Stack>
        </Grid>
    }

    const campaignColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Campaign Name',
            width: 220
        },
        {
            field: 'createAtUtc',
            headerName: 'Created',
            width: 160,
            renderCell: dateCell
        },
        {
            field: 'description',
            headerName: 'Campaign Description',
            flex: 1
        },
        {
            field: 'guests',
            headerName: 'Sends',
            width: 160,
            renderCell: guestsCell
        },
        {
            field: 'state',
            headerName: '',
            width: 120,
            renderCell: actionsCell
        }
    ];

    useEffect(() => {
        const campaignPollster = setInterval(() => {
            if (!!locationId) {
                dispatch(portalApi.util.invalidateTags(
                    [{ type: 'MarketingCampaignsByLocation', id: locationId }]
                ));
            }
        }, pollInterval * 1000);
        return () => clearInterval(campaignPollster);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <DataGridPro
        columns={campaignColumns}
        rows={campaigns || []}
        getRowId={(row) => row.id}
        disableSelectionOnClick
        sx={dataGridSx}
        initialState={{
            sorting: {
                sortModel: [{ field: "createAtUtc", sort: "desc" }],
            },
        }}
        autoHeight
        hideFooter
    />

};

export default Campaigns;