import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: "userSlice",
    initialState: {
        customerId: null,
    },
    reducers: {
        setCustomerId: (state, action) => {
            state.customerId = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase("adminSlice/setCustomerId", (state, action) => {
            return { ...state, customerId: action.payload };
        });
    }
});

export const { setCustomerId } = userSlice.actions;

export default userSlice.reducer;