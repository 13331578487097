import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import "./LoadingBar.scss";

const ThickerProgress = styled(LinearProgress)(({ theme }) => ({ height: 8 }));

export default function LoadingBar() {

    // Redux Selectors
    const loaders = useSelector((state: any) => state.loadingSlice.loaders);
    const [loadings, setLoadings] = useState<String[]>([]);

    useEffect(() => {
        const activeLoaders = Object.entries(loaders).map(([k, v]) => (v ? k : null)).filter(k => k) as String[];
        setLoadings(activeLoaders);
    }, [loaders]);

    const fadeIn = (loadings && !!loadings.length) ? true : false;

    return <Fade in={fadeIn} timeout={500}>
        <div className="LoadingBar">
            <div className="Text">
                {(loadings && !!loadings.length) && <React.Fragment>
                    Loading: {loadings.join(", ")}
                </React.Fragment>}
            </div>
            <div className="Progress">
                <ThickerProgress />
            </div>
        </div>
    </Fade>

}