import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { DataGridPro, GridColDef, GridSelectionModel } from '@mui/x-data-grid-pro';
import { GridCellParams, GridRowOrderChangeParams } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Tooltip from '@mui/material/Tooltip';
import { LicenseInfo } from '@mui/x-license-pro';
import { useGetLocationByIdQuery } from '../Redux/Services/OhWaiter';
import { useGetTagsByZoneIdQuery, useGetRepliesByZoneIdQuery, useGetButtonsByZoneIdQuery } from '../Redux/Services/OhWaiter';
import { useSaveButtonsOrderMutation } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Location as LocationType, Tag as TagType, Reply as ReplyType, Button as ButtonType } from '../Types';
import Main from '../Layouts/Main';
import Calendar from '../Components/Calendar';
import QrEdit from '../Components/Modals/QrEdit';
import ReplyEdit from '../Components/Modals/ReplyEdit';
import ButtonEdit from '../Components/Modals/ButtonEdit';
import { reorderButtonGrid, filterButtonsPayload } from '../Helpers/ButtonsOrder';
import { staffRoles } from '../Constants';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_KEY as string);

// Note: This page is currently unlinked, for being too useful ;) No, but seriously, per Jonathan, it's best to keep things linear and separated out into distinct areas. It's been split into separate Zones and Tags pages, along with the existing Replies and Buttons pages.

export default function Zone() {

    let { location_id, zone_id } = useParams();

    const dispatch = useDispatch();
    const accountRole = useSelector((state: any) => state.adminSlice.accountRole);
    const [buttonsOrdered, setButtonsOrdered] = useState<ButtonType[] | null>(null);
    const [nextOrdinal] = useState<number>(0);

    const [qrEditOpen, setQrEditOpen] = useState(false);
    const [replyEditOpen, setReplyEditOpen] = useState(false);
    const [buttonEditOpen, setButtonEditOpen] = useState(false);
    const [editReplyId, setEditReplyId] = useState<number | null>(null);
    const [editButtonId, setEditButtonId] = useState<number | null>(null);
    const openQrEdit = () => setQrEditOpen(true);
    const closeQrEdit = () => setQrEditOpen(false);
    const openReplyEdit = () => setReplyEditOpen(true);
    const closeReplyEdit = () => setReplyEditOpen(false);
    const openButtonEdit = () => setButtonEditOpen(true);
    const closeButtonEdit = () => setButtonEditOpen(false);

    const [qrGridSelection, setQrGridSelection] = useState<GridSelectionModel>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const [buttonGridSelection, setButtonGridSelection] = useState<GridSelectionModel>([]);
    const [selectedButtons, setSelectedButtons] = useState<string[]>([]);

    // Redux Selectors
    const locationId = location_id || null;
    const zoneId = zone_id || null;
    const { data: locationData = null, isLoading: locationLoading } = useGetLocationByIdQuery({ locationId }, { skip: !!!locationId });
    const { data: tagsData = null, isLoading: tagsLoading } = useGetTagsByZoneIdQuery({ zoneId }, { skip: !!!zoneId });
    const { data: repliesData = null, isLoading: repliesLoading } = useGetRepliesByZoneIdQuery({ zoneId }, { skip: !!!zoneId });
    const { data: buttonsData = null, isLoading: buttonsLoading } = useGetButtonsByZoneIdQuery({ zoneId }, { skip: !!!zoneId });
    const [saveButtonsOrder] = useSaveButtonsOrderMutation();
    const location = locationData as LocationType || {};
    const tags = tagsData as TagType[] || [];
    const replies = repliesData as ReplyType[] || [];
    const buttons = buttonsData as ButtonType[] || [];
    const location_name = (location && location.name) ? location.name : '';
    const zone_meta = location.zones?.find(obj => obj.zoneId === zone_id);
    const zone_name = (zone_meta) ? zone_meta.name : '';

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Location", status: locationLoading }));
        dispatch(addLoader({ loader: "QR Tags", status: tagsLoading }));
        dispatch(addLoader({ loader: "Auto Replies", status: repliesLoading }));
        dispatch(addLoader({ loader: "Buttons", status: buttonsLoading }));
    }, [dispatch, locationLoading, tagsLoading, repliesLoading, buttonsLoading]);

    useEffect(() => {
        if (!!buttons.length && !buttonsOrdered) setButtonsOrdered(buttons);
    }, [buttons]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleNewQrClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        openQrEdit();
        event.stopPropagation(); // Don't collapse the accordion
    }

    const handleNewReplyClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setEditReplyId(null);
        openReplyEdit();
        event.stopPropagation(); // Don't collapse the accordion
    }

    const handleNewButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setEditButtonId(null);
        openButtonEdit();
        event.stopPropagation(); // Don't collapse the accordion
    }

    const handleDeleteQr = (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log("delete tag");
        //if (!!zone_id) cpsvc.killTags(dispatch, parseInt(zone_id), selectedTags);
        event.stopPropagation(); // Don't collapse the accordion
    };

    const updateQrSelection = (selectedItems: GridSelectionModel) => {
        setQrGridSelection(selectedItems);
        const selectedRows = tags.filter((t) => (t.tagId && new Set(selectedItems).has(t.tagId.toString())));
        setSelectedTags(selectedRows.map(t => t.tagName) as string[]);
    }

    const updateButtonSelection = (selectedItems: GridSelectionModel) => {
        setButtonGridSelection(selectedItems);
        const selectedRows = buttons.filter((b) => (b.buttonId && new Set(selectedItems).has(b.buttonId.toString())));
        setSelectedButtons(selectedRows.map(b => b.buttonId) as string[]);
    }

    const updateButtonOrder = async (params: GridRowOrderChangeParams) => {
        if (buttonsOrdered) {
            const buttonsReorderedSorted = reorderButtonGrid(buttonsOrdered, params);
            saveButtonsOrder({ zoneId, buttons: filterButtonsPayload(buttonsReorderedSorted as ButtonType[]) });
            setButtonsOrdered(buttonsReorderedSorted as ButtonType[]);
        }
    };

    const handleDeleteButtons = (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log("delete buttons");
        //if (!!zone_id) cpsvc.killButtons(dispatch, parseInt(zone_id), selectedButtons.map(b => parseInt(b)) as number[]);
        event.stopPropagation(); // Don't collapse the accordion
    };

    const handleNewReply = () => {
        setEditReplyId(null);
        openReplyEdit();
    };

    const handleReplyEdit = (id: number) => {
        setEditReplyId(id);
        openReplyEdit();
    };

    const handleButtonEdit = (id: number) => {
        setEditButtonId(id);
        openButtonEdit();
    };

    const qrActionsCell = (params: GridCellParams) => {
        //const clickAction = () => { if (!!zone_id) cpsvc.killTags(dispatch, parseInt(zone_id), [params.row.tagName]); };
        const clickAction = () => { console.log("delete tags"); };
        return <Grid container justifyContent="flex-end" mx={1}>
            <Stack direction="row" alignItems="center" spacing={3}>
                <DeleteForeverIcon fontSize="small" color="error" style={{ cursor: "pointer" }} onClick={clickAction} />
            </Stack>
        </Grid>
    }

    const buttonActionsCell = (params: GridCellParams) => {
        const clickAction = () => handleButtonEdit(parseInt(params.row.buttonId));
        return <Grid container justifyContent="flex-end" mx={1}>
            <Stack direction="row" alignItems="center" spacing={3}>
                <ModeEditIcon fontSize="small" style={{ cursor: "pointer" }} onClick={clickAction} />
            </Stack>
        </Grid>
    }

    const AddButton = (el: any) => {
        return <Button
            size="small"
            variant="contained"
            startIcon={<AddCircleIcon />}
            sx={{ mr: 1, boxShadow: "none" }}
            onClick={el.handler}
        >Add New</Button>
    }

    const qrColumns: GridColDef[] = [
        {
            field: 'tagName',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'tagId',
            headerName: '',
            width: 115,
            renderCell: qrActionsCell
        }
    ];

    const buttonColumns: GridColDef[] = [
        {
            field: 'title',
            headerName: 'Button',
            width: 200,
        }, {
            field: 'response',
            headerName: 'Message',
            flex: 1
        }, {
            //     field: 'ordinal',
            //     headerName: 'DB Order',
            //     width: 80
            // }, {
            //     field: 'newOrdinal',
            //     headerName: 'Order',
            //     width: 80
            // }, {
            field: 'buttonId',
            headerName: '',
            width: 115,
            renderCell: buttonActionsCell
        }
    ];

    return <Main header={location_name}>

        {/* Modals / Drawers */}
        {zone_id && <QrEdit zoneId={parseInt(zone_id)} open={qrEditOpen} handleClose={closeQrEdit} />}
        {zone_id && <ReplyEdit zoneId={parseInt(zone_id)} replyId={editReplyId} open={replyEditOpen} handleClose={closeReplyEdit} />}
        {zone_id && <ButtonEdit zoneId={parseInt(zone_id)} buttonId={editButtonId} nextOrdinal={nextOrdinal} open={buttonEditOpen} handleClose={closeButtonEdit} />}

        {(location_id && zone_id) && <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Typography variant="h6" className="bold-header">
                            {`${zone_name} Zone`}
                            {/* <Link to={`/location/${location_id}`} style={{ color: "inherit", textDecoration: "none" }}>
                                Zones:
                            </Link>
                            {`\u00A0\u00A0${zone_name}`} */}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Button component={Link} to={`/location/${location_id}`} variant="outlined">
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>

                            {/* QR Codes */}
                            <Accordion defaultExpanded={false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Tooltip title="Add New QR Codes" placement="top" arrow>
                                            <React.Fragment>
                                                <AddButton handler={handleNewQrClick} />
                                            </React.Fragment>
                                        </Tooltip>
                                        <Typography variant="h6" className="bold-header">QR Tags ({tags.length})</Typography>
                                        <IconButton color="error" disabled={selectedTags.length <= 0} onClick={handleDeleteQr}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <DataGridPro
                                        columns={qrColumns}
                                        rows={(!locationId) ? [] : tags}
                                        getRowId={(row) => row.tagId}
                                        autoHeight
                                        hideFooter
                                        checkboxSelection
                                        disableSelectionOnClick
                                        onSelectionModelChange={(selectModel) => updateQrSelection(selectModel)}
                                        selectionModel={qrGridSelection}
                                    />
                                </AccordionDetails>
                            </Accordion>

                            {/* Automatic Replies */}
                            <Accordion defaultExpanded={false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Tooltip title="Schedule New Auto-Reply" placement="top" arrow>
                                            <React.Fragment>
                                                <AddButton handler={handleNewReplyClick} />
                                            </React.Fragment>
                                        </Tooltip>
                                        <Typography variant="h6" className="bold-header">Automatic Replies ({replies.length})</Typography>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Calendar
                                        replies={(!location_id || !zone_id) ? [] : replies}
                                        handleNew={handleNewReply}
                                        handleEdit={handleReplyEdit}
                                    />
                                </AccordionDetails>
                            </Accordion>

                            {/* Quick Reply Buttons */}
                            {(accountRole && staffRoles.includes(accountRole)) && <Accordion defaultExpanded={false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Tooltip title="Add New Quick Reply Button" placement="top" arrow>
                                            <React.Fragment>
                                                <AddButton handler={handleNewButtonClick} />
                                            </React.Fragment>
                                        </Tooltip>
                                        <Typography variant="h6" className="bold-header">Quick Reply Buttons ({buttons.length})</Typography>
                                        <IconButton color="error" disabled={selectedButtons.length <= 0} onClick={handleDeleteButtons}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <DataGridPro
                                        columns={buttonColumns}
                                        rows={(buttonsOrdered && !!buttonsOrdered.length && !!zone_id) ? buttonsOrdered : []}
                                        getRowId={(row) => row.buttonId}
                                        autoHeight
                                        hideFooter
                                        checkboxSelection
                                        disableSelectionOnClick
                                        onSelectionModelChange={(selectModel) => updateButtonSelection(selectModel)}
                                        selectionModel={buttonGridSelection}
                                        rowReordering
                                        onRowOrderChange={updateButtonOrder}
                                        components={{ RowReorderIcon: DragHandleIcon }}
                                    />
                                </AccordionDetails>
                            </Accordion>}

                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>}

    </Main>

};