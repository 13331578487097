import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import { useGetRepliesByZoneIdQuery } from '../Redux/Services/OhWaiter';
import { useSaveReplyMutation, useDeleteRepliesMutation } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Reply } from '../Types';
import { autoReplyTypes } from '../Constants';

const StaticReply: React.FC<{ replyName: string; zoneId: string; }> = ({ replyName, zoneId }) => {

    const [currentValue, setCurrentValue] = useState('');
    const [fieldValue, setFieldValue] = useState('');
    const [fieldSaved, setFieldSaved] = useState('');
    const [fieldInitted, setFieldInitted] = useState(false);
    const [replyId, setReplyId] = useState('');

    const dispatch = useDispatch();

    // Redux Selectors
    const { data: repliesData = null, isLoading: repliesLoading } = useGetRepliesByZoneIdQuery({ zoneId }, { skip: !!!zoneId });
    const [saveReply] = useSaveReplyMutation();
    const [deleteReplies] = useDeleteRepliesMutation();
    const replies = useMemo(() => repliesData as Reply[] || [], [zoneId, repliesData]); // eslint-disable-line react-hooks/exhaustive-deps

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Auto Replies", status: repliesLoading }));
    }, [dispatch, repliesLoading]);

    useEffect(() => {
        const existingReply = replies.find(reply => reply.name === replyName);
        if (existingReply) {
            setReplyId(existingReply.responseId || '');
            setCurrentValue(existingReply.response || '');
        } else clearField();
    }, [replies]); // eslint-disable-line react-hooks/exhaustive-deps

    // Init Field
    useEffect(() => {
        if (!!currentValue && fieldValue === '') setFieldValue(currentValue);
    }, [currentValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSave = async () => {
        if (!!replyId && fieldValue === '') {
            await deleteReplies({
                zoneId,
                replyIds: [replyId?.toString()]
            });
            setFieldInitted(true);
            setFieldSaved(fieldValue);
        } else {
            const payload: Reply = {
                name: replyName,
                response: fieldValue,
                startTime: "09:00",
                endTime: "13:00",
                daysOfWeek: "0,1,2,3,4,5,6",
                ordinal: 0
            };
            if (!!replyId) payload.responseId = replyId.toString();
            await saveReply({
                zoneId,
                reply: payload
            });
            setFieldInitted(true);
            setFieldSaved(fieldValue);
        }
    };

    const clearField = () => {
        setFieldValue('');
        setCurrentValue('');
        setReplyId('');
    }

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(e.target.value);

    return <Box component="form" noValidate autoComplete="off">

        <Grid container>
            <Grid item xs={10.6} display="flex" flexDirection="row" pr={1}>
                <TextField
                    label={replyName}
                    variant="outlined"
                    fullWidth
                    multiline
                    value={fieldValue}
                    onChange={handleFieldChange}
                />
            </Grid>
            <Grid item xs={1.4} display="flex" flexDirection="row-reverse">
                {((fieldSaved === fieldValue) && fieldInitted)
                    ? <Button
                        variant="outlined"
                        fullWidth
                        startIcon={<CheckIcon />}
                        color="success"
                        onClick={handleSave}
                    >Saved</Button>
                    : <Button
                        variant="outlined"
                        fullWidth
                        startIcon={<SaveIcon />}
                        onClick={handleSave}
                    >Save</Button>
                }
            </Grid>
        </Grid>

    </Box>

};

const AutoReplies: React.FC<{ zoneId: string; }> = ({ zoneId }) => <React.Fragment>

    {/* Main Column */}
    <Grid item lg={12} md={12} sm={12}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box my={2}>
                    <Stack direction="column" spacing={3}>

                        {autoReplyTypes.map(replyType => <StaticReply
                            key={`${replyType}_${zoneId}`}
                            replyName={replyType}
                            zoneId={zoneId}
                        />)}

                    </Stack>
                </Box>
            </Grid>
        </Grid>
    </Grid>

</React.Fragment >;

export default AutoReplies;