import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './msalConfig';
import { MsalProvider } from '@azure/msal-react';
import { Store } from './Redux/Store';
import App from './App'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const msalInstance = new PublicClientApplication(msalConfig);
root.render(<React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <Provider store={Store}>
      <App />
    </Provider>
  </MsalProvider>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
