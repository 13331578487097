import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useGetLocationByIdQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Location } from '../Types';
import Main from '../Layouts/Main';
import LocationSelector from '../Components/LocationSelector';
import ZoneCard from '../Components/ZoneCard';
import ZoneEdit from '../Components/Modals/ZoneEdit';

export default function Zones() {

    const dispatch = useDispatch();
    const [locationId, setLocationId] = React.useState<string>('');

    const [zoneEditOpen, setZoneEditOpen] = React.useState(false);
    const openZoneEdit = () => setZoneEditOpen(true);
    const closeZoneEdit = () => setZoneEditOpen(false);

    // Redux Selectors
    const { data: locationData = null, isLoading: locationLoading } = useGetLocationByIdQuery({ locationId }, { skip: !!!locationId });
    const location = locationData as Location || {};
    const { zones } = location;

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Location", status: locationLoading }));
    }, [dispatch, locationLoading]);

    return <Main header="Zones">

        {locationId && <ZoneEdit locationId={parseInt(locationId)} open={zoneEditOpen} handleClose={closeZoneEdit} />}

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector selectionHandler={setLocationId} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            <Button variant="contained" startIcon={<AddCircleIcon />} onClick={openZoneEdit} disabled={!locationId}>
                                New Zone
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>

                    {zones && zones.map(zone => (
                        <Grid item xs={12} sm={12} md={4} key={zone.zoneId}>
                            <ZoneCard locationId={parseInt(locationId)} zoneId={zone.zoneId} zoneName={zone.name} qrCount={zone.qrCount} />
                        </Grid>
                    ))}

                </Grid>
            </Grid>

        </Grid>

    </Main >

};