
import { GridRowOrderChangeParams } from '@mui/x-data-grid-pro';
import { Button as ButtonType } from '../Types';

const reorderButtonGrid = (buttons: ButtonType[], row: GridRowOrderChangeParams) => {
  if (buttons) {
    const previousOrdinal = row.oldIndex + 1;
    const targetOrdinal = row.targetIndex + 1;
    const buttonsReordered = buttons.map(button => {
      if (button.newOrdinal) {
        let newOrdinal = button.newOrdinal;
        const rangeMax = Math.max(previousOrdinal, targetOrdinal);
        const rangeMin = Math.min(previousOrdinal, targetOrdinal);
        if (button.newOrdinal >= rangeMin && button.newOrdinal <= rangeMax) {
          if (button.newOrdinal === previousOrdinal) newOrdinal = targetOrdinal;
          else {
            if (targetOrdinal < previousOrdinal) newOrdinal = button.newOrdinal + 1; // Moved Up
            if (targetOrdinal > previousOrdinal) newOrdinal = button.newOrdinal - 1; // Moved Down
          }
        }
        return { ...button, newOrdinal };
      }
      return button;
    });
    const buttonsReorderedSorted = buttonsReordered
      .filter((item): item is any => !!item) // No undefineds
      .sort((a, b) => a.newOrdinal - b.newOrdinal); // Sort by new ordinal
    return buttonsReorderedSorted as ButtonType[];
  }
};

const filterButtonsPayload = (buttons: ButtonType[]) => {
  let saveButtons: ButtonType[] = [];
  if (buttons) saveButtons = [...buttons];
  // Replace ordinals that have changed:
  const filteredButtons = saveButtons
    .filter(button => button.newOrdinal !== button.ordinal)
    .map(button => {
      const reorganized = { ...button };
      reorganized.ordinal = reorganized.newOrdinal;
      reorganized.category = "Default";
      delete reorganized.newOrdinal;
      return reorganized;
    });
  return filteredButtons as ButtonType[];
}

export { reorderButtonGrid, filterButtonsPayload };