import React from 'react';
import Box from '@mui/material/Box';
import Alerts from '../Components/Alerts';

type LayoutProps = {
    children?: React.ReactNode;
};

const Centered = ({ children }: LayoutProps) => {

    return <Box style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }}>
        <Box>
            {children}
        </Box>
        <Alerts />
    </Box>

};

export default Centered;