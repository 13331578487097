import React, { useEffect, useMemo } from 'react';
import { useLocation, Link } from "react-router-dom";
import { loginRequest } from '../msalConfig';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Centered from '../Layouts/Centered';
import { useGetLocationsQuery, useGetIACMLocationQuery, useSetIACMAssociationMutation } from '../Redux/Services/OhWaiter';
import { Location as LocationType, IACMLocation as IACMLocationType } from '../Types';
import { apiSuccess, apiError } from '../Helpers/Toaster';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LoginButton() {
    const { instance } = useMsal();
    const handleLogin = (instance: any) => {
        instance.loginRedirect(loginRequest);
    };
    return <Button onClick={() => handleLogin(instance)} variant="contained">
        Sign in
    </Button>
};

export default function OauthIACM() {

    const query = useQuery();
    const code = query.get("code");

    const loggedIn = useIsAuthenticated();
    const [IACMLocationId, setIACMLocationId] = React.useState<string | null>(null);
    const [IACMConnected, setIACMConnected] = React.useState<boolean>(false);
    const [statusText, setStatusText] = React.useState<string>("Please Sign in to Select a Location.");
    const [locationId, setLocationId] = React.useState<string>('');

    // Redux Selectors
    const { data: locationsData = null, isLoading: locationsLoading } = useGetLocationsQuery(null, { skip: !loggedIn });
    const { data: IACMLocationData = null, isLoading: IACMLoading } = useGetIACMLocationQuery(code, { skip: !loggedIn });
    const [setIACMAssociation] = useSetIACMAssociationMutation();
    const locations = locationsData as LocationType[] || [];
    const IACMLocation = useMemo(() => IACMLocationData as IACMLocationType || {}, [IACMLocationData]); // eslint-disable-line react-hooks/exhaustive-deps

    // Everything IACM Needs:
    const IACMReady = ((IACMLocationId && !IACMConnected) && (!!code && locations.length > 0));

    useEffect(() => {
        if (loggedIn && !code) setStatusText("No OAuth code provided!");
    }, [loggedIn, code]);

    useEffect(() => {
        if (IACMLocation.itsACheckMateLocationId) setIACMLocationId(IACMLocation.itsACheckMateLocationId);
    }, [IACMLocation]);

    useEffect(() => {
        if (!IACMConnected && locations.length > 0 && !!code) {
            if (IACMReady) setStatusText("Select a Location:");
            else if (locationsLoading || IACMLoading) setStatusText("Loading...");
            else setStatusText("Error Connecting to ItsaCheckmate.");
        }
    }, [IACMReady, IACMConnected, locations, code]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleLocationSelect = (event: SelectChangeEvent) => {
        setLocationId(event.target.value);
    };

    const handleIACMConnect = async () => {
        setStatusText("Connecting Location...");
        await setIACMAssociation({ locationId, IACMLocationId })
            .then(() => {
                setStatusText("ItsaCheckmate Connected!");
                apiSuccess("ItsaCheckmate Connected!");
                setIACMConnected(true);
            })
            .catch((err) => apiError(err));
    }

    return <Centered><Box sx={{ width: "100%", maxWidth: "800px" }}>

        <AuthenticatedTemplate>

            {!IACMConnected && <Stack direction="column" spacing={2}>

                <Typography variant="h6" align="center">{statusText}</Typography>

                {/* IACM association has everything it needs: */}
                {IACMReady && <React.Fragment>
                    <Stack direction="column" spacing={0}>
                        <Typography variant="body2" align="center">Select an OhWaiter location to associate to the ItsaCheckmate location:</Typography>
                        <Typography variant="body2" align="center"><strong>{IACMLocation.name}</strong> ({IACMLocation.itsACheckMateLocationId}), {IACMLocation.address}</Typography>
                    </Stack>
                    <FormControl sx={{ minWidth: 100 }}>
                        <InputLabel>OhWaiter Location</InputLabel>
                        <Select label="OhWaiter Location" value={locationId?.toString()} onChange={handleLocationSelect}>
                            {locations.map(location =>
                                <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </React.Fragment>}

                {/* Things are loading: */}
                {(!IACMReady && (locationsLoading || IACMLoading)) && <React.Fragment>
                    <Stack direction="column" spacing={0}>
                        <Box width="100%" textAlign="center">
                            <CircularProgress />
                        </Box>
                    </Stack>
                </React.Fragment>}

                {/* Things loaded, but something's missing: */}
                {(!IACMReady && (!locationsLoading && !IACMLoading)) && <React.Fragment>
                    <Stack direction="column" spacing={0}>
                        <Typography variant="body2" align="center">ItsaCheckmate auth code missing or invalid.</Typography>
                    </Stack>
                </React.Fragment>}

            </Stack>}

            {IACMConnected && <Stack direction="column" spacing={2} sx={{ my: 3 }}>
                <Typography variant="h6" align="center">{statusText}</Typography>
            </Stack>}

            <Stack direction="column" spacing={2} sx={{ my: 3 }}>
                {(IACMReady && !IACMConnected) && <Button variant="contained" onClick={handleIACMConnect} disabled={!!!locationId}>Connect</Button>}
                {(IACMConnected || !IACMReady) && <Button component={Link} to="/" variant="contained">Go to Dashboard</Button>}
            </Stack>

        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>

            <Stack direction="column" spacing={2} sx={{ my: 3 }} textAlign="center">
                <Box textAlign="center">
                    <a href="https://ohwaiter.com/" style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">
                        <img src="/ohwaiter-logo.svg" alt="OhWaiter Logo" width="200" />
                        <Typography variant="subtitle2" align="center" color="#009cdb" mt={1}>Make Every Guest a VIP</Typography>
                    </a>
                </Box>
                <Typography align="center" variant="h6">Connect ItsaCheckmate to OhWaiter</Typography>
                <Typography align="center" variant="body1">
                    Sign in to connect ItsaCheckmate to your existing OhWaiter account.
                    Don’t have OhWaiter yet? No problem! We’ll just need a few things from you to get you set up.&nbsp;
                    <a href="https://formstack.io/DD9D1" target="_blank" rel="noreferrer">Head here to start the on-boarding process.</a>
                </Typography>
                <Box><Box sx={{ my: 3 }}><LoginButton /></Box></Box>
            </Stack>

        </UnauthenticatedTemplate>

    </Box></Centered>

}