import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useSetChatTranscriptTagsMutation } from '../../Redux/Services/OhWaiter';
import { TranscriptRequest } from '../../Types';
import HelperText from '../../Components/HelperText';
import { explodeInput } from '../../Helpers/StringUtils';
import { apiSuccess, apiError } from '../../Helpers/Toaster';
import { smallModalSx } from '../../Constants';

type ModalProps = {
    transcript: TranscriptRequest;
    currentTags: string[],
    open: boolean;
    handleClose: (open: boolean) => void;
};

const ChatTagsEdit: React.FC<ModalProps> = ({ transcript, currentTags, open, handleClose }) => {

    const { locationId, conversationId, guestHash } = transcript;

    const [newTags, setNewTags] = useState("");
    const [setChatTranscriptTags] = useSetChatTranscriptTagsMutation();
    useEffect(() => setNewTags(currentTags.join(", ")), [currentTags]);

    const handleBucketsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTags(e.target.value);
    };

    const saveBuckets = async () => {
        const tags = explodeInput(newTags);
        await setChatTranscriptTags({ locationId, conversationId, guestHash, tags })
            .then(() => apiSuccess("Buckets Updated!"))
            .catch((err) => apiError(err));
        handleClose(false);
        setNewTags("");
    };

    return <Modal open={open} onClose={handleClose}>
        <Box sx={smallModalSx}>
            <Stack spacing={2}>
                <Typography variant="h6" component="h2" className="bold-header">
                    Transcript Buckets
                </Typography>
                <HelperText>
                    <Typography variant="body2">Transcript <strong>Buckets</strong> allow you to organize guest interactions for education and technical review.</Typography>
                </HelperText>
                <Box component="form" sx={{ my: 3 }} noValidate autoComplete="off">
                    <TextField
                        label="Buckets"
                        variant="outlined"
                        fullWidth
                        multiline
                        value={newTags}
                        onChange={handleBucketsChange}
                    />
                </Box>
                <Grid container sx={{ mt: 3 }}>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Stack direction="row" spacing={1}>
                            {/* Placeholder */}
                        </Stack>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" onClick={() => handleClose(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={saveBuckets}>
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    </Modal>

};

export default ChatTagsEdit;