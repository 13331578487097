import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
// import Looks5Icon from '@mui/icons-material/Looks5';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useGetLocationByIdQuery } from '../Redux/Services/OhWaiter';
import { useGetSignsByLocationIdQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Location as LocationType, Sign as SignType } from '../Types';
import HelperText from './HelperText';
import { formatSms } from '../Helpers/StringUtils';

import './Onboarding.scss';

type Props = {
    locationId?: number
};

const Onboarding: React.FC<Props> = ({ locationId }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [location, setLocation] = useState<LocationType>({});

    // Redux Selectors
    const noMemory = true;
    const { data: locationData = null, isLoading: locationLoading } = useGetLocationByIdQuery({ locationId, noMemory }, { skip: !!!locationId });
    const { data: signsData = null, isLoading: signsLoading } = useGetSignsByLocationIdQuery({ locationId, noMemory }, { skip: !!!locationId });

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Location", status: locationLoading }));
        dispatch(addLoader({ loader: "Sign Templates", status: signsLoading }));
    }, [dispatch, locationLoading, signsLoading]);

    useEffect(() => setLocation(locationData as LocationType), [locationData]); // Redundant, but satisfies TS

    const steps = location ? [
        {
            text: "Zones",
            icon: <LooksOneIcon />,
            url: `/zones?location=${location.id}`,
            complete: () => {
                return (location.zones && location.zones.length > 0);
            }
        },
        {
            text: "Tags",
            icon: <LooksTwoIcon />,
            url: `/tags?location=${location.id}`,
            complete: () => {
                return (location.zones && location.zones.some(l => !!l.qrCount));
            }
        },
        {
            text: "Sign Templates",
            icon: <Looks3Icon />,
            url: `/signs?location=${location.id}`,
            complete: () => {
                const signs = signsData as SignType[];
                return (!!signs && signs.length > 0);
            }
        },
        {
            text: "Auto-Replies",
            icon: <Looks4Icon />,
            url: `/replies?location=${location.id}`,
            complete: () => {
                return (location.responses && location.responses.length > 0);
            }
        }
    ] : [];

    let nextStep = true;
    const allComplete = location && steps.every(step => !!step.complete());

    return <Grid item xs={12}>
        {location && location.id && <React.Fragment>

            <Grid container>
                <Grid item xs={6} display="flex" flexDirection="row">
                    <Typography variant="subtitle1" className="onboarding-header">{location.name}</Typography>
                </Grid>
                {!!location.smsNumber && <Grid item xs={6} display="flex" flexDirection="row-reverse">
                    <Typography variant="subtitle1" className="onboarding-header">
                        <a href={`sms:${location.smsNumber}`}>{formatSms(location.smsNumber)}</a>
                    </Typography>
                </Grid>}
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={0} className="onboarding-wrapper">

                {allComplete && <HelperText variant="success">
                    <Typography variant="body2"><strong>Your location {location.name} is ready to start taking orders!</strong> Generate some sign PDFs through the <Link to={`/signs`}>Sign Templates</Link> section. You can refine your menu items and their synonyms in the <Link to={`/menu`}>Menu Items</Link> section. When your guests use OhWaiter, the chat transcripts will show up under <Link to={`/transcripts`}>Chat Transcripts</Link>.</Typography>
                </HelperText>}

                {!allComplete && steps.map((step, index) => {

                    const completed = !!step.complete();
                    const cellClass = completed ? 'completed' : '';
                    let headerText = '\u00A0';
                    if (!completed && nextStep) { headerText = "Next Step"; nextStep = false; }
                    if (!nextStep && index === 0) headerText = "First Step";
                    if (completed) headerText = "Completed!";

                    return <Grid key={index} item xs={12 / steps.length} textAlign="center" className={`onboarding-step-cell ${cellClass}`}>
                        <div className="onboarding-step-content">

                            <Stack spacing={1} p={2} style={{ cursor: "pointer" }} onClick={() => navigate(step.url, { replace: true })}>

                                <Typography variant="caption">{headerText}</Typography>

                                <div>
                                    <IconButton disabled className="icon-button">
                                        {completed ? <CheckCircleIcon /> : step.icon}
                                    </IconButton>
                                </div>

                                <Typography variant="caption">{step.text}</Typography>

                            </Stack>

                            <div className="step-arrow" />

                        </div>
                    </Grid>

                })}

            </Grid>
        </React.Fragment>}
    </Grid>

};

export default Onboarding;