import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { useGetRepliesByZoneIdQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Reply } from '../Types';
import Calendar from '../Components/Calendar';
import ReplyEdit from '../Components/Modals/ReplyEdit';

const ReplyScheduler: React.FC<{ zoneId: string; }> = ({ zoneId }) => {

    const dispatch = useDispatch();
    const [editId, setEditId] = useState<number | null>(null);

    const [replyEditOpen, setReplyEditOpen] = useState(false);
    const openReplyEdit = () => setReplyEditOpen(true);
    const closeReplyEdit = () => setReplyEditOpen(false);

    // Redux Selectors
    const { data: repliesData = null, isLoading: repliesLoading } = useGetRepliesByZoneIdQuery({ zoneId }, { skip: !!!zoneId });
    const replies = useMemo(() => repliesData as Reply[] || [], [zoneId, repliesData]); // eslint-disable-line react-hooks/exhaustive-deps

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Auto Replies", status: repliesLoading }));
    }, [dispatch, repliesLoading]);

    const handleNewReply = () => {
        setEditId(null);
        openReplyEdit();
    };

    const handleReplyEdit = (id: number) => {
        setEditId(id);
        openReplyEdit();
    };

    return <React.Fragment>

        {/* Button Edit Drawer */}
        {zoneId && <ReplyEdit zoneId={parseInt(zoneId)} replyId={editId} open={replyEditOpen} handleClose={closeReplyEdit} />}

        <Grid item lg={12} md={12} sm={12}>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Calendar
                            replies={replies}
                            handleNew={handleNewReply}
                            handleEdit={handleReplyEdit}
                        />
                    </Grid>
                </Grid>

            </Grid>
        </Grid>

    </React.Fragment>

};

export default ReplyScheduler;