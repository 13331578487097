import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridColDef, GridCellParams } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
import Tooltip from '@mui/material/Tooltip';
import { useGetSignsByLocationIdQuery } from '../Redux/Services/OhWaiter';
import { useDeleteSignTemplateMutation } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Sign } from '../Types';
import Main from '../Layouts/Main';
import LocationSelector from '../Components/LocationSelector';
import SignEdit from '../Components/Modals/SignEdit';
import PdfGenerator from '../Components/Modals/PdfGenerator';
import { apiSuccess, apiError } from '../Helpers/Toaster';

import './Signs.scss';

export default function Signs() {

    const dispatch = useDispatch();
    const [editId, setEditId] = useState<string | null>(null);
    const [locationId, setLocationId] = useState<string>('');

    const [signEditOpen, setSignEditOpen] = useState(false);
    const openSignEdit = () => setSignEditOpen(true);
    const closeSignEdit = () => setSignEditOpen(false);

    const [pdfSelectorOpen, setPdfSelectorOpen] = useState(false);
    const openPdfSelector = () => setPdfSelectorOpen(true);
    const closePdfSelector = () => setPdfSelectorOpen(false);

    // Redux Selectors
    const { data: signsData = null, isLoading: signsLoading } = useGetSignsByLocationIdQuery({ locationId }, { skip: !!!locationId });
    const [deleteSignTemplate] = useDeleteSignTemplateMutation();
    const signs = useMemo(() => signsData as Sign[] || [], [locationId, signsData]); // eslint-disable-line react-hooks/exhaustive-deps

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Sign Templates", status: signsLoading }));
    }, [dispatch, signsLoading]);

    const handleNewTemplate = () => {
        setEditId(null);
        openSignEdit();
    };

    const handleTemplateEdit = (id: string) => {
        setEditId(id);
        openSignEdit();
    };

    const handleTemplateDelete = async (id: string) => {
        deleteSignTemplate({ locationId, templateId: id })
            .then(() => apiSuccess("Template Deleted!"))
            .catch((err) => apiError(err));
    };

    const generatePDF = (id: string) => {
        setEditId(id);
        openPdfSelector();
    };

    const actionsCell = (params: GridCellParams) => {
        const editAction = () => handleTemplateEdit(params.row.templateId);
        const deleteAction = () => handleTemplateDelete(params.row.templateId);
        const makeAction = () => generatePDF(params.row.templateId);
        return <Grid container justifyContent="flex-end" mx={1}>
            <Stack direction="row" alignItems="center" spacing={3}>
                <Tooltip title="Delete Sign Template" placement="top" arrow>
                    <DeleteForeverIcon fontSize="small" color="error" style={{ cursor: "pointer" }} onClick={deleteAction} />
                </Tooltip>
                <Tooltip title="Edit Sign Template" placement="top" arrow>
                    <ModeEditIcon fontSize="small" style={{ cursor: "pointer" }} onClick={editAction} />
                </Tooltip>
                <Tooltip title="Generate PDF" placement="top" arrow>
                    <PictureAsPdfSharpIcon fontSize="small" style={{ cursor: "pointer" }} onClick={makeAction} />
                </Tooltip>
            </Stack>
        </Grid>
    };

    const signColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'templateType',
            headerName: 'Type',
            width: 100,
            cellClassName: 'template-type-cell'
        },
        {
            field: 'templateId',
            headerName: '',
            width: 160,
            renderCell: actionsCell
        }
    ];

    return <Main header="Sign Templates">

        {/* Template Edit Drawer */}
        {(locationId) &&
            <SignEdit
                locationId={parseInt(locationId)}
                templateId={editId}
                open={signEditOpen}
                handleClose={closeSignEdit}
                handleReload={handleTemplateEdit}
            />}

        {/* PDF Generator Dialog */}
        {(locationId && editId) &&
            <PdfGenerator
                locationId={parseInt(locationId)}
                templateId={editId}
                open={pdfSelectorOpen}
                handleClose={closePdfSelector}
            />}

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector selectionHandler={setLocationId} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Button variant="contained" onClick={handleNewTemplate} startIcon={<AddCircleIcon />} disabled={!locationId}>
                            New Sign Template
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <DataGridPro
                            columns={signColumns}
                            rows={(!locationId) ? [] : signs}
                            getRowId={(row) => row.templateId}
                            autoHeight
                            hideFooter
                        />

                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    </Main >

};