import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useSaveTagsMutation, useDeleteTagsMutation } from '../../Redux/Services/OhWaiter';
import HelperText from '../../Components/HelperText';
import { explodeInput } from '../../Helpers/StringUtils';
import { apiSuccess, apiError } from '../../Helpers/Toaster';
import { smallModalSx } from '../../Constants';

type ModalProps = {
    zoneId: number;
    qrId?: number | null;
    open: boolean;
    handleClose: (open: boolean) => void;
};

const QrEdit: React.FC<ModalProps> = ({ zoneId, qrId, open, handleClose }) => {

    const [qrNames, setQrNames] = useState('');
    const [saveTags] = useSaveTagsMutation();
    const [deleteTags] = useDeleteTagsMutation();

    useEffect(() => {
        setQrNames('');
    }, [qrId]);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQrNames(e.target.value);
    };

    const saveQr = async () => {
        const explodedTags = explodeInput(qrNames, true);
        await saveTags({ zoneId, tags: explodedTags })
            .then(() => apiSuccess("Tags Saved!"))
            .catch((err) => apiError(err));
        setQrNames('');
        handleClose(false);
    };

    const deleteQr = async () => {
        await deleteTags({ zoneId, tags: [qrId] })
            .then(() => apiSuccess("Tag Removed!"))
            .catch((err) => apiError(err));
        setQrNames('');
        handleClose(false);
    };

    return <Modal open={open} onClose={handleClose}>
        <Box sx={smallModalSx}>
            <Stack spacing={2}>
                <Typography variant="h6" component="h2" className="bold-header">
                    {(!!qrId) ? 'Edit QR Tag' : 'New QR Tag'}
                </Typography>
                <HelperText>
                    <Typography variant="body2"><strong>Tags</strong> identify specific guests within a specific area (<strong>Zone</strong>) at your location. These can be table numbers, room numbers, or any other identifier. You can define multiple tags separated by commas or new lines, and you can specify ranges of tags using hyphens. For example: <strong>1-5, 10-15, 20, 21</strong> or <strong>T1-T10</strong> or <strong>Pool1-Pool5</strong>.</Typography>
                </HelperText>
                <Box component="form" sx={{ my: 3 }} noValidate autoComplete="off">
                    <TextField
                        label={(!!qrId) ? 'Name' : 'Names'}
                        variant="outlined"
                        fullWidth
                        multiline={!!!qrId}
                        value={qrNames}
                        onChange={handleNameChange}
                    />
                </Box>
                <Grid container sx={{ mt: 3 }}>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Stack direction="row" spacing={1}>
                            {qrId && <Button variant="outlined" color="error" onClick={deleteQr}>
                                Delete
                            </Button>}
                        </Stack>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" onClick={() => handleClose(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={saveQr} disabled={!!qrId}>
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    </Modal>

};

export default QrEdit;