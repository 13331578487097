// import React from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import md5 from "md5";
import Gravatar from "react-gravatar";
import Main from '../Layouts/Main';
import GuestDetails from '../Components/GuestDetails';

export default function GuestDetail() {

    let { guest_id } = useParams();

    return <Main header={`Guest Detail: ${guest_id}`}>
        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            {guest_id && <Gravatar
                                size={80}
                                style={{ margin: "0 0 0 0", borderRadius: "50%" }}
                                md5={md5(guest_id)}
                            />}
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        {/* Placeholder */}
                    </Grid>
                </Grid>
            </Grid>

            {/* Main */}
            <Grid item lg={12} md={12} sm={12}>
                {guest_id && <GuestDetails guestId={guest_id} />}
            </Grid>

        </Grid>
    </Main >

};