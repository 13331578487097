import React, { useState, useEffect, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Divider from '@mui/material/Divider';
import { useGetRepliesByZoneIdQuery } from '../../Redux/Services/OhWaiter';
import { useSaveReplyMutation, useDeleteRepliesMutation } from '../../Redux/Services/OhWaiter';
import { Reply } from '../../Types';
import HelperText from '../../Components/HelperText';
import { apiSuccess, apiError } from '../../Helpers/Toaster';
import { daysOfWeek, circleButtonSx, editSlideSx } from '../../Constants';

type ModalProps = {
    zoneId: number;
    replyId?: number | null;
    open: boolean;
    handleClose: (open: boolean) => void;
};

const ReplyEdit: React.FC<ModalProps> = ({ zoneId, replyId, open, handleClose }) => {

    const dummyDate = '2023-01-01'; // Not used, but needed for valid date format
    const defaultStartTime = dayjs(`${dummyDate}T09:00:00`);
    const defaultEndTime = dayjs(`${dummyDate}T11:00:00`);
    const noDaysSelected = Array(7).fill(false);

    // Component State
    const [replyLabel, setReplyLabel] = useState('');
    const [replyText, setReplyText] = useState('');
    const [activeDays, setActiveDays] = useState<boolean[]>(noDaysSelected);
    const [startTime, setStartTime] = useState<Dayjs | null>(defaultStartTime);
    const [endTime, setEndTime] = useState<Dayjs | null>(defaultEndTime);

    // Redux Selectors
    const { data: repliesData = null } = useGetRepliesByZoneIdQuery({zoneId}, { skip: !!!zoneId });
    const [saveReply] = useSaveReplyMutation();
    const [deleteReplies] = useDeleteRepliesMutation();
    const replies = useMemo(() => repliesData as Reply[] || [], [zoneId, repliesData]); // eslint-disable-line react-hooks/exhaustive-deps

    // Populate existing fields to edit:
    useEffect(() => {
        if (!!replyId) {
            const reply = replies.find(r => r.responseId === replyId.toString());
            if (reply) {
                clearForm();
                if (reply.name) setReplyLabel(reply.name);
                if (reply.response) setReplyText(reply.response);
                if (reply.startTime) setStartTime(dayjs(`${dummyDate}T${reply.startTime}`));
                if (reply.endTime) setEndTime(dayjs(`${dummyDate}T${reply.endTime}`));
                if (reply.daysOfWeek) {
                    const newActiveDays = [...noDaysSelected];
                    let currentActive: number[] = reply.daysOfWeek.split(",").map(d => parseInt(d));
                    [...Array(7)].map((_, i) => { if (currentActive.includes(i)) newActiveDays[i] = true; return true; });
                    setActiveDays(newActiveDays);
                }
            } else clearForm();
        } else clearForm();
    }, [replyId, replies]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeLabel = (e: React.ChangeEvent<HTMLInputElement>) => setReplyLabel(e.target.value);
    const changeReply = (e: React.ChangeEvent<HTMLInputElement>) => setReplyText(e.target.value);
    const changeStartTime = (val: Dayjs | null) => setStartTime(val);
    const changeEndTime = (val: Dayjs | null) => setEndTime(val);

    const toggleDay = (i: number) => {
        const newActiveDays = [...activeDays];
        newActiveDays[i] = !newActiveDays[i];
        setActiveDays(newActiveDays);
    };

    const clearForm = () => {
        setActiveDays(noDaysSelected);
        setStartTime(defaultStartTime);
        setEndTime(defaultEndTime);
        setReplyLabel('');
        setReplyText('');
    };

    const handleSave = async () => {
        let days: string[] = [];
        [...Array(7)].map((_, i) => { if (!!activeDays[i]) days.push(i.toString()); return true; });
        const payload: Reply = {
            name: replyLabel,
            response: replyText,
            startTime: dayjs(startTime).format('HH:mm'),
            endTime: dayjs(endTime).format('HH:mm'),
            daysOfWeek: days.join(","),
            ordinal: 0
        };
        if (!!replyId) payload.responseId = replyId.toString();
        await saveReply({ zoneId, reply: payload })
            .then(() => apiSuccess("Reply " + ((!!replyId) ? "Updated!" : "Added!")))
            .catch((err) => apiError(err));
        clearForm();
        handleClose(false);
    };

    const handleDelete = async () => {
        await deleteReplies({ zoneId, replyIds: [replyId?.toString()] })
            .then(() => apiSuccess("Reply Deleted!"))
            .catch((err) => apiError(err));
        clearForm();
        handleClose(false);
    };

    return <Drawer anchor="right" open={open} onClose={handleClose}>
        <Box sx={editSlideSx}>
            <Stack spacing={4}>

                <Typography variant="h6" component="h2" className="bold-header">
                    {(!!replyId) ? 'Edit' : 'New'} Automatic Reply
                </Typography>

                <HelperText>
                    <Typography variant="body2">Here you can create a new <strong>Automatic Reply</strong> that guests will receive when they greet OhWaiter from a specific zone, during a specified time of day. This will ensure that your guests will see (for example) the correct breakfast, lunch, or dinner menu during the corresponding hours, and that they can be told that the kitchen isn't serving orders if they use OhWaiter outside of that area's operating hours.</Typography>
                </HelperText>

                <Box component="form" sx={{ my: 3 }} noValidate autoComplete="off">
                    <Stack spacing={2}>

                        <FormControl>
                            <TextField
                                label="Reply Name"
                                variant="outlined"
                                value={replyLabel}
                                onChange={changeLabel}
                            />
                        </FormControl>

                        <FormControl>
                            <TextField
                                label="Message"
                                variant="outlined"
                                fullWidth
                                multiline
                                value={replyText}
                                onChange={changeReply}
                            />
                        </FormControl>

                        <Stack spacing={1}>
                            <Typography variant="subtitle2">
                                Schedule
                            </Typography>
                            <Divider />
                        </Stack>

                        <Stack direction="row" spacing={1}>
                            {daysOfWeek.map((day, i) => <Tooltip key={i} title={day} placement="top">
                                <Button
                                    sx={circleButtonSx}
                                    variant={(activeDays[i]) ? 'contained' : 'outlined'}
                                    onClick={() => toggleDay(i)}
                                >{day.charAt(0)}</Button>
                            </Tooltip>)}
                        </Stack>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack direction="row" spacing={2}>
                                <TimePicker
                                    label="Start Time"
                                    value={startTime}
                                    onChange={changeStartTime}
                                    disabled={!(activeDays.some((val) => val))}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <TimePicker
                                    label="End Time"
                                    value={endTime}
                                    onChange={changeEndTime}
                                    disabled={!(activeDays.some((val) => val))}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>

                    </Stack>
                </Box>

                <Stack spacing={2}>
                    <Button variant="contained" onClick={handleSave}>
                        Save
                    </Button>
                    {replyId && <Button variant="contained" color="error" onClick={handleDelete}>
                        Delete
                    </Button>}
                </Stack>

            </Stack>
        </Box>
    </Drawer>

};

export default ReplyEdit;