import { homePage, theCurb } from './Constants';

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_MSAL_CLIENT_ID}`,
    authority: `https://${process.env.REACT_APP_MSAL_KNWON_AUTHORITY}/${process.env.REACT_APP_MSAL_KNWON_AUTHORITY}/B2C_1A_SIGNUP_SIGNIN`,
    redirectUri: `${process.env.REACT_APP_MSAL_REDIRECT_URL}`,
    knownAuthorities: [`${process.env.REACT_APP_MSAL_KNWON_AUTHORITY}`],
    validateAuthority: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: [`${process.env.REACT_APP_MSAL_SCOPE}`],
  redirectUri: homePage
};

export const logoutRequest = {
  postLogoutRedirectUri: theCurb
};

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
    forgotPassword: "b2c_1_reset",
    editProfile: "b2c_1_edit_profile"
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${process.env.REACT_APP_MSAL_KNWON_AUTHORITY}/${process.env.REACT_APP_MSAL_KNWON_AUTHORITY}/B2C_1A_SIGNUP_SIGNIN`,
      scopes: [`${process.env.REACT_APP_MSAL_SCOPE}`]
    },
    forgotPassword: {
      authority: `https://${process.env.REACT_APP_MSAL_KNWON_AUTHORITY}/${process.env.REACT_APP_MSAL_KNWON_AUTHORITY}/b2c_1_reset`,
      scopes: [`${process.env.REACT_APP_MSAL_SCOPE}`]
    },
    editProfile: {
      authority: `https://${process.env.REACT_APP_MSAL_KNWON_AUTHORITY}/${process.env.REACT_APP_MSAL_KNWON_AUTHORITY}/b2c_1_edit_profile`,
      scopes: [`${process.env.REACT_APP_MSAL_SCOPE}`]
    }
  },
  authorityDomain: process.env.REACT_APP_MSAL_KNWON_AUTHORITY
}