export const sharedPrefix = (words: string[]) => {
  if (!words[0] || words.length === 1) return words[0] || "";
  let i: number = 0;
  let c: string = words[0][i];
  while (/^\D$/.test(c) && words.every(w => w[i] === c)) { i++; c = words[0][i]; }; // eslint-disable-line
  return words[0].substr(0, i);
}

export const getNumbersFromRange = (start: number, end: number) => {
  let [x, y] = [start, end];
  if (y <= x) return [x, y];
  return Array.from((function* () {
    while (x <= y) yield x++;
  })());
};

export const getCharsFromRange = (start: string, end: string) => {
  const x = start.charCodeAt(0), y = end.charCodeAt(0);
  if (y <= x) return [start, end];
  return [...Array(Math.floor(y - x) + 1)].map((_, i) => String.fromCharCode(x + i));
};

export const conjureFromRange = (sides: string[], prefix: string = '') => {
  if (sides.length === 2) { // Could be a range...
    sides = sides.map(side => side.trim());
    if (sides.every(s => /^[A-Za-z]$/.test(s))) { // Both are letters
      return getCharsFromRange(sides[0], sides[1]).map(n => prefix + n);
    }
    if (!isNaN(+sides[0]) && !isNaN(+sides[1])) { // Both are numbers
      return getNumbersFromRange(parseInt(sides[0]), parseInt(sides[1])).map(n => prefix + n);
    }
  }
  // Not a range, so reassemble string and return it:
  return [sides.map(side => prefix + side).join('-')];
};

export const convertToInches = (size: any) => {
  return (parseFloat(size) / 96).toFixed(2).toString();
};

export const convertToPX = (size: any) => {
  return Math.round(parseFloat(size) * 96);
};

export const explodeInput = (input: string, conjure = false) => {
  const explodedTags: string[] = [];
  const lines = (input) ? input.split("\n").map(line => line.trim()) : [];
  lines.forEach(line => {
    const parts = line.split(",").map(part => part.trim());
    parts.forEach(part => {
      if (conjure) {
        const hyphens = part.match(/-/g) || [];
        if (hyphens.length === 1) { // Is a range, with two sides...
          const sides = part.split("-").map(side => side.trim());
          const prefix = sharedPrefix(sides);
          const range1 = sides[0].slice(prefix.length);
          const range2 = sides[1].slice(prefix.length);
          explodedTags.push(...conjureFromRange([range1, range2], prefix));
        } else explodedTags.push(part);
      } else explodedTags.push(part);
    });
  });
  return explodedTags;
}

export const stripPunctuation = (input: string) => {
  return input.replace(/[.,/#!$%^&*;:{}=_`~()]/g, "");
}

export const capitalizeWords = (input: string) => {
  const words = input.split(" ");
  const output = words.map(word => {
    return word[0].toUpperCase() + word.substring(1);
  }).join(" ");
  return output;
}

export const formatSms = (n: string) => {
  return `${n.slice(2, 5)}-${n.slice(5, 8)}-${n.slice(8)}`;
}

export const getRandomString = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}