import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridColDef, GridCellParams } from '@mui/x-data-grid-pro';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IosShareIcon from '@mui/icons-material/IosShare';
import Tooltip from '@mui/material/Tooltip';
import Moment from 'moment';
import { useGetPDFsByLocationIdQuery, useDeletePDFMutation } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { PDF } from '../Types';
import Main from '../Layouts/Main';
import LocationSelector from '../Components/LocationSelector';
import { apiSuccess, apiError } from '../Helpers/Toaster';

export default function Pdfs() {

    const dispatch = useDispatch();
    const [locationId, setLocationId] = useState<string>('');

    // Redux Selectors
    const { data: pdfsData = null, isLoading: pdfsLoading } = useGetPDFsByLocationIdQuery({ locationId }, { skip: !!!locationId });
    const pdfs = useMemo(() => pdfsData as PDF[] || [], [locationId, pdfsData]); // eslint-disable-line react-hooks/exhaustive-deps
    const [deletePDF] = useDeletePDFMutation();

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "PDF Files", status: pdfsLoading }));
    }, [dispatch, pdfsLoading]);

    const handlePDFShare = async (url: string) => {
        navigator.clipboard.writeText(url);
        apiSuccess("URL Copied to Clipboard!")
    };

    const handlePDFDelete = async (id: string) => {
        deletePDF({ locationId, pdfId: id })
            .then(() => apiSuccess("PDF Deleted!"))
            .catch((err) => apiError(err));
    };

    const dateCell = (params: GridCellParams) => Moment(params.row.createdAtUtc).format('M/D/YY, h:mm A');

    const tagsCell = (params: GridCellParams) => params.row.tags.join(", ");

    const actionsCell = (params: GridCellParams) => {
        const ready = { cursor: "pointer" };
        const spinning = { animation: `spin 3s linear infinite` };
        const downloadAction = () => window.open(params.row.downloadLink);
        const deleteAction = () => handlePDFDelete(params.row.pdfId);
        const shareAction = () => handlePDFShare(params.row.downloadLink);
        const [downloadIcon, tipText] = (params.row.status !== "Ready")
            ? [<TimelapseIcon fontSize="small" color="disabled" style={spinning} />, "PDF Brewing..."]
            : [<FileDownloadIcon fontSize="small" style={ready} onClick={downloadAction} />, "Download PDF"];
        return <Grid container justifyContent="flex-end" mx={1}>
            <Stack direction="row" alignItems="center" spacing={3}>
                <Tooltip title="Delete Generated PDF" placement="top" arrow>
                    <DeleteForeverIcon fontSize="small" color="error" style={{ cursor: "pointer" }} onClick={deleteAction} />
                </Tooltip>
                <Tooltip title="Copy URL" placement="top" arrow>
                    <IosShareIcon fontSize="small" style={{ cursor: "pointer" }} onClick={shareAction} />
                </Tooltip>
                <Tooltip title={tipText} placement="top" arrow>
                    {downloadIcon}
                </Tooltip>
            </Stack>
        </Grid>
    };

    const pdfColumns: GridColDef[] = [
        {
            field: 'createdAtUtc',
            headerName: 'Generated',
            width: 180,
            renderCell: dateCell
        },
        {
            field: 'templateName',
            headerName: 'Template',
            width: 200
        },
        {
            field: 'tags',
            headerName: 'Tags',
            flex: 1,
            renderCell: tagsCell
        },
        {
            field: 'templateId',
            headerName: '',
            width: 160,
            renderCell: actionsCell
        }
    ];

    return <Main header="Sign PDF Downloads">

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector selectionHandler={setLocationId} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        {/* Placeholder */}
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DataGridPro
                            columns={pdfColumns}
                            rows={(!locationId) ? [] : pdfs}
                            getRowId={(row) => row.pdfId}
                            autoHeight
                            hideFooter
                        />
                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    </Main >

};