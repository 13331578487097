import { Location, Zone } from '../Types';

export const autoLocation = (
  selectedLocationId: string,
  recentLocationId: number | null,
  savedLocationId: string | null,
  availableLocations: Location[] | undefined,
  locationJump: string | null = null
) => {

  let ret = selectedLocationId;
  const availableIds = (availableLocations) ? availableLocations.map(location => location.id) : [];
  if (locationJump && availableIds.includes(locationJump)) ret = locationJump;
  else {
    if (availableLocations && availableLocations[0] && availableLocations[0].id) {
      if (savedLocationId && availableIds.includes(savedLocationId.toString())) ret = savedLocationId.toString();
      else if (recentLocationId && availableIds.includes(recentLocationId.toString())) ret = recentLocationId.toString();
      else ret = availableLocations[0].id.toString();
    }
  }
  if (!availableLocations || availableLocations.length < 1) ret = '';
  return ret;

};

export const autoZone = (
  selectedZoneId: string,
  recentZoneId: number | null,
  savedZoneId: string | null,
  availableZones: Zone[] | undefined,
  zoneJump: string | null = null
) => {

  let ret = selectedZoneId;
  const availableIds = (availableZones) ? availableZones.map(zone => zone.zoneId) : [];
  if (zoneJump && availableIds.includes(zoneJump)) ret = zoneJump;
  else {
    if (availableZones && availableZones[0] && availableZones[0].zoneId) {
      if (savedZoneId && availableIds.includes(savedZoneId.toString())) ret = savedZoneId.toString();
      else if (recentZoneId && availableIds.includes(recentZoneId.toString())) ret = recentZoneId.toString();
      else ret = availableZones[0].zoneId.toString();
    }
  }
  if (!availableZones || availableZones.length < 1) ret = '';
  return ret;

};