import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridColDef, GridRowParams, GridCellParams } from '@mui/x-data-grid-pro';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tooltip from '@mui/material/Tooltip';
import md5 from "md5";
import Gravatar from "react-gravatar";
import { useGetLocationsQuery } from '../Redux/Services/OhWaiter';
import { useGetGuestsQuery, useGetGuestsByLocationIdQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Location as LocationType, GuestData as GuestDataType } from '../Types';
import Main from '../Layouts/Main';
import LocationSelector from '../Components/LocationSelector';
import GuestView from '../Components/Modals/GuestView';
import { moneyFormat } from '../Helpers/Money';
import { dataGridSx } from '../Constants';

export default function Guests() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [locationId, setLocationId] = useState<string>('all');
    const [guestsData, setGuestsData] = useState<GuestDataType[]>([]);
    const [page, setPage] = useState(0);
    const [totalRows, setTotalRows] = useState<number>(0);
    const itemsPerPage = 40;

    // Slide-Over Guest Detail
    const [guestViewId, setGuestViewId] = useState<string | null>(null);
    const closeGuestView = () => setGuestViewId(null);

    // Redux Selectors
    const customerId = useSelector((state: any) => state.userSlice.customerId);
    const { data: locationsData = null, isLoading: locationsLoading } = useGetLocationsQuery(customerId);
    const { data: allGuestsData = null, isLoading: allGuestsLoading } = useGetGuestsQuery({
        offset: itemsPerPage * page,
        limit: itemsPerPage
    });
    const { data: locationGuestsData = null, isLoading: locationGuestsLoading } = useGetGuestsByLocationIdQuery({
        locationId,
        offset: itemsPerPage * page,
        limit: itemsPerPage
    }, { skip: (!!!locationId || locationId.toLowerCase() === 'all') });

    const locations = useMemo(() => locationsData as LocationType[] || [], [locationsData]);
    const allGuests = useMemo(() => allGuestsData as GuestDataType[] || [], [allGuestsData]);
    const guestsByLocation = useMemo(() => locationGuestsData as GuestDataType[] || [], [locationGuestsData]);

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Locations", status: locationsLoading }));
        dispatch(addLoader({ loader: "Guests", status: allGuestsLoading }));
        dispatch(addLoader({ loader: "Guests by Location", status: locationGuestsLoading }));
    }, [dispatch, locationsLoading, allGuestsLoading, locationGuestsLoading]);

    // Handle Loaded Guests Data Set
    useEffect(() => {
        let dataset: any = null;
        if (!!locationId && locationId.toLowerCase() !== 'all') dataset = guestsByLocation;
        else dataset = allGuests;
        setGuestsData(dataset.items || []);
        setTotalRows(dataset.totalItemCount || 0);
    }, [locationId, allGuests, guestsByLocation, page]);

    const handleChangePage = (page: number) => setPage(page);

    const handleRowClick = (params: GridRowParams) => {
        const { guestId } = params.row;
        setGuestViewId(guestId as string);
    }

    const handleTranscriptsFilter = (e: any, row: GuestDataType) => {
        e.stopPropagation();
        const { guestId, locationId } = row;
        navigate(`/transcripts/?location=${locationId}&guestId=${guestId}`);
    }

    const avatarCell = (params: GridCellParams) => {
        const { guestId } = params.row;
        return <React.Fragment>
            <span><Gravatar
                size={30}
                style={{ margin: "7px 10px 0 6px", borderRadius: "50%" }}
                md5={md5(guestId)}
            /></span>
            <span>{guestId}</span>
        </React.Fragment>
    };

    const moneyCell = (params: GridCellParams) => moneyFormat(params.row.totalPaidInCents);

    const locationCell = (params: GridCellParams) => {
        const { locationId } = params.row;
        const locationLookup = locations.find(l => locationId === l.id);
        return (locationLookup ? locationLookup.name : `Unknown Location (ID #${locationId})`);
    }

    const transcriptsCell = (params: GridCellParams) => {
        const { locationId, transcriptCount } = params.row;
        const locationLookup = locations.find(l => locationId === l.id);
        return <React.Fragment>
            <Box className="centered-icon-text">
                {!!locationLookup
                    ? <Tooltip title="Filter Transcripts" placement="top" arrow>
                        <FilterAltIcon fontSize="small" onClick={(e) => handleTranscriptsFilter(e, params.row)} />
                    </Tooltip>
                    : <FilterAltIcon fontSize="small" className="appear-disabled" />
                }
                <span>{transcriptCount}</span>
            </Box>
        </React.Fragment>
    }

    const guestColumns: GridColDef[] = [
        {
            field: 'guestId',
            headerName: 'Guest ID',
            width: 200,
            renderCell: avatarCell
        },
        {
            field: 'locationId',
            headerName: 'Location',
            flex: 1,
            renderCell: locationCell
        },
        {
            field: 'totalPaidInCents',
            headerName: 'Total Paid',
            width: 180,
            renderCell: moneyCell
        },
        {
            field: 'transcriptCount',
            headerName: 'Transcripts',
            flex: 1,
            renderCell: transcriptsCell
        }
    ];

    const noLocationColumn = [...guestColumns]; noLocationColumn.splice(1, 1);

    return <Main header="Guests">

        <GuestView
            guestId={guestViewId}
            open={!!guestViewId}
            handleClose={closeGuestView}
        />

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector allOption selectionHandler={setLocationId} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        {/* Placeholder */}
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <DataGridPro
                            columns={locationId.toLowerCase() === 'all' ? guestColumns : noLocationColumn}
                            rows={(!locationId) ? [] : guestsData}
                            getRowId={(row) => `${row.locationId}-${row.guestId}`}
                            onRowClick={handleRowClick}
                            disableSelectionOnClick
                            sx={dataGridSx}
                            pagination
                            paginationMode="server"
                            rowCount={totalRows}
                            page={page}
                            pageSize={itemsPerPage}
                            rowsPerPageOptions={[itemsPerPage]}
                            onPageChange={handleChangePage}
                            autoHeight
                        />

                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    </Main >

};