import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useGetLocationByIdQuery } from '../Redux/Services/OhWaiter';
import { useGetTagsByZoneIdQuery } from '../Redux/Services/OhWaiter';
import { useGetLocationSettingsQuery } from '../Redux/Services/OhWaiter';
import { useLaunchTestMutation } from '../Redux/Services/Validation';
import { Location, Tag, ValidationRequest, UserSettings } from '../Types';
import Main from '../Layouts/Main';
import LocationSelector from '../Components/LocationSelector';
import ObjectBuilder from '../Components/ObjectBuilder';
import HelperText from '../Components/HelperText';
import { explodeInput } from '../Helpers/StringUtils';
import { apiSuccess, apiError } from '../Helpers/Toaster';

export default function Validation() {

    const [searchParams] = useSearchParams();
    const [locationId, setLocationId] = useState<string>('');
    const [zoneId, setZoneId] = useState<string>('');
    const [greeting, setGreeting] = useState('');
    const [testItems, setTestItems] = useState('');
    const [testGroup, setTestGroup] = useState<string | null>(null);
    const [launchTest] = useLaunchTestMutation();

    // Redux Selectors
    const { data: locationData = null, isLoading: locationLoading } = useGetLocationByIdQuery({ locationId }, { skip: !!!locationId });
    const { data: tagsData = null, isLoading: tagsLoading } = useGetTagsByZoneIdQuery({ zoneId }, { skip: !!!zoneId });
    const location = useMemo(() => locationData as Location || {}, [locationData]);
    const tags = useMemo(() => tagsData as Tag[] || {}, [tagsData]);

    // Get Test Groups from Settings
    const { data: locationSettingsData = null } = useGetLocationSettingsQuery({ locationId }, { skip: !!!locationId });

    const validationLists = useMemo(() => {
        const settings = locationSettingsData as UserSettings;
        return (settings && settings.validationLists) ? JSON.parse(settings.validationLists) || {} : {};
    }, [locationSettingsData]);

    const availableTestGroups = useMemo(() => {
        const settings = locationSettingsData as UserSettings;
        const groups = (settings && settings.validationLists) ? Object.keys(JSON.parse(settings.validationLists)) || [] : [];
        return ['', ...groups];
    }, [locationSettingsData]);

    // Test Menu Item
    useEffect(() => {
        if (searchParams.get("item")) {
            const itemQuery = searchParams.get("item") || '';
            setTestItems(itemQuery);
        }
    }, [searchParams]);

    const handleZoneSelect = (event: SelectChangeEvent) => setZoneId(event.target.value);
    const handleItemsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTestItems(e.target.value);
        setTestGroup('');
    }
    const handleGroupChange = (value: string | null) => {
        setTestGroup(value);
        setTestItems('');
    }

    useEffect(() => {
        if (!!zoneId && !!tags.length && location.zones) {
            const zone = location.zones.find(l => l.zoneId === zoneId);
            setGreeting(zone ? `${zone.name} ${tags[0].tagName}` : '');
        } else setGreeting('');
    }, [location, zoneId, tags]);

    const handleLaunchTest = async () => {
        let arrayToRunWith: string[] = [];
        if (!!testGroup && testGroup in validationLists) arrayToRunWith = validationLists[testGroup];
        else arrayToRunWith = explodeInput(testItems);
        if (arrayToRunWith.length > 0) {
            const launchPayload: ValidationRequest = {
                location_id: locationId,
                location_sms: location.smsNumber,
                items: arrayToRunWith,
                greeting
            }
            await launchTest(launchPayload)
                .then(() => apiSuccess("Test Launched!"))
                .catch((err) => apiError(err));
            setTestItems('');
        } else apiError("No Items!");
    };

    const launchReady = (!locationLoading && !tagsLoading && !!greeting && (!!testItems || !!testGroup));

    return <Main header="Menu Validation">

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector selectionHandler={setLocationId} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        {/* Placeholder */}
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <HelperText>
                            <Typography variant="body2">This tool will validate menu items by simulating a conversation with OhWaiter. After launching a test, the conversation will appear in <strong>Chat Transcripts</strong> for review.</Typography>
                        </HelperText>

                        {(location && location.zones) && <FormControl variant="outlined" sx={{ mt: 3 }} fullWidth>
                            <InputLabel>Zone</InputLabel>
                            <Select label="Zone" value={zoneId} onChange={handleZoneSelect}>
                                {location.zones.map(zone =>
                                    <MenuItem key={zone.zoneId} value={zone.zoneId}>{zone.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>}

                        <Box sx={{ my: 3 }}>
                            <Grid container spacing={2}>

                                {availableTestGroups.length > 1 && <React.Fragment>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            disablePortal
                                            fullWidth
                                            options={availableTestGroups}
                                            value={testGroup}
                                            onChange={(event: any, newValue: string | null) => {
                                                handleGroupChange(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Group" />}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Or Custom Items"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            value={testItems}
                                            onChange={handleItemsChange}
                                        />
                                    </Grid>
                                </React.Fragment>}

                                {availableTestGroups.length <= 1 && <React.Fragment>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Items"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            value={testItems}
                                            onChange={handleItemsChange}
                                        />
                                    </Grid>
                                </React.Fragment>}

                            </Grid>
                        </Box>

                        <Grid container sx={{ mt: 3 }}>
                            <Grid item xs={6} display="flex" flexDirection="row">
                                <Stack direction="row" spacing={1}>
                                    {/* Placeholder */}
                                </Stack>
                            </Grid>
                            <Grid item xs={6} display="flex" flexDirection="row-reverse">
                                <Stack direction="row" spacing={1}>
                                    <Button variant="contained" onClick={handleLaunchTest} disabled={!launchReady}>
                                        Launch Test
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>

                        {!!locationId && <Box sx={{ my: 3 }}>
                            <Stack direction="column" spacing={2}>
                                <Divider />
                                <Typography variant="body1"><strong>Manage Validation Lists</strong></Typography>
                                <ObjectBuilder
                                    locationId={locationId}
                                    settingName="validationLists"
                                    settingType="location"
                                    description={<Typography variant="body2">Manage groups of menu items to test with this automated testing tool.</Typography>}
                                />
                            </Stack>
                        </Box>}

                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    </Main >

};