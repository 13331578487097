export const COMBINER_SUM = "SUM";
export const COMBINER_DATE_TOP = "DATE_TOP";
export const COMBINER_DATE_BOTTOM = "DATE_BOTTOM";
export const SORT_ASC = "ASC";
export const SORT_DESC = "DESC";
export const SORT_DATE_ASC = "DATE_ASC";
export const SORT_DATE_DESC = "DATE_DESC";

export const organizeObjArray = (
  inputObjects: any[],
  keyParameter: string,
  valueParameter: string,
  combiner: string,
  sort?: string | null
) => {

  const returnArray: any[] = [];

  const newObj: any = {};
  inputObjects.forEach(function (obj) {
    if (obj[keyParameter]) {
      switch (combiner) {
        case "SUM":
          if (obj[keyParameter] in newObj) newObj[obj[keyParameter]] += obj[valueParameter];
          else newObj[obj[keyParameter]] = obj[valueParameter];
          break;
        case "DATE_TOP":
          if (obj[keyParameter] in newObj) {
            if (new Date(obj[valueParameter]).getTime() > new Date(newObj[obj[keyParameter]]).getTime()) {
              newObj[obj[keyParameter]] = obj[valueParameter];
            }
          } else newObj[obj[keyParameter]] = obj[valueParameter];
          break;
        case "DATE_BOTTOM":
          if (obj[keyParameter] in newObj) {
            if (new Date(obj[valueParameter]).getTime() < new Date(newObj[obj[keyParameter]]).getTime()) {
              newObj[obj[keyParameter]] = obj[valueParameter];
            }
          } else newObj[obj[keyParameter]] = obj[valueParameter];
          break;
      }
    }
  });

  for (const [key, value] of Object.entries(newObj)) {
    let retObj: any = {};
    retObj[keyParameter] = key;
    retObj[valueParameter] = value;
    returnArray.push(retObj);
  }

  if (sort) {
    switch (sort) {
      case "ASC": returnArray.sort((a, b) => Number(a[valueParameter]) - Number(b[valueParameter])); break;
      case "DESC": returnArray.sort((a, b) => Number(b[valueParameter]) - Number(a[valueParameter])); break;
      case "DATE_ASC": returnArray.sort((a, b) => new Date(a[valueParameter]).getTime() - new Date(b[valueParameter]).getTime()); break;
      case "DATE_DESC": returnArray.sort((a, b) => new Date(b[valueParameter]).getTime() - new Date(a[valueParameter]).getTime()); break;
    }
  }

  return returnArray;

}