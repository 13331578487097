import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Footer() {

    const WrapperStyle = {
        padding: "24px",
        marginTop: "24px",
        marginLeft: "-24px",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        width: "calc(100% + 48px)",
    };

    return <Box style={WrapperStyle}>
        <Grid container>
            <Grid item xs={6} display="flex" flexDirection="row">
                <Typography variant="caption">
                    Powered by <a href="https://ohwaiter.com" target="_blank" rel="noreferrer">OhWaiter</a> ©2023 OhWaiter, Inc. All Rights Reserved.
                </Typography>
            </Grid>
            <Grid item xs={6} display="flex" flexDirection="row-reverse">
                <Typography variant="caption">
                    <a href="https://ohwaiter.com/privacy/" target="_blank" rel="noreferrer">Privacy Statement</a> &nbsp;|&nbsp; <a href="https://ohwaiter.com/terms/" target="_blank" rel="noreferrer">Terms of Service</a>
                </Typography>
            </Grid>
        </Grid>
    </Box>

};