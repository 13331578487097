import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { useGetTranscriptTagsByEOMQuery, useGetOrderTotalsByEOMQuery, useGetWordCloudByEOMQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { ReportOrderView, ReportTagCount, CountedWord } from '../Types';
import Main from '../Layouts/Main';
import LocationSelector from '../Components/LocationSelector';
import WordCloud from '../Components/WordCloud';
import { getLastNMonths } from '../Helpers/Temporal';
import { moneyFormat } from '../Helpers/Money';
import { capitalizeWords } from '../Helpers/StringUtils';

import './Reports.scss';

export default function Reports() {

    const dispatch = useDispatch();
    const customerId = useSelector((state: any) => state.userSlice.customerId);
    const [locationId, setLocationId] = useState<string>('');
    const [eom, setEom] = useState<string>('');

    // Month Selectors/Query
    const { data: tagCountsData = null, isLoading: tagCountsLoading } = useGetTranscriptTagsByEOMQuery({ locationId, eom: eom }, { skip: !!!locationId });
    const { data: orderTotalsData = null, isLoading: orderTotalsLoading } = useGetOrderTotalsByEOMQuery({ locationId, eom: eom }, { skip: !!!locationId });
    const { data: wordsData = null, isLoading: wordCloudLoading } = useGetWordCloudByEOMQuery({ customerId, locationId, eom: eom }, { skip: (!!!customerId || !!!locationId) });

    const tagCounts = useMemo(() => tagCountsData as ReportTagCount[] || [], [locationId, tagCountsData, eom]); // eslint-disable-line react-hooks/exhaustive-deps
    const orderTotalsDue = useMemo(() => orderTotalsData as ReportOrderView[] || [], [locationId, orderTotalsData, eom]); // eslint-disable-line react-hooks/exhaustive-deps
    const wordcloudWords = useMemo(() => wordsData as CountedWord[] || [], [locationId, wordsData, eom]); // eslint-disable-line react-hooks/exhaustive-deps

    // Select Current Month
    useEffect(() => setEom(getLastNMonths(1)[0].lastDayUnderscores.slice(-8)), []);

    useEffect(() => {
        dispatch(addLoader({ loader: "Tag Counts", status: tagCountsLoading }));
        dispatch(addLoader({ loader: "Order Totals", status: orderTotalsLoading }));
        dispatch(addLoader({ loader: "Word Cloud", status: wordCloudLoading }));
    }, [dispatch, tagCountsLoading, orderTotalsLoading, wordCloudLoading]);

    const handleEOMSelect = (event: SelectChangeEvent) => setEom(event.target.value);

    const filterLinkCell = (params: GridCellParams) => {
        const { tag } = params.row;
        return <React.Fragment>
            <Link to={`/transcripts?eom=${eom}&tag=${tag}`}>{tag}</Link>
        </React.Fragment>
    }

    const tagCountColumns: GridColDef[] = [
        {
            field: 'tag',
            headerName: 'Tag',
            cellClassName: 'tagNameCell',
            flex: 1,
            renderCell: filterLinkCell
        },
        {
            field: 'count',
            headerName: 'Count',
            cellClassName: 'tagCountCell',
            width: 200
        }
    ];

    const statusCell = (params: GridCellParams) => {
        let status: string = params.row.status;
        if (status.toLowerCase() === "open") status = "abandoned";
        return capitalizeWords(status);
    };

    const totalDueCell = (params: GridCellParams) => moneyFormat(params.row.totalDueInCents);
    const orderTotalDueColumns: GridColDef[] = [
        {
            field: 'status',
            headerName: 'Status',
            cellClassName: 'statusCell',
            flex: 1,
            renderCell: statusCell
        },
        {
            field: 'totalDueInCents',
            headerName: 'Total',
            cellClassName: 'totalDueCell',
            width: 200,
            renderCell: totalDueCell
        }
    ];

    return <Main header="Reports">

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector selectionHandler={setLocationId} />
                                <FormControl variant="standard" sx={{ minWidth: 100 }}>
                                    <InputLabel>Month</InputLabel>
                                    <Select label="Month" value={eom} onChange={handleEOMSelect}>
                                        {getLastNMonths(6).map(d =>
                                            <MenuItem key={d.monthDashes} value={d.lastDayUnderscores.slice(-8)}>{d.monthNiceName}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            {/* Placeholder */}
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            {/* Word Cloud */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <WordCloud
                            words={wordcloudWords}
                            width={800}
                            height={400}
                            filename={`Wordcloud_${locationId}_${eom}`}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* Transcript Tags */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <Box mb={2}>
                            <Typography variant="h6" className="bold-header">Transcript Tags</Typography>
                        </Box>

                        <DataGridPro
                            columns={tagCountColumns}
                            rows={(!locationId) ? [] : tagCounts}
                            getRowId={(row) => row.id}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: "count", sort: "desc" }],
                                },
                            }}
                            autoHeight
                            hideFooter
                        />

                    </Grid>
                </Grid>
            </Grid>

            {/* Order Totals */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <Box mb={2}>
                            <Typography variant="h6" className="bold-header">Order Totals</Typography>
                        </Box>

                        <DataGridPro
                            columns={orderTotalDueColumns}
                            rows={(!locationId) ? [] : orderTotalsDue}
                            getRowId={(row) => row.id}
                            autoHeight
                            hideFooter
                        />

                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    </Main>

};