import moment from 'moment-timezone';
import { findIana } from 'windows-iana';
import { stdDateFormat, monthsOfYear } from '../Constants';

export type DateObject = {
  monthDashes: string;
  monthUnderscores: string;
  monthNiceName: string;
  fullDashes: string;
  fullUnderscores: string;
  lastDayDashes: string;
  lastDayUnderscores: string;
  lastDaySlashes: string;
};

export const getDateTimeFromGMTISO = (
  gmtiso: string,
  timezone: string | undefined | null,
  format: string | undefined | null
) => {
  const date = moment(gmtiso);
  if (timezone) {
    const ianaZones = findIana(timezone);
    date.tz(ianaZones[0]);
  }
  const dateformat = format || stdDateFormat;
  let localized = date.format(dateformat);
  return localized;
}

export const getLastDayOfMonth = (m: number | string, y: number | string) => {
  const month = parseInt(m.toString());
  const year = parseInt(y.toString());
  var d = new Date(year, month, 0);
  return d.getDate();
}

export const getEOMFromDateString = (input: number | string) => {
  let output = "";
  const d = new Date(Date.parse(input.toString()));
  const lastDay = getLastDayOfMonth(d.getMonth() + 1, d.getFullYear());
  output = ('0' + d.getFullYear()).slice(-2);
  output += "_" + ('0' + (d.getMonth() + 1)).slice(-2)
  output += "_" + ('0' + lastDay).slice(-2);
  return output;
}

export const getLastNMonths = (n: number) => {
  const today = new Date();
  let d;
  let month;
  let year;
  let months: DateObject[] = [];
  for (var i = n - 1; i >= 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month = ('0' + (d.getMonth() + 1)).slice(-2);
    year = d.getFullYear();
    months.push({
      monthDashes: `${year}-${month}`,
      monthUnderscores: `${year}_${month}`,
      monthNiceName: monthsOfYear[parseInt(month) - 1] + ` ${year}`,
      fullDashes: `${year}-${month}-01`,
      fullUnderscores: `${year}_${month}_01`,
      lastDayDashes: `${year}-${month}-` + ('0' + getLastDayOfMonth(month, year)).slice(-2),
      lastDayUnderscores: `${year}_${month}_` + ('0' + getLastDayOfMonth(month, year)).slice(-2),
      lastDaySlashes: `${month}/` + ('0' + getLastDayOfMonth(month, year)).slice(-2) + `/${year}`,
    });
  }
  return months.reverse();
}