import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from "@mui/material/CardContent";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { styled } from "@mui/material/styles";
import { useGetTagsByZoneIdQuery } from '../../Redux/Services/OhWaiter';
import { useGeneratePDFMutation } from '../../Redux/Services/OhWaiter';
import { Tag } from '../../Types';
import ZoneSelector from '../../Components/ZoneSelector';
import { apiSuccess, apiError } from '../../Helpers/Toaster';
import { mediumModalSx, fullModalSx } from '../../Constants';

type ModalProps = {
    locationId: number;
    templateId: string;
    open: boolean;
    handleClose: (open: boolean) => void;
};

const PdfGenerator: React.FC<ModalProps> = ({ locationId, templateId, open, handleClose }) => {

    const navigate = useNavigate();
    const [zoneId, setZoneId] = useState<string>('');
    const [checkedTags, setCheckedTags] = useState<any[]>([]);

    // Redux Selectors
    const { data: tagsData = null } = useGetTagsByZoneIdQuery({ zoneId }, { skip: !!!zoneId });
    const [generatePDF] = useGeneratePDFMutation();
    const tags = tagsData as Tag[] || [];

    const handleSelectAll = (event: React.MouseEvent<HTMLButtonElement>) => {
        const allTags = tags.map(tag => tag.tagName);
        if (!!checkedTags.length) setCheckedTags([]);
        else setCheckedTags(allTags);
    };

    const handleCheckboxToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, value } = event.target;
        let updatedTags = checkedTags;
        if (checked) updatedTags.push(value);
        else updatedTags = updatedTags.filter(item => item !== value);
        updatedTags = Array.from(new Set(updatedTags)); // De-Dupe
        setCheckedTags(updatedTags);
    };

    const handleGeneratePdf = async (event: React.MouseEvent<HTMLButtonElement>) => {
        generatePDF({ locationId, templateId, tags: checkedTags })
            .then(() => apiSuccess("PDF Generation Queued!"))
            .catch((err) => apiError(err));
        handleClose(false);
        navigate(`/pdfs`, { replace: true });
    }

    const CardContentTight = styled(CardContent)(`
        padding-top: 0;
        padding-bottom: 0;
        &:last-child {
            padding-bottom: 0;
        }
    `);

    const modalStyle = (tags.length > 50) ? fullModalSx : mediumModalSx;

    return <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
            <Stack spacing={2}>

                <Typography variant="h6" component="h2" className="bold-header">Generate Sign PDF for Tags</Typography>

                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <ZoneSelector locationId={locationId.toString()} selectionHandler={setZoneId} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        {!!zoneId && !!tags.length &&
                            <Button
                                variant="outlined"
                                onClick={handleSelectAll}
                                startIcon={!checkedTags.length ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
                            >{!checkedTags.length ? "Select All" : "Select None"}</Button>
                        }
                    </Grid>
                </Grid>

                <Grid container sx={{ py: 2 }}>
                    <Grid container spacing={2}>
                        {!!zoneId && tags.map(tag => {
                            const isChecked = !!(tag.tagName && checkedTags.includes(tag.tagName));
                            return <Grid item xs={4} sm={4} md={2} key={tag.tagId}>
                                <Card><CardContentTight>
                                    <FormControlLabel
                                        label={<Typography sx={{ lineHeight: "1.2" }}>{tag.tagName}</Typography>}
                                        control={<Checkbox checked={isChecked} value={tag.tagName} onChange={handleCheckboxToggle} />}
                                    />
                                </CardContentTight></Card>
                            </Grid>
                        })}
                    </Grid>
                </Grid>

                <Grid container sx={{ mt: 3 }}>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" onClick={() => handleClose(false)}>
                                Cancel
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            <Button variant="contained" disabled={!!!checkedTags.length} onClick={handleGeneratePdf} >
                                {!!checkedTags.length ? `Generate PDF (${checkedTags.length})` : `Generate PDF`}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

            </Stack>
        </Box>
    </Modal>

};

export default PdfGenerator;