import { Middleware } from "redux";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { msalConfig, loginRequest } from '../../msalConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import { Store } from '../Store';
import { portalApi } from '../Services/OhWaiter';

const msalApp = new PublicClientApplication(msalConfig);
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const signMiddleware: Middleware<{}, any> = storeApi => {
    let connected = false;
    let connecting = false;
    return next => action => {
        const accounts = msalApp.getAllAccounts();
        if (accounts.length > 0) {
            msalApp.setActiveAccount(accounts[0]);
            if (connected === false) {
                if (connecting === false) {
                    connecting = true;
                    msalApp.acquireTokenSilent(loginRequest).then((tokenResponse) => {
                        const accessToken = tokenResponse.accessToken;
                        const url = `${API_BASE_URL}/Sign/`;
                        const connection = new HubConnectionBuilder()
                            .withUrl(url, {
                                accessTokenFactory: () => accessToken
                            })
                            .configureLogging(LogLevel.Information)
                            .withAutomaticReconnect()
                            .build();
                        connection
                            .start()
                            .then(_ => {
                                connecting = false;
                                connected = true;
                                console.log("Connected");
                            })
                            .catch(err => console.log(err));
                        connection.onreconnecting(error => {
                            console.log(error);
                            //https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-5.0#bsleep
                            //https://developer.mozilla.org/en-US/docs/Web/API/Web_Locks_API
                        });
                        connection.onreconnected(connectionId => {
                            console.log(connectionId);
                        });
                        connection.onclose(error => {
                            connected = false;
                            connecting = false;
                            //https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-5.0#reconnect-clients
                            console.log(error);
                        });
                        connection.on("pdfupdated", pdf => {
                            Store.dispatch(portalApi.util.invalidateTags(['PDFsByLocation']));
                        });
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            }
        }
        return next(action);
    };
};
