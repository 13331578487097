import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { loginRequest, logoutRequest } from '../msalConfig';
import { useMsal } from "@azure/msal-react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Centered from '../Layouts/Centered';
import HelperText from '../Components/HelperText';

export function LogIn() {

    const { instance, inProgress } = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        instance.loginRedirect(loginRequest);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (inProgress === 'none') navigate(`/`, { replace: true });
    }, [inProgress]); // eslint-disable-line react-hooks/exhaustive-deps

    return <Centered>
        <CircularProgress />
    </Centered>

};

export function LogOut() {

    const { instance } = useMsal();

    useEffect(() => {
        instance.logoutRedirect(logoutRequest);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <Centered>
        <CircularProgress />
    </Centered>

};

export function LoggedOut() {
    return <Centered>
        <Grid container spacing={3}>
            <Grid item>
                <HelperText>
                    <Typography variant="body2">You have been logged out due to inactivity or a technical issue. Click <Link to={`/login`}>here</Link> to log back in.</Typography>
                </HelperText>
            </Grid>
        </Grid>
    </Centered>
};