import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import HomeIcon from '@mui/icons-material/Home'
//import StoreIcon from '@mui/icons-material/Store';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
import CampaignIcon from '@mui/icons-material/Campaign';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import CableIcon from '@mui/icons-material/Cable';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SupportIcon from '@mui/icons-material/Support';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { useGetLocationsQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Location } from '../Types';
import Impersonation from '../Components/Impersonation';
import { staffRoles } from '../Constants';

type NavigationProps = {
    accountRole: string | null;
};

const AdminNav = () => <React.Fragment>
    <List subheader={<ListSubheader>Admin</ListSubheader>}>
        <ListItem>
            <Impersonation />
        </ListItem>
    </List>
    <Divider sx={{ my: 2 }} />
</React.Fragment>

const Navigation: React.FC<NavigationProps> = ({ accountRole }) => {

    const dispatch = useDispatch();
    const { instance } = useMsal();

    // Redux Selectors
    const customerId = useSelector((state: any) => state.userSlice.customerId);
    const { data: locationsData = null, isLoading: locationsLoading } = useGetLocationsQuery(customerId);
    const locations = locationsData as Location[] || [];

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Locations", status: locationsLoading }));
    }, [dispatch, locationsLoading]);

    const handleLogin = (instance: any) => {
        instance.loginRedirect();
    };

    const handleLogout = (instance: any) => {
        instance.logoutRedirect();
    };

    const userFound = true;
    const posList = Array.from(new Set(locations.map(obj => obj.pos))) as string[];

    return <React.Fragment>

        {/* Admin Top Nav */}
        {(accountRole && staffRoles.includes(accountRole)) && <AdminNav />}

        {/* Home */}
        {userFound && <List>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/">
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItemButton>
            </ListItem>
        </List>}

        {/* <pre>{JSON.stringify(locations)}</pre> */}

        {/* Note: For now, location select has been disabled, to make the onboarding path more linear. */}

        {/* Location Select (Future) */}
        {/* {userFound && <React.Fragment>
            <List subheader={<ListSubheader>Locations</ListSubheader>}>
                {locations && <ListItem>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Location</InputLabel>
                        <Select label="Impersonate">
                            {locations.map(location => <MenuItem key={location.id} value={location.id}>
                                {location.name}
                            </MenuItem>)}
                        </Select>
                    </FormControl>
                </ListItem>}
            </List>
            <Divider sx={{ my: 2 }} />
        </React.Fragment>} */}

        {/* Location Select (Simple) */}
        {/* {userFound && <List subheader={<ListSubheader>Locations</ListSubheader>}>
            {locations.map(location => <ListItem key={location.id} disablePadding>
                <ListItemButton component={Link} to={`/location/${location.id}`}>
                    <ListItemIcon>
                        <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary={location.name} />
                </ListItemButton>
            </ListItem>)}
        </List>} */}

        {/* Zones & QR Tags */}
        {userFound && <List subheader={<ListSubheader>Location Setup</ListSubheader>}>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/zones">
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Zones" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/tags">
                    <ListItemIcon>
                        <QrCode2Icon />
                    </ListItemIcon>
                    <ListItemText primary="Tags" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/replies">
                    <ListItemIcon>
                        <ChatBubbleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Auto Replies" />
                </ListItemButton>
            </ListItem>
            {locations.some(l => (l.mode && l.mode.toLowerCase() === 'tablet')) && <ListItem disablePadding>
                <ListItemButton component={Link} to="/buttons">
                    <ListItemIcon>
                        <ChatBubbleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reply Buttons" />
                </ListItemButton>
            </ListItem>}
        </List>}

        {/* Signage */}
        {userFound && <List subheader={<ListSubheader>Signage</ListSubheader>}>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/signs">
                    <ListItemIcon>
                        <FormatShapesIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sign Templates" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/pdfs">
                    <ListItemIcon>
                        <PictureAsPdfSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sign PDFs" />
                </ListItemButton>
            </ListItem>
        </List>}

        {/* Menu Items */}
        {userFound && <List subheader={<ListSubheader>Menu Items</ListSubheader>}>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/menu">
                    <ListItemIcon>
                        <RestaurantMenuIcon />
                    </ListItemIcon>
                    <ListItemText primary="Menu Items" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/modifiers">
                    <ListItemIcon>
                        <FormatListBulletedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Modifiers" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/validate">
                    <ListItemIcon>
                        <FactCheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="Validation" />
                </ListItemButton>
            </ListItem>
        </List>}

        {/* Marketing */}
        {userFound && <List subheader={<ListSubheader>Marketing Tools</ListSubheader>}>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/marketing">
                    <ListItemIcon>
                        <CampaignIcon />
                    </ListItemIcon>
                    <ListItemText primary="Campaigns" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/qr">
                    <ListItemIcon>
                        <QrCode2Icon />
                    </ListItemIcon>
                    <ListItemText primary="QR Code Generator" />
                </ListItemButton>
            </ListItem>
        </List>}

        {/* Reporting */}
        {userFound && <List subheader={<ListSubheader>Reporting</ListSubheader>}>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/transcripts">
                    <ListItemIcon>
                        <QuestionAnswerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Chat Transcripts" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/guests">
                    <ListItemIcon>
                        <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Guest Details" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/reports">
                    <ListItemIcon>
                        <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                </ListItemButton>
            </ListItem>
        </List>}

        {userFound && <Divider />}

        {/* Help & Settings & Login */}
        <List>
            <AuthenticatedTemplate>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to="/settings">
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                    </ListItemButton>
                </ListItem>
                {posList.includes("square") && <ListItem disablePadding>
                    <ListItemButton component={Link} to="/square/oauth">
                        <ListItemIcon>
                            <CableIcon />
                        </ListItemIcon>
                        <ListItemText primary="Square Connect" />
                    </ListItemButton>
                </ListItem>}
                <ListItem disablePadding>
                    <ListItemButton component="a" href="https://ohwaiter.scrollhelp.site" target="_blank">
                        <ListItemIcon>
                            <SupportIcon />
                        </ListItemIcon>
                        <ListItemText primary="User Guide" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => handleLogout(instance)}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Log Out" />
                    </ListItemButton>
                </ListItem>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => handleLogin(instance)}>
                        <ListItemIcon>
                            <LoginIcon />
                        </ListItemIcon>
                        <ListItemText primary="Log In" />
                    </ListItemButton>
                </ListItem>
            </UnauthenticatedTemplate>
        </List>

    </React.Fragment>

}

export default Navigation;