import React, { useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import StoreIcon from '@mui/icons-material/Store';
import { useGetLocationsQuery } from '../Redux/Services/OhWaiter';
import { useGetUserSettingsQuery, useSaveUserSettingsMutation } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { UserSettings, Location as LocationType } from '../Types';
import { autoLocation } from '../Helpers/AutoLocation';

import './LocationSelector.scss';

const LocationSelector: React.FC<{ filter?: string; allOption?: boolean; selectionHandler: (locationId: string) => void; }> = ({ filter, allOption, selectionHandler }) => {

    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [locationId, setLocationId] = useState<string>(allOption ? 'all' : '');
    const [filteredLocations, setFilteredLocations] = useState<LocationType[]>([]);

    // Redux Selectors
    const customerId = useSelector((state: any) => state.userSlice.customerId);
    const { data: locationsData = null, isLoading: locationsLoading } = useGetLocationsQuery(customerId);
    const recentLocationId = useSelector((state: any) => state.recentLocation.locationId);
    const locations = locationsData as LocationType[] || [];

    // Save to / Restore from User Settings
    const { data: userSettingsData = null } = useGetUserSettingsQuery(null);
    const userSettings = useMemo(() => userSettingsData as UserSettings || {}, [userSettingsData]);
    const [saveUserSettings] = useSaveUserSettingsMutation();

    // Loader Queue
    useEffect(() => {
        dispatch(addLoader({ loader: "Locations", status: locationsLoading }));
    }, [dispatch, locationsLoading]);

    // Auto-Select Location:
    useEffect(() => {
        const savedLocationId = (userSettings && userSettings.recentLocationId) ? userSettings.recentLocationId : null;
        if (!allOption) setLocationId(autoLocation(
            locationId,
            recentLocationId,
            savedLocationId,
            filteredLocations,
            searchParams.get("location")
        ));
    }, [filteredLocations, userSettings, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    // Affect Parent
    useEffect(() => {
        selectionHandler(locationId);
        if (!!locationId) saveUserSettings({ "recentLocationId": locationId });
    }, [locationId]);// eslint-disable-line react-hooks/exhaustive-deps

    // Filter Locations by Mode
    useEffect(() => {
        if (!!filter) setFilteredLocations(locations.filter(l => (l.mode && l.mode.toLowerCase() === filter)));
        else setFilteredLocations(locations);
    }, [locations]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleLocationSelect = (event: SelectChangeEvent) => setLocationId(event.target.value);

    return <FormControl variant="standard" sx={{ minWidth: 200 }}>
        <InputLabel>Location</InputLabel>
        <Select label="Location" value={locationId?.toString()} onChange={handleLocationSelect}>
            {allOption && <MenuItem value="all">
                <div className="listItemWithIcon">
                    <StoreIcon /><div>All Locations</div>
                </div>
            </MenuItem>}
            {filteredLocations.map(location =>
                <MenuItem key={location.id} value={location.id}>
                    <div className="listItemWithIcon">
                        {location.mode === 'tablet' ? <PhoneAndroidIcon /> : <PsychologyIcon />}
                        <div>{location.name}</div>
                    </div>
                </MenuItem>
            )}
        </Select>
    </FormControl>

}

export default LocationSelector;