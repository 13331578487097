import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Main from '../Layouts/Main';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useGetLocationsQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Location } from '../Types';
import LocationSelector from '../Components/LocationSelector';
import ZoneSelector from '../Components/ZoneSelector';
import AutoReplies from './AutoReplies';
import ReplyScheduler from './ReplyScheduler';

export default function Replies() {

    const dispatch = useDispatch();
    const [locationId, setLocationId] = useState<string>('');
    const [zoneId, setZoneId] = useState<string>('');
    let legacyReplies = false;

    // Redux Selectors
    const customerId = useSelector((state: any) => state.userSlice.customerId);
    const { data: locationsData = null, isLoading: locationsLoading } = useGetLocationsQuery(customerId);
    const locations = locationsData as Location[] || [];

    // Loader Queue
    useEffect(() => {
        dispatch(addLoader({ loader: "Locations", status: locationsLoading }));
    }, [dispatch, locationsLoading]);

    const foundLocation = locations.find(l => l.id === locationId);
    if (foundLocation && foundLocation.mode && foundLocation.mode.toLowerCase() === 'tablet') legacyReplies = true;

    return <Main header="Automatic Replies">

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector selectionHandler={setLocationId} />
                                <ZoneSelector locationId={locationId} selectionHandler={setZoneId} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        {/* Placeholder */}
                    </Grid>
                </Grid>
            </Grid>

            {legacyReplies
                ? <ReplyScheduler zoneId={zoneId} />
                : <AutoReplies zoneId={zoneId} />
            }

        </Grid>

    </Main >

};