import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridColDef, GridRowParams, GridCellParams } from '@mui/x-data-grid-pro';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import InventoryIcon from '@mui/icons-material/Inventory';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import md5 from "md5";
import Gravatar from "react-gravatar";
import { useGetLocationByIdQuery } from '../Redux/Services/OhWaiter';
import { useGetChatTranscriptsByLocationIdQuery } from '../Redux/Services/OhWaiter';
import { useGetTranscriptTagsByLocationQuery } from '../Redux/Services/OhWaiter';
import { useGetOrderTotalsByEOMQuery } from '../Redux/Services/OhWaiter';
import { useGetUserSettingsQuery, useSaveUserSettingsMutation } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Location as LocationType } from '../Types';
import { ChatHeader, ChatOrder } from '../Types';
import { TranscriptsResponse } from '../Types';
import { TranscriptRequest, TranscriptResponse } from '../Types';
import { UserSettings, TranscriptsViewState } from '../Types';
import { ReportOrderView, ReportTagCount } from '../Types';
import Main from '../Layouts/Main';
import LocationSelector from '../Components/LocationSelector';
import ChatTagsEdit from '../Components/Modals/ChatTagsEdit';
import GuestView from '../Components/Modals/GuestView';
import Spinner from '../Components/Spinner';
import { getDateTimeFromGMTISO, getLastNMonths } from '../Helpers/Temporal';
import { capitalizeWords } from '../Helpers/StringUtils';
import { moneyFormat } from '../Helpers/Money';
import { stdDateFormat, dataGridSx } from '../Constants';

import './Transcripts.scss';

const Summary: React.FC<{ locationId: string, eom: string }> = ({ locationId, eom }) => {

    const { data: orderTotalsData = null } = useGetOrderTotalsByEOMQuery({ locationId, eom: eom }, { skip: !!!locationId });
    const orderTotalsDue = useMemo(() => orderTotalsData as ReportOrderView[] || [], [orderTotalsData]);

    const paidOrders = orderTotalsDue.find(obj => obj.status.toLowerCase() === 'paid');
    const openOrders = orderTotalsDue.find(obj => obj.status.toLowerCase() === 'open');

    return (!!paidOrders || !!openOrders)
        ? <Stack direction="row" spacing={1} className="transcriptsSummary">
            <Button variant="outlined" size="small" color="inherit" className="noborder">Monthly Totals:</Button>
            {paidOrders && <Button variant="outlined" size="small" color="success">{paidOrders.orderCount} Paid Orders ({moneyFormat(paidOrders.totalDueInCents)})</Button>}
            {openOrders && <Button variant="outlined" size="small" color="error">{openOrders.orderCount} Abandoned ({moneyFormat(openOrders.totalDueInCents)})</Button>}
        </Stack>
        : <React.Fragment />

}

const TagSelector: React.FC<{
    tagsData: ReportTagCount[],
    selectorLabel: string,
    preSelected?: string[] | null,
    selectHandler: (tagsList: string[]) => void
}> = ({ tagsData, selectorLabel, preSelected, selectHandler }) => {

    const [selectedTags, setSelectedTags] = useState<string[]>(preSelected || []);
    useEffect(() => selectHandler(selectedTags), []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => selectHandler(selectedTags), [preSelected, selectedTags]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event: any) => {
        const { target: { value } } = event;
        setSelectedTags(value.sort().filter(function (el: any, i: number, a: string) { return i === a.indexOf(el) }));
    };

    return <FormControl sx={{ m: 0, width: 200 }} className={`tagCount_${tagsData.length}`}>
        <InputLabel>{selectorLabel}</InputLabel>
        <Select
            input={<OutlinedInput label="Include Tags" />}
            value={selectedTags}
            renderValue={(selected: string[]) => selected.join(', ')}
            onChange={handleChange}
            multiple
        >{tagsData.map(obj => {
            const selectionDisabled = (!selectedTags.includes(obj.tag) && !(selectedTags.length < 3));
            return <MenuItem key={obj.id} value={obj.tag} className={`tagSelectorItem`} disabled={selectionDisabled}>
                <Checkbox checked={selectedTags.includes(obj.tag)} />
                <ListItemText primary={`${obj.tag} (${obj.count})`} />
            </MenuItem>
        })}
        </Select>
    </FormControl>

}

const tagFilterHasChanged = (queryParam: string | null, filterArray: string[]) => {
    return !(filterArray.length === 1 && (filterArray[0] === queryParam));
}

export default function Transcripts() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [locationId, setLocationId] = useState<string>('');
    const [page, setPage] = useState(0);
    const [eom, setEom] = useState<string>(searchParams.get("eom") ? searchParams.get("eom") as string : '');
    const [transcripts, setTranscripts] = useState<ChatHeader[]>([]);
    const [guestFilter, setGuestFilter] = useState<string | null>(null);
    const [tagsIncludeFilter, setTagsIncludeFilter] = useState<string[]>([]);
    const [tagsExcludeFilter, setTagsExcludeFilter] = useState<string[]>([]);
    const [viewState, setViewState] = useState<TranscriptsViewState | null>(null);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [tagEditorOpen, setTagEditorOpen] = useState<boolean>(false);
    const [conversationToEdit, setConversationToEdit] = useState<TranscriptRequest | null>(null);
    const [tagsToEdit, setTagsToEdit] = useState<string[]>([]);
    const itemsPerPage = 40;

    // Slide-Over Guest Detail
    const [guestViewId, setGuestViewId] = useState<string | null>(null);
    const closeGuestView = () => setGuestViewId(null);

    // Redux Selectors
    const { data: locationData = null, isLoading: locationLoading } = useGetLocationByIdQuery({ locationId }, { skip: !!!locationId });
    const { data: transcriptsData = null, isLoading: transcriptsLoading } = useGetChatTranscriptsByLocationIdQuery({ ...viewState }, { skip: !viewState });
    const { data: tagsData = null, isLoading: tagsDataLoading } = useGetTranscriptTagsByLocationQuery({ locationId }, { skip: !!!locationId });
    const { data: userSettingsData = null } = useGetUserSettingsQuery(null);
    const [saveUserSettings] = useSaveUserSettingsMutation();
    const userSettings = useMemo(() => userSettingsData as UserSettings || {}, [userSettingsData]);
    const location = locationData as LocationType || {};
    const tagsList = tagsData as ReportTagCount[] || [];

    // Update View State
    useEffect(() => {
        if (!!locationId) setViewState({
            locationId,
            eom,
            guestFilter,
            tagsInclude: tagsIncludeFilter,
            tagsExclude: tagsExcludeFilter,
            offset: itemsPerPage * page,
            limit: itemsPerPage,
            page
        });
    }, [locationId, eom, guestFilter, tagsIncludeFilter, tagsExcludeFilter, itemsPerPage, page]);

    // Store View State
    useEffect(() => {
        if (viewState && JSON.parse(JSON.stringify(viewState))) {
            saveUserSettings({ "transcriptsViewState": JSON.stringify(viewState) });
        }
    }, [viewState]); // eslint-disable-line react-hooks/exhaustive-deps

    // Restore View State
    useEffect(() => {
        if (!!locationId && !!userSettings["transcriptsViewState"]) {
            const parsedState = JSON.parse(userSettings["transcriptsViewState"]);
            if (parsedState.locationId && locationId === parsedState.locationId) {
                if (searchParams.get("eom")) parsedState.eom = searchParams.get("eom") as string;
                if (searchParams.get("tag")) parsedState.tagsInclude = [searchParams.get("tag") as string];
                if (parsedState.page) setPage(parsedState.page);
                setViewState(parsedState);
            }
        }
    }, [locationId, userSettings]); // eslint-disable-line react-hooks/exhaustive-deps

    // Select Current Month
    useEffect(() => {
        if (!!!searchParams.get("eom")) setEom(getLastNMonths(1)[0].lastDayUnderscores.slice(-8));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Load Items
    useEffect(() => {
        if (transcriptsData) {
            const response = transcriptsData as TranscriptsResponse || null;
            if (response) setTranscripts(response.items || []);
            if (response) setTotalRows(response.totalItemCount || 0);
        }
    }, [transcriptsData]);

    // Reset Page to 0 if Out of Bounds
    useEffect(() => {
        if (transcripts && transcripts.length <= 0 && page > 0) setPage(0);
    }, [transcripts]); // eslint-disable-line react-hooks/exhaustive-deps

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Location", status: locationLoading }));
        dispatch(addLoader({ loader: "Transcripts", status: transcriptsLoading }));
        dispatch(addLoader({ loader: "Tags", status: tagsDataLoading }));
    }, [dispatch, locationLoading, transcriptsLoading, tagsDataLoading]);

    // Guest Filter
    useEffect(() => { if (searchParams.get("guestId")) setGuestFilter(searchParams.get("guestId")); }, [searchParams]);

    // Ensure Guest Filter is Applied (Bug Fix)
    useEffect(() => {
        if (viewState && guestFilter !== viewState.guestFilter) setViewState({ ...viewState, guestFilter });
    }, [guestFilter, viewState]);

    const handleEOMSelect = (event: SelectChangeEvent) => {
        setEom(event.target.value);
        if (!!event.target.value && searchParams.get("eom") !== event.target.value) {
            searchParams.delete("eom");
            setSearchParams(searchParams);
        }
    }

    const updateIncludeTags = (tagsList: string[]) => {
        setTagsIncludeFilter(tagsList);
        if (tagFilterHasChanged(searchParams.get("tag"), tagsList)) {
            searchParams.delete("tag");
            setSearchParams(searchParams);
        }
    }

    const handleChangePage = (page: number) => setPage(page);

    const updateExcludeTags = (tagsList: string[]) => setTagsExcludeFilter(tagsList);

    const handleRowClick = (params: GridRowParams) => {
        const { guestHash, conversationId } = params.row;
        navigate(`/transcripts/${locationId}/${guestHash}/${conversationId}`);
    }

    const handleGuestDetails = (e: any, row: TranscriptResponse) => {
        e.stopPropagation();
        setGuestViewId(row.guestHash as string);
    }

    const handleGuestFilter = (e: any, row: TranscriptResponse) => {
        e.stopPropagation();
        navigate(`/transcripts?guestId=${row.guestHash}`);
    }

    const openTagEditor = (e: any, row: TranscriptResponse) => {
        e.stopPropagation();
        setConversationToEdit({
            locationId,
            conversationId: row.conversationId,
            guestHash: row.guestHash
        });
        if (row.tags) setTagsToEdit(row.tags);
        setTagEditorOpen(true);
    }

    const closeTagEditor = () => {
        setTagEditorOpen(false);
        setTagsToEdit([]);
    }

    const avatarCell = (params: GridCellParams) => {
        const { guestHash } = params.row;
        return <React.Fragment>
            <Tooltip title={`Guest ID ${guestHash}`} placement="top" arrow className="avatarWrapper">
                <span><Gravatar
                    size={30}
                    style={{ margin: "0 10px 0 6px", borderRadius: "50%" }}
                    md5={md5(guestHash)}
                    onClick={(e) => handleGuestDetails(e, params.row)}
                /></span>
            </Tooltip>
            <Tooltip title="Filter by Guest" placement="top" arrow>
                <FilterAltIcon
                    onClick={(e) => handleGuestFilter(e, params.row)}
                />
            </Tooltip>
        </React.Fragment>
    };

    const dateCell = (params: GridCellParams) => {
        const { lastUpdateUtc, messageCount } = params.row;
        const localFormatted = getDateTimeFromGMTISO(lastUpdateUtc, location.timeZone, stdDateFormat);
        return <React.Fragment>
            {localFormatted}{` (${messageCount})`}
        </React.Fragment>
    }

    const statusCell = (params: GridCellParams) => {
        const specialTags = ["reviewed", "flagged"];
        const { orders, tags } = params.row;
        const filteredTags = tags ? tags.filter((tag: string) => !specialTags.includes(tag)) : [];
        const orderStatus = {
            totalTransactions: 0,
            totalClosed: 0,
            iconColor: "rgba(0, 0, 0, 0.26)",
            toolTip: "No Orders Open"
        };
        const reviewStatus = {
            reviewed: false,
            iconColor: "rgba(0, 0, 0, 0.26)",
            toolTip: "Not Reviewed"
        }
        if (orders && orders.length) {
            const ordersArr = orders as ChatOrder[];
            orderStatus.iconColor = "#ffbf00";
            orderStatus.totalTransactions = ordersArr.length;
            orderStatus.totalClosed = ordersArr.filter(order => {
                return order.orderStatus && order.orderStatus.toLowerCase() === "paid";
            }).length;
            const { totalTransactions, totalClosed } = orderStatus;
            orderStatus.toolTip = `${totalClosed}/${totalTransactions} Orders Closed`;
            if (totalClosed >= 1) orderStatus.iconColor = "#2e7d32";
        }
        if (tags && tags.includes("reviewed")) {
            reviewStatus.reviewed = true;
            reviewStatus.iconColor = "#2e7d32";
            reviewStatus.toolTip = "Reviewed";
        }
        if (tags && tags.includes("flagged")) {
            reviewStatus.iconColor = "#d32f2f";
            reviewStatus.toolTip = "Flagged";
        }
        const tagCount = (filteredTags && !!filteredTags.length) ? filteredTags.length : 0;
        const tagTip = (tagCount > 0) ? capitalizeWords(tags.join(", ")) : "No Buckets";
        return <Grid container justifyContent="flex-end" mx={1}>
            <Stack direction="row" alignItems="center" spacing={3}>
                <Tooltip title={orderStatus.toolTip} placement="top" arrow>
                    <PointOfSaleIcon fontSize="small" sx={{ color: orderStatus.iconColor }} />
                </Tooltip>
                <Tooltip title={tagTip} placement="top" arrow>
                    <InventoryIcon
                        fontSize="small"
                        onClick={(e) => openTagEditor(e, params.row)}
                        sx={{ color: (tagCount > 0) ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.26)" }}
                    />
                </Tooltip>
                <Tooltip title={reviewStatus.toolTip} placement="top" arrow>
                    <FactCheckIcon fontSize="small" sx={{ color: reviewStatus.iconColor }} />
                </Tooltip>
            </Stack>
        </Grid>
    };

    const transcriptColumns: GridColDef[] = [
        {
            field: 'guestHash',
            headerName: 'Guest',
            width: 90,
            renderCell: avatarCell
        },
        {
            field: 'lastUpdateUtc',
            headerName: 'Last Message',
            width: 170,
            renderCell: dateCell
        },
        {
            field: 'summary',
            headerName: 'Summary',
            flex: 1
        },
        {
            field: 'status',
            headerName: '',
            width: 140,
            renderCell: statusCell
        }
    ];

    return <Main header="Chat Transcripts">

        <GuestView
            guestId={guestViewId}
            open={!!guestViewId}
            handleClose={closeGuestView}
        />

        {(locationId && conversationToEdit) && <ChatTagsEdit
            transcript={conversationToEdit}
            currentTags={tagsToEdit}
            open={tagEditorOpen}
            handleClose={closeTagEditor}
        />}

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={3} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector selectionHandler={setLocationId} />
                                <FormControl variant="standard" sx={{ minWidth: 100 }}>
                                    <InputLabel>Month</InputLabel>
                                    <Select label="Month" value={eom} onChange={handleEOMSelect}>
                                        {getLastNMonths(6).map(d =>
                                            <MenuItem key={d.monthDashes} value={d.lastDayUnderscores.slice(-8)}>{d.monthNiceName}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={9} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            {!!guestFilter &&
                                <Button variant="outlined" size="small" color="error" onClick={() => setGuestFilter(null)}>
                                    <CloseIcon fontSize="small" color="error" />
                                    <Gravatar
                                        size={30}
                                        style={{ margin: "0 10px 0 6px", borderRadius: "50%" }}
                                        md5={md5(guestFilter)}
                                    />
                                    <span>{guestFilter}</span>
                                </Button>
                            }

                            <TagSelector
                                tagsData={tagsList}
                                selectorLabel="Include Tags"
                                selectHandler={updateIncludeTags}
                                preSelected={searchParams.get("tag") ? [searchParams.get("tag") as string] : null}
                            />

                            <TagSelector
                                tagsData={tagsList}
                                selectorLabel="Exclude Tags"
                                selectHandler={updateExcludeTags}
                            />

                        </Stack>
                    </Grid>
                    <Grid item xs={12} display="flex" flexDirection="row" mt={2} alignItems="center" justifyContent="center">
                        <Stack direction="row" spacing={1}>
                            <Summary locationId={locationId} eom={eom} />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        {transcriptsLoading
                            ? <Spinner />
                            : <DataGridPro
                                columns={transcriptColumns}
                                rows={(!locationId) ? [] : transcripts}
                                getRowId={(row) => row.conversationId}
                                onRowClick={handleRowClick}
                                disableSelectionOnClick
                                sx={dataGridSx}
                                pagination
                                paginationMode="server"
                                rowCount={totalRows}
                                page={page}
                                pageSize={itemsPerPage}
                                rowsPerPageOptions={[itemsPerPage]}
                                onPageChange={handleChangePage}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: "lastUpdateUtc", sort: "desc" }],
                                    },
                                }}
                                autoHeight
                            />
                        }

                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    </Main >

};