import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Main from '../Layouts/Main';
import html2canvas from 'html2canvas';
import QRCode from 'react-qr-code';

import './QRGenerator.scss';

export default function QRCodeGenerator() {

    const [codeValue, setCodeValue] = useState<string>('');
    const downloadRef = React.useRef<HTMLDivElement>(null);

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => setCodeValue(e.target.value);

    const downloadImage = async () => {
        const element = downloadRef.current as HTMLDivElement;
        const canvas = await html2canvas(element);
        const imageData = canvas.toDataURL("image/jpg");
        const linkElement = document.createElement("a");
        if (typeof linkElement.download === "string") {
            linkElement.href = imageData;
            linkElement.download = Date.now() + '.jpg';
            document.body.appendChild(linkElement);
            linkElement.click();
            document.body.removeChild(linkElement);
        } else {
            window.open(imageData);
        }
    };

    return <Main header="QR Code Generator">

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                {/* Placeholder */}
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        {/* Placeholder */}
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>

                            <TextField
                                label="URL"
                                variant="outlined"
                                fullWidth
                                multiline
                                value={codeValue}
                                onChange={handleValueChange}
                            />

                            <div className="qr-center">
                                <div className="qr-code-wrapper">
                                    <div className="qr-code">
                                        <div className="save-image" ref={downloadRef}>
                                            <QRCode
                                                size={256}
                                                viewBox={`0 0 256 256`}
                                                value={!!codeValue ? codeValue : "https://ohwaiter.com"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Button
                                variant="contained"
                                fullWidth
                                startIcon={<FileDownloadIcon />}
                                onClick={downloadImage}
                            >Save Image</Button>

                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    </Main >

};