import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircleIcon from '@mui/icons-material/Circle';
import { useGetButtonsByZoneIdQuery } from '../../Redux/Services/OhWaiter';
import { useSaveButtonMutation, useDeleteButtonsMutation } from '../../Redux/Services/OhWaiter';
import { Button as ButtonType } from '../../Types';
import HelperText from '../../Components/HelperText';
import { apiSuccess, apiError } from '../../Helpers/Toaster';
import { buttonColors, editSlideSx } from '../../Constants';

type ModalProps = {
    zoneId: number;
    buttonId?: number | null;
    nextOrdinal?: number;
    open: boolean;
    handleClose: (open: boolean) => void;
};

const ButtonEdit: React.FC<ModalProps> = ({ zoneId, buttonId, nextOrdinal, open, handleClose }) => {

    // Component State
    const [buttonLabel, setButtonLabel] = useState('');
    const [buttonColor, setButtonColor] = useState('');
    const [replyText, setReplyText] = useState('');
    const [buttonOrdinal, setButtonOrdinal] = useState(nextOrdinal);

    // Redux Selectors
    const { data: buttonsData = null } = useGetButtonsByZoneIdQuery({zoneId}, { skip: !!!zoneId });
    const [saveButton] = useSaveButtonMutation();
    const [deleteButtons] = useDeleteButtonsMutation();
    const buttons = useMemo(() => buttonsData as ButtonType[] || [], [zoneId, buttonsData]); // eslint-disable-line react-hooks/exhaustive-deps

    // Populate existing fields to edit:
    useEffect(() => {
        if (!!buttonId) {
            const button = buttons.find(r => r.buttonId === buttonId.toString());
            if (button) {
                clearForm();
                if (button.title) setButtonLabel(button.title);
                if (button.response) setReplyText(button.response);
                if (button.color) setButtonColor(button.color);
                if (button.ordinal) setButtonOrdinal(button.ordinal);
            } else clearForm();
        } else clearForm();
    }, [buttonId, buttons]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeLabel = (e: React.ChangeEvent<HTMLInputElement>) => setButtonLabel(e.target.value);
    const changeReply = (e: React.ChangeEvent<HTMLInputElement>) => setReplyText(e.target.value);
    const changeColor = (e: SelectChangeEvent) => setButtonColor(e.target.value as string);

    const clearForm = () => {
        setButtonLabel('');
        setReplyText('');
        setButtonColor('');
        setButtonOrdinal(nextOrdinal);
    };

    const handleSave = async () => {
        const payload: ButtonType = {
            title: buttonLabel,
            response: replyText,
            color: buttonColor,
            ordinal: buttonOrdinal
        };
        if (!!buttonId) payload.buttonId = buttonId.toString();
        await saveButton({ zoneId, button: payload })
            .then(() => apiSuccess("Button " + ((!!buttonId) ? "Updated!" : "Added!")))
            .catch((err) => apiError(err));
        clearForm();
        handleClose(false);
    };

    const handleDelete = async () => {
        await deleteButtons({ zoneId, buttonIds: [buttonId?.toString()] })
            .then(() => apiSuccess("Button Deleted!"))
            .catch((err) => apiError(err));
        clearForm();
        handleClose(false);
    };

    return <Drawer anchor="right" open={open} onClose={handleClose}>
        <Box sx={editSlideSx}>
            <Stack spacing={4}>

                <Typography variant="h6" component="h2" className="bold-header">
                    {(!!buttonId) ? 'Edit' : 'New'} Button
                </Typography>

                <HelperText>
                    <Typography variant="body2">Here you can create a new <strong>Quick Reply Button</strong> for a given zone, which will then be avaiable on your OhWaiter tablet. Your staff can tap these buttons to quickly send common replies to your guests, whether it is a link to a beer or wine list, or a simple <em>"I'll be right there to help you with that!"</em></Typography>
                </HelperText>

                <Box component="form" sx={{ my: 3 }} noValidate autoComplete="off">
                    <Stack spacing={2}>

                        <FormControl>
                            <TextField
                                label="Button Label"
                                variant="outlined"
                                fullWidth
                                value={buttonLabel}
                                onChange={changeLabel}
                            />
                        </FormControl>

                        <FormControl>
                            <InputLabel>Button Color</InputLabel>
                            <Select label="Button Color" value={buttonColor} onChange={changeColor}>
                                {buttonColors.map(({ name, hex }, index) => <MenuItem key={index} value={hex}>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <CircleIcon sx={{ color: hex }} />
                                        <Typography>{name}</Typography>
                                    </Stack>
                                </MenuItem>)}
                            </Select>
                        </FormControl>

                        <FormControl>
                            <TextField
                                label="Message"
                                variant="outlined"
                                fullWidth
                                multiline
                                value={replyText}
                                onChange={changeReply}
                            />
                        </FormControl>

                    </Stack>
                </Box>

                <Stack spacing={2}>
                    <Button variant="contained" onClick={handleSave}>
                        Save
                    </Button>
                    {buttonId && <Button variant="contained" color="error" onClick={handleDelete}>
                        Delete
                    </Button>}
                </Stack>

            </Stack>
        </Box>
    </Drawer>

};

export default ButtonEdit;