import React, { useState, useEffect, useRef, useMemo } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import FileUpload from 'react-material-file-upload';
import { makeMoveable, Draggable, Scalable } from "react-moveable";
import { DraggableProps, ScalableProps } from "react-moveable";
import { OnDrag as DragEvent } from "react-moveable";
import MoveableHelper from "moveable-helper";
import { useGetSignsByLocationIdQuery } from '../../Redux/Services/OhWaiter';
import { useReplaceSignImageMutation } from '../../Redux/Services/OhWaiter';
import { useSaveSignTemplateMutation, useDeleteSignTemplateMutation } from '../../Redux/Services/OhWaiter';
import { Sign as SignType } from '../../Types';
import HelperText from '../../Components/HelperText';
import { linearMap } from '../../Helpers/NumberMapping';
import { apiSuccess, apiError } from '../../Helpers/Toaster';
import { editSlideSx, fullModalSx } from '../../Constants';

import './SignEdit.scss';

type ModalProps = {
    locationId: number;
    templateId?: string | null;
    open: boolean;
    handleClose: (open: boolean) => void;
    handleReload: (id: string) => void;
};

const SignEdit: React.FC<ModalProps> = ({ locationId, templateId, open, handleClose, handleReload }) => {

    // Component State
    const [positionerOpen, setPositionerOpen] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [templateType, setTemplateType] = useState('');
    const [bgImgUpload, setBgImgUpload] = useState<File[]>([]);
    const [bgImgUrl, setBgImgUrl] = useState('');
    const [replaceImage, setReplaceImage] = useState(false);
    const [signWidth, setSignWidth] = useState('');
    const [signHeight, setSignHeight] = useState('');
    const [qrSize, setQrSize] = useState('');
    const [qrXPos, setQrXPos] = useState('');
    const [qrYPos, setQrYPos] = useState('');
    const [moveStarted, setMoveStarted] = useState(false);
    const [moveableHelper] = useState(() => new MoveableHelper());
    const [imgLoadedDimensions, setImgLoadedDimensions] = useState([0, 0]);
    const [imgLoadedPos, setImgLoadedPos] = useState([0, 0]);

    // Redux Selectors
    const { data: signsData = null } = useGetSignsByLocationIdQuery({ locationId }, { skip: !!!locationId });
    const [replaceSignImage] = useReplaceSignImageMutation();
    const [saveSignTemplate] = useSaveSignTemplateMutation();
    const [deleteSignTemplate] = useDeleteSignTemplateMutation();
    const signs = useMemo(() => signsData as SignType[] || [], [locationId, signsData]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeName = (e: React.ChangeEvent<HTMLInputElement>) => setTemplateName(e.target.value);
    const changeType = (e: SelectChangeEvent) => setTemplateType(e.target.value as string);
    const changeWidth = (e: React.ChangeEvent<HTMLInputElement>) => setSignWidth(e.target.value);
    const changeHeight = (e: React.ChangeEvent<HTMLInputElement>) => setSignHeight(e.target.value);
    const changeQrSize = (e: React.ChangeEvent<HTMLInputElement>) => setQrSize(e.target.value);
    const changeQrXPos = (e: React.ChangeEvent<HTMLInputElement>) => setQrXPos(e.target.value);
    const changeQrYPos = (e: React.ChangeEvent<HTMLInputElement>) => setQrYPos(e.target.value);

    const openPositioner = () => {
        setMoveStarted(false);
        setPositionerOpen(true);
    }
    const closePositioner = () => {
        if (moveStarted) {
            // Translate QR size/x/y pixels to inches:
            const qrSizeInches = linearMap({ in: dragSize, x1: 0, y1: imgLoadedDimensions[0], x2: 0, y2: parseFloat(signWidth) });
            const qrLeftInches = linearMap({ in: dragPos[0], x1: 0, y1: imgLoadedDimensions[0], x2: 0, y2: parseFloat(signWidth) });
            const qrTopInches = linearMap({ in: dragPos[1], x1: 0, y1: imgLoadedDimensions[1], x2: 0, y2: parseFloat(signHeight) });
            if (!isNaN(qrLeftInches)) setQrXPos(Number(qrLeftInches).toFixed(2));
            if (!isNaN(qrTopInches)) setQrYPos(Number(qrTopInches).toFixed(2));
            if (isFinite(qrSizeInches)) setQrSize(Number(qrSizeInches).toFixed(2));
        }
        setPositionerOpen(false);
    }

    const Moveable = makeMoveable<DraggableProps & ScalableProps>([Draggable, Scalable]);
    const moveableElement = useRef<HTMLDivElement>(null);
    const [dragSize, setDragSize] = React.useState<number>(100);
    const [dragPos, setDragPos] = React.useState<number[]>([0, 0]);

    // Populate existing fields to edit:
    useEffect(() => {
        if (!!templateId) {
            const template = signs.find(r => r.templateId === templateId);
            if (template) {
                if (template.name) setTemplateName(template.name);
                if (template.templateType) setTemplateType(template.templateType);
                if (template.printWidth) setSignWidth(template.printWidth);
                if (template.printHeight) setSignHeight(template.printHeight);
                if (template.qrWidthHeight) setQrSize(template.qrWidthHeight);
                if (template.qrLeft) setQrXPos(template.qrLeft);
                if (template.qrTop) setQrYPos(template.qrTop);
                if (template.backgroundImageUrl) setBgImgUrl(template.backgroundImageUrl);
            }
        } else clearForm();
    }, [templateId, signs]); // eslint-disable-line react-hooks/exhaustive-deps

    // Live updating of backdrop upload:
    useEffect(() => {
        if (!!templateId && bgImgUpload.length > 0) handleImageReplace();
    }, [bgImgUpload]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ensure bgImgUrl is populated:
    useEffect(() => {
        const template = signs.find(r => r.templateId === templateId);
        if (template && !!template.backgroundImageUrl && !!!bgImgUrl) setBgImgUrl(template.backgroundImageUrl);
    }, [templateId, signs]); // eslint-disable-line react-hooks/exhaustive-deps

    const clearForm = () => {
        setTemplateName('');
        setTemplateType('');
        setSignWidth('');
        setSignHeight('');
        setQrSize('');
        setQrXPos('');
        setQrYPos('');
        setReplaceImage(false);
        setBgImgUrl('');
        setBgImgUpload([]);
    };

    const closePanel = () => {
        clearForm();
        handleClose(false);
    }

    const handleImageReplace = async () => {
        const formData = new FormData();
        formData.append("file", bgImgUpload[0]);
        await replaceSignImage({
            locationId,
            templateId,
            templateName,
            formData
        });
        setReplaceImage(false);
        setBgImgUpload([]);
    }

    const saveSign = async (reload: boolean = false) => {
        const sign: SignType = {
            name: templateName,
            templateType: templateType,
            printWidth: signWidth,
            printHeight: signHeight,
            qrWidthHeight: qrSize,
            qrTop: qrYPos,
            qrLeft: qrXPos
        };
        if (!!templateId) sign.id = templateId;
        if (!!templateId) sign.templateId = templateId;
        try {
            const savedTemplate = await saveSignTemplate({ locationId, sign }).unwrap() as SignType;
            apiSuccess("Sign Template " + (!!templateId) ? "Updated!" : "Added!");
            if (reload && savedTemplate.templateId) handleReload(savedTemplate.templateId);
            if (!reload) closePanel();
        } catch (err) {
            apiError(err);
        }
    };

    const saveAndKeep = async () => saveSign(true);
    const saveAndClose = async () => saveSign(false);

    const handleDelete = async () => {
        deleteSignTemplate({ locationId, templateId })
            .then(() => apiSuccess("Template Deleted!"))
            .catch((err) => apiError(err));
        closePanel();
    };

    const getDraggerSize = () => {
        const size = linearMap({ in: parseFloat(qrSize), x1: 0, y1: parseFloat(signWidth), x2: 0, y2: imgLoadedDimensions[0] });
        return isNaN(size) ? 100 : size;
    }
    const getDraggerPos = () => {
        const xPixels = linearMap({ in: parseFloat(qrXPos), x1: 0, y1: parseFloat(signWidth), x2: 0, y2: imgLoadedDimensions[0] });
        const yPixels = linearMap({ in: parseFloat(qrYPos), x1: 0, y1: parseFloat(signHeight), x2: 0, y2: imgLoadedDimensions[1] });
        const offset = 66; // 50px margin + 16px padding
        return [
            isNaN(yPixels) ? 20 : yPixels,
            isNaN(xPixels) ? (imgLoadedPos[0] + 20 - offset) : (imgLoadedPos[0] + xPixels - offset)
        ];
    }

    const draggerStartPos = {
        top: getDraggerPos()[0] + "px",
        left: getDraggerPos()[1] + "px",
        width: getDraggerSize() + "px",
        height: getDraggerSize() + "px",
        lineHeight: getDraggerSize() + "px"
    };

    const draggerBounds = {
        top: imgLoadedPos[1],
        right: imgLoadedPos[0] + imgLoadedDimensions[0],
        bottom: imgLoadedPos[1] + imgLoadedDimensions[1],
        left: imgLoadedPos[0]
    };

    const dragPolice = (e: DragEvent) => {
        let xDirection: null | string = null;
        let yDirection: null | string = null;
        if (e.dist[0] > 0) xDirection = "right";
        if (e.dist[0] < 0) xDirection = "left";
        if (e.dist[1] < 0) yDirection = "up";
        if (e.dist[1] > 0) yDirection = "down";
        const { top, right, bottom, left } = e.target.getBoundingClientRect();
        if ((top <= draggerBounds.top && yDirection === "up") ||
            (right >= draggerBounds.right && xDirection === "right") ||
            (bottom >= draggerBounds.bottom && yDirection === "down") ||
            (left <= draggerBounds.left && xDirection === "left")) {
            e.stopDrag(); setTimeout(() => readDimensions(e), 300);
        } else moveableHelper.onDrag(e);
    }

    const readDimensions = ({ target }: any) => {
        if (!moveStarted) setMoveStarted(true);
        const rect = target.getBoundingClientRect();
        setDragSize(rect.width);
        setDragPos([rect.left - imgLoadedPos[0], rect.top - imgLoadedPos[1]]);
    }

    const handleBgLoad = ({ target }: any) => {
        const rect = target.getBoundingClientRect();
        setImgLoadedPos([rect.left, rect.top]);
        setImgLoadedDimensions([rect.width, rect.height]);
    }

    // Little bit of extra dummy-proofing:
    const starterText = (!!templateName)
        ? <span>First, you'll need to give your sign template a name.</span>
        : <strong>First, you'll need to give your sign template a name.</strong>;

    const templateIsValid = !(!!!templateName || !!!templateType);
    const qrPositionReady = !(!!!templateName || !!!signWidth || !!!signHeight || !!!templateType || !!!bgImgUrl);

    return <React.Fragment>

        {/* Positioner Modal Thing */}
        <Modal open={positionerOpen} onClose={closePositioner}>
            <Box sx={fullModalSx} className="sign-positioner-modal">
                <div className="dragger-wrapper">
                    <div className="dragger-parent">
                        <div className="dragger" ref={moveableElement} style={draggerStartPos}>
                            <QrCode2Icon sx={{ fontSize: getDraggerSize() }} />
                        </div>
                    </div>
                    <Moveable
                        target={moveableElement}
                        draggable={true}
                        scalable={true}
                        keepRatio={true}
                        renderDirections={["nw", "ne", "se", "sw"]}
                        onDragStart={moveableHelper.onDragStart}
                        onDrag={dragPolice}
                        onDragEnd={readDimensions}
                        onScaleStart={moveableHelper.onScaleStart}
                        onScale={moveableHelper.onScale}
                        onScaleEnd={readDimensions}
                    />
                </div>
                <div className="backdrop-wrapper">
                    <div className="backdrop">
                        <img src={bgImgUrl} onLoad={handleBgLoad} alt="Sign Artwork" />
                    </div>
                </div>
                <div className="done-button">
                    <Box p={2}>
                        <Button variant="contained" startIcon={<ThumbUpAltIcon />} onClick={closePositioner}>
                            Done
                        </Button>
                    </Box>
                </div>
            </Box>
        </Modal>

        {/* Sign Template Edit Panel */}
        <Drawer anchor="right" open={open} onClose={handleClose}>
            <Box sx={editSlideSx}>
                <Stack spacing={4}>

                    <Typography variant="h6" component="h2" className="bold-header">
                        {!!templateId ? 'Edit' : 'New'} Sign Template
                    </Typography>

                    <HelperText>
                        <Typography variant="body2">Here you can design your OhWaiter signage. {starterText} Then you can upload your sign artwork, and position the dynamic QR code (<strong>Tag</strong>) either manually by specifying its size and position in inches, or by using the interactive <strong>Position QR Code</strong> interface. Once the QR code is in place, you can click the "PDF" icon button and our system will start generating signs for the tags that you specify. These can be downloaded in the <strong>Sign PDFs</strong> section.</Typography>
                    </HelperText>

                    <Box component="form" sx={{ my: 3 }} noValidate autoComplete="off">
                        <Stack spacing={2}>

                            <FormControl>
                                <TextField
                                    label="Template Name"
                                    variant="outlined"
                                    fullWidth
                                    value={templateName}
                                    onChange={changeName}
                                />
                            </FormControl>

                            <FormControl>
                                <InputLabel>Template Type</InputLabel>
                                <Select label="Template Type" value={templateType} onChange={changeType}>
                                    <MenuItem value="single">Single</MenuItem>
                                    <MenuItem value="double">Double</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        label="Page Print Width"
                                        variant="outlined"
                                        fullWidth
                                        value={signWidth}
                                        onChange={changeWidth}
                                    />
                                    <TextField
                                        label="Page Print Height"
                                        variant="outlined"
                                        fullWidth
                                        value={signHeight}
                                        onChange={changeHeight}
                                    />
                                </Stack>
                            </FormControl>

                            {(!!!templateId) &&
                                <Button variant="contained" onClick={saveAndKeep} disabled={!templateIsValid}>
                                    Upload Sign Artwork
                                </Button>
                            }

                            {(!!templateId && !!bgImgUrl && !replaceImage) &&
                                <Button variant="contained" onClick={() => setReplaceImage(true)} disabled={!!!bgImgUrl}>
                                    Replace Sign Artwork
                                </Button>
                            }

                            {((!!templateId && !!!bgImgUrl) || replaceImage) && <React.Fragment>
                                <FileUpload
                                    title="Drag & Drop Artwork"
                                    buttonText="Browse..."
                                    maxFiles={1}
                                    value={bgImgUpload}
                                    onChange={setBgImgUpload}
                                />
                                {replaceImage && <Button variant="contained" onClick={() => setReplaceImage(false)}>
                                    Cancel
                                </Button>}
                            </React.Fragment>}

                            <Button variant="contained" className="set-dimensions-button" onClick={openPositioner} disabled={!qrPositionReady}>
                                Position QR Code
                            </Button>

                            <FormControl>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        label="QR Code Size"
                                        variant="outlined"
                                        fullWidth
                                        value={qrSize}
                                        onChange={changeQrSize}
                                    />
                                    <TextField
                                        label="X Position"
                                        variant="outlined"
                                        fullWidth
                                        value={qrXPos}
                                        onChange={changeQrXPos}
                                    />
                                    <TextField
                                        label="Y Position"
                                        variant="outlined"
                                        fullWidth
                                        value={qrYPos}
                                        onChange={changeQrYPos}
                                    />
                                </Stack>
                            </FormControl>

                        </Stack>
                    </Box>

                    <Stack spacing={2}>
                        <Button variant="contained" onClick={saveAndClose} disabled={!templateIsValid}>
                            Save & Close
                        </Button>
                        {templateId && <Button variant="contained" color="error" onClick={handleDelete}>
                            Delete
                        </Button>}
                    </Stack>

                </Stack>
            </Box>
        </Drawer>
    </React.Fragment>

};

export default SignEdit;