export const thisHost = window.location.protocol.concat("//").concat(window.location.host);
export const homePage = `${thisHost}/`;
export const theCurb = `${thisHost}/curb`;

export const staffRoles: string[] = ["staff"];

export const autoReplyTypes: string[] = [
    "Welcome",
    "Example",
    "Service",
    "Follow Up",
    "After Hours",
    "Order New",
    "Order Sent",
    "Order Ready"
];

export const monthsOfYear: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

export const daysOfWeek: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
];

export const transcriptSystemTags: string[] = [
    // "Placeholder",
];

export const wordCloudFiltered: string[] = [
    "order",
    "start",
    "begin",
    "table",
    "send",
    "press",
    "with",
    "and",
    "skip",
    "remove",
    "pay",
    "example",
    "marketinginit"
];

export const stdDateFormat: string = "M/D/YY, h:mm A";
export const stdDateFormatSeconds: string = "M/D/YY, h:mm:ss A";

export const buttonColors = [
    { name: "Green", hex: "#4ead49" },
    { name: "Blue", hex: "#1ea0de" },
    { name: "Purple", hex: "#a020f0" },
    { name: "Orange", hex: "#ffa500" },
    { name: "Red", hex: "#eb4034" }
];

export const circleButtonSx = {
    borderRadius: "50%",
    minWidth: "45px",
    width: "45px",
    height: "45px",
    p: 0
}

export const detailSlideSx = {
    minWidth: "375px",
    width: "75vw",
    p: 4
};

export const editSlideSx = {
    minWidth: "375px",
    width: "50vw",
    p: 4
};

const modalSx = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: "calc(100vw - 100px)",
    bgcolor: 'background.paper',
    overflow: 'auto',
    boxShadow: 24,
    p: 4
}

export const smallModalSx = {
    ...modalSx,
    width: 450
};

export const mediumModalSx = {
    ...modalSx,
    width: 800
};

export const fullModalSx = {
    ...modalSx,
    width: "calc(100vw - 100px)",
    height: "calc(100vh - 100px)"
};

export const dataGridSx = {
    "& .MuiDataGrid-row": {
        cursor: "pointer",
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
        outline: "none"
    }
};