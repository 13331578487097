import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridColDef, GridSelectionModel } from '@mui/x-data-grid-pro';
import { GridCellParams } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LicenseInfo } from '@mui/x-license-pro';
import { useGetTagsByZoneIdQuery } from '../Redux/Services/OhWaiter';
import { useDeleteTagsMutation } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Tag } from '../Types';
import Main from '../Layouts/Main';
import LocationSelector from '../Components/LocationSelector';
import ZoneSelector from '../Components/ZoneSelector';
import QrEdit from '../Components/Modals/QrEdit';
import { apiSuccess, apiError } from '../Helpers/Toaster';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_KEY as string);

export default function Tags() {

    const dispatch = useDispatch();
    const [locationId, setLocationId] = useState<string>('');
    const [zoneId, setZoneId] = useState<string>('');
    const [qrGridSelection, setQrGridSelection] = useState<GridSelectionModel>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const [qrEditOpen, setQrEditOpen] = useState(false);
    const openQrEdit = () => setQrEditOpen(true);
    const closeQrEdit = () => setQrEditOpen(false);

    // Redux Selectors
    const { data: tagsData = null, isLoading: tagsLoading } = useGetTagsByZoneIdQuery({ zoneId }, { skip: !!!zoneId });
    const [deleteTags] = useDeleteTagsMutation();
    const tags = useMemo(() => tagsData as Tag[] || [], [locationId, tagsData]); // eslint-disable-line react-hooks/exhaustive-deps

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "QR Tags", status: tagsLoading }));
    }, [dispatch, tagsLoading]);

    const updateQrSelection = (selectedItems: GridSelectionModel) => {
        setQrGridSelection(selectedItems);
        const selectedRows = tags.filter((t) => (t.tagId && new Set(selectedItems).has(t.tagId.toString())));
        setSelectedTags(selectedRows.map(t => t.tagName) as string[]);
    }

    const handleDeleteQr = async (event: React.MouseEvent<HTMLButtonElement>) => {
        await deleteTags({ zoneId, tags: selectedTags })
            .then(() => apiSuccess("Tags Deleted!"))
            .catch((err) => apiError(err));
    };

    const qrActionsCell = (params: GridCellParams) => {
        const clickAction = async () => {
            await deleteTags({ zoneId, tags: [params.row.tagName] })
                .then(() => apiSuccess("Tag Deleted!"))
                .catch((err) => apiError(err));
        };
        return <Grid container justifyContent="flex-end" mx={1}>
            <Stack direction="row" alignItems="center" spacing={3}>
                <DeleteForeverIcon fontSize="small" color="error" style={{ cursor: "pointer" }} onClick={clickAction} />
            </Stack>
        </Grid>
    }

    const qrColumns: GridColDef[] = [
        {
            field: 'tagName',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'tagId',
            headerName: '',
            width: 115,
            renderCell: qrActionsCell
        }
    ];

    return <Main header="QR Tags">

        {zoneId && <QrEdit zoneId={parseInt(zoneId)} open={qrEditOpen} handleClose={closeQrEdit} />}

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector selectionHandler={setLocationId} />
                                <ZoneSelector locationId={locationId} selectionHandler={setZoneId} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            {!!selectedTags.length &&
                                <Button variant="contained" color="error" startIcon={<DeleteForeverIcon />} onClick={handleDeleteQr}>
                                    Remove Selected
                                </Button>}
                            <Button variant="contained" startIcon={<AddCircleIcon />} onClick={openQrEdit} disabled={!locationId}>
                                Add New QR Tags
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>

                <DataGridPro
                    columns={qrColumns}
                    rows={(!locationId) ? [] : tags}
                    getRowId={(row) => row.tagId}
                    autoHeight
                    hideFooter
                    checkboxSelection
                    disableSelectionOnClick
                    onSelectionModelChange={(selectModel) => updateQrSelection(selectModel)}
                    selectionModel={qrGridSelection}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "tagName", sort: "asc" }],
                        },
                    }}
                />

            </Grid>

        </Grid>

    </Main >

};