import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useGetLocationsQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Location as LocationType } from '../Types';
import Main from '../Layouts/Main';
import Onboarding from '../Components/Onboarding';
import HelperText from '../Components/HelperText';

export default function Home() {

    const dispatch = useDispatch();

    // Redux Selectors
    const customerId = useSelector((state: any) => state.userSlice.customerId);
    const { data: locationsData = null, isLoading: locationsLoading } = useGetLocationsQuery(customerId);
    const locations = locationsData as LocationType[] || [];

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Locations", status: locationsLoading }));
    }, [dispatch, locationsLoading]);

    return <Main header="Home">

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Typography variant="h6" className="bold-header">On-Boarding Status</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        {/* Placeholder */}
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {locations.map(location => <React.Fragment key={location.id}>
                        {location.id && <Onboarding locationId={parseInt(location.id)} />}
                    </React.Fragment>)}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            {/* Copy */}
            <Grid item xs={12}>
                <HelperText>
                    <Typography variant="body2"><strong>Welcome to the new OhWaiter Customer Portal!</strong> This is your main dashboard, and soon will feature direct access to your business' overall data and metrics. For now, it will give you access to administer your OhWaiter-powered locations, allowing you to configure your scheduled <strong>Automatic Replies</strong> in your locations' various <strong>Zones</strong>, and it will allow you to design and generate your OhWaiter signage.</Typography>
                    <Typography variant="body2">To help you understand the OhWaiter system, here are some quick terminology definitions:</Typography>
                    <Typography variant="body2" component="span">
                        <ul>
                            <li>By <strong>Locations</strong>, we mean your physical establishments. Each location will have <strong>Zones</strong> within it.</li>
                            <li><strong>Zones</strong> are general areas of a <strong>Location</strong> where your guests may be found using OhWaiter. Some examples are: Tables, Bar, Hotel Rooms, Pool, Golf Course, Tennis Courts, etc.</li>
                            <li><strong>QR Codes</strong> or <strong>Tags</strong> are specific locators within a <strong>Zone</strong>. These could be table or seat numbers, or you can use any other identifier that will help your staff locate a specific guest. Each <strong>Tag</strong> will have a unique QR code; they are one and the same.</li>
                            <li><strong>Automatic Replies</strong> are messages guests will automatically receive when they greet OhWaiter from a specific zone, during a specified time of day. By customizing Automatic Replies, we can ensure that your guests get a breakfast menu during breakfast hours, the happy hour menu during happy hour, or be told that the kitchen isn't serving orders if they use OhWaiter outside of that area's operating hours.</li>
                            <li>Your <strong>Sign Templates</strong> are the different formats of your OhWaiter signage, which you can customize and then generate for any <strong>Zones</strong>, with unique <strong>Tags</strong> automatically embedded. You can download your printable signs in the <strong>Sign PDFs</strong> section, once they have been generated.</li>
                        </ul>
                    </Typography>
                    <Typography variant="body2">If you need to add additional locations, please contact OhWaiter so we can start the on-boarding process.</Typography>
                </HelperText>
            </Grid>


        </Grid>

    </Main>

};