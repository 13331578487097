import { Store } from '../Store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { msalConfig, loginRequest, logoutRequest } from '../../msalConfig';
import { PublicClientApplication, AccountEntity } from '@azure/msal-browser';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { setAccountRole, setLocationLookup } from '../Slices/Admin';
import { setCustomerId } from '../Slices/User';
import { setRecentLocation, setRecentZone } from '../Slices/RecentLocation';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const msalApp = new PublicClientApplication(msalConfig);

const tagTypes = [
    'Location',
    'TagsByZone',
    'SignsByLocation',
    'PDFsByLocation',
    'MenuItemsByLocation',
    'ModifierItemsByLocation',
    'RepliesByZone',
    'ButtonsByZone',
    'ChatTranscriptsByLocation',
    'ChatTranscriptById',
    'TranscriptTagsByLocation',
    'TranscriptTagsByEOM',
    'OrderTotalsByEOM',
    'WordCloudByEOM',
    'MarketingCohortsByLocation',
    'MarketingCohortsByGuest',
    'MarketingCampaignsByLocation',
    'UserSettings',
    'CustomerSettings',
    'LocationSettings',
    'SquareOauth'
];

const endpoints = (builder) => ({
    getCustomers: builder.query({
        query: () => `/Customer`,
        transformResponse: (res) => {
            const locationLookup = {};
            res.forEach(customer => {
                customer.locations.forEach(location => {
                    locationLookup[location.id] = customer.id;
                });
            });
            Store.dispatch(setLocationLookup(locationLookup));
            return res;
        }
    }),
    getLocations: builder.query({
        query: (customerId) => `/Location?customerId=${customerId}`,
        transformResponse: (res) => {
            const foundCustomer = res.find(r => !!r.customerId);
            if (!!foundCustomer) Store.dispatch(setCustomerId(foundCustomer.customerId));
            return res;
        }
    }),
    getLocationById: builder.query({
        providesTags: (res, err, { locationId }) => [{ type: 'Location', id: locationId }],
        query: ({ locationId }) => `/Location/${locationId}/details`,
        onQueryStarted: ({ locationId, noMemory }) => {
            if (!noMemory) Store.dispatch(setRecentLocation(locationId));
        }
    }),
    saveZone: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'Location', id: locationId }],
        query: ({ locationId, zoneName }) => ({
            url: `/Zone`,
            method: 'POST',
            body: { locationId, zoneName }
        })
    }),
    deleteZone: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'Location', id: locationId }],
        query: ({ zoneId }) => ({
            url: `/Zone/delete`,
            method: 'PUT',
            body: [zoneId]
        })
    }),
    getSignsByLocationId: builder.query({
        providesTags: (res, err, { locationId }) => [{ type: 'SignsByLocation', id: locationId }],
        query: ({ locationId }) => `/Sign/template/${locationId}`,
        onQueryStarted: ({ locationId, noMemory }) => {
            if (!noMemory) Store.dispatch(setRecentLocation(locationId));
        }
    }),
    saveSignTemplate: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'SignsByLocation', id: locationId }],
        query: ({ locationId, sign }) => ({
            url: `/Sign/template/${locationId}`,
            method: 'POST',
            body: sign
        })
    }),
    deleteSignTemplate: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'SignsByLocation', id: locationId }],
        query: ({ locationId, templateId }) => ({
            url: `/Sign/template/${locationId}/${templateId}`,
            method: 'DELETE'
        })
    }),
    replaceSignImage: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'SignsByLocation', id: locationId }],
        query: ({ locationId, templateId, formData }) => ({
            url: `/Sign/Template/${locationId}/${templateId}/background`,
            method: 'POST',
            body: formData
        })
    }),
    getPDFsByLocationId: builder.query({
        providesTags: (res, err, { locationId }) => [{ type: 'PDFsByLocation', id: locationId }],
        query: ({ locationId }) => `/Sign/Pdf/${locationId}`,
        onQueryStarted: ({ locationId, noMemory }) => {
            if (!noMemory) Store.dispatch(setRecentLocation(locationId));
        }
    }),
    generatePDF: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'PDFsByLocation', id: locationId }],
        query: ({ locationId, templateId, tags }) => ({
            url: `/Sign/Pdf/${locationId}/${templateId}`,
            method: 'POST',
            body: tags
        })
    }),
    deletePDF: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'PDFsByLocation', id: locationId }],
        query: ({ locationId, pdfId }) => ({
            url: `/Sign/Pdf/${locationId}/${pdfId}`,
            method: 'DELETE'
        })
    }),
    getMenuItemsByLocationId: builder.query({
        providesTags: (res, err, { locationId }) => [{ type: 'MenuItemsByLocation', id: locationId }],
        query: ({ locationId }) => `/Menu/items/${locationId}`,
        onQueryStarted: ({ locationId, noMemory }) => {
            if (!noMemory) Store.dispatch(setRecentLocation(locationId));
        }
    }),
    getMenuMetaByLocationId: builder.query({
        providesTags: (res, err, { locationId }) => [{ type: 'MenuMetaByLocation', id: locationId }],
        query: ({ locationId }) => `/Menu/menus/${locationId}`,
        onQueryStarted: ({ locationId, noMemory }) => {
            if (!noMemory) Store.dispatch(setRecentLocation(locationId));
        }
    }),
    getModifierItemsByLocationId: builder.query({
        providesTags: (res, err, { locationId }) => [{ type: 'ModifierItemsByLocation', id: locationId }],
        query: ({ locationId }) => `/Menu/item_modifiers/${locationId}`,
        onQueryStarted: ({ locationId, noMemory }) => {
            if (!noMemory) Store.dispatch(setRecentLocation(locationId));
        }
    }),
    saveSynonyms: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'MenuItemsByLocation', id: locationId }],
        query: ({ locationId, synonym }) => ({
            url: `/Menu/synonym`,
            method: 'POST',
            body: {
                locationId: locationId?.toString(),
                synonym
            }
        })
    }),
    saveModifierSynonyms: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'ModifierItemsByLocation', id: locationId }],
        query: ({ locationId, synonym }) => ({
            url: `/Menu/item_modifiers/synonym`,
            method: 'POST',
            body: {
                locationId: locationId?.toString(),
                synonym
            }
        })
    }),
    getGuests: builder.query({
        query: ({ offset, limit }) => `/Guest/customer?offset=${offset}&limit=${limit}`,
    }),
    getGuestsByLocationId: builder.query({
        query: ({ locationId, offset, limit }) => `/Guest/location/${locationId}?offset=${offset}&limit=${limit}`,
        onQueryStarted: ({ locationId, noMemory }) => {
            if (!noMemory) Store.dispatch(setRecentLocation(locationId));
        }
    }),
    getGuestById: builder.query({
        query: ({ guestId }) => `/Guest/guest/${guestId}`
    }),
    getChatTranscriptsByLocationId: builder.query({
        providesTags: (res, err, { locationId, tagsInclude, tagsExclude }) => {
            let tagKey = locationId;
            if (tagsInclude.length > 0) tagKey = `${tagKey}_in_` + tagsInclude.join('-');
            if (tagsExclude.length > 0) tagKey = `${tagKey}_ex_` + tagsExclude.join('-');
            return [{ type: 'ChatTranscriptsByLocation', id: tagKey }]
        },
        query: ({ locationId, eom, guestFilter, tagsInclude, tagsExclude, offset, limit }) => {
            const queryParams = new URLSearchParams();
            if (!!eom) queryParams.append('eom', eom);
            if (tagsInclude.length > 0) queryParams.append('inclusiveTags', tagsInclude.join(','));
            if (tagsExclude.length > 0) queryParams.append('exclusiveTags', tagsExclude.join(','));
            const byLocation = `/Transcript/${locationId}/${offset}/${limit}`;
            const byGuest = `/Transcript/${locationId}/${guestFilter}/${offset}/${limit}`;
            const baseEndpoint = (!!guestFilter) ? byGuest : byLocation;
            return (!!queryParams.toString()) ? (baseEndpoint + '?' + queryParams.toString()) : baseEndpoint;
        },
        onQueryStarted: ({ locationId, noMemory }) => {
            if (!noMemory) Store.dispatch(setRecentLocation(locationId));
        }
    }),
    getChatTranscript: builder.query({
        providesTags: (res, err, { conversationId }) => [{ type: 'ChatTranscriptById', id: conversationId }],
        query: ({ customerId, locationId, conversationId, guestHash }) => `/Transcript/${locationId}/conversation/${conversationId}/${guestHash}?customer=${customerId}`,
        onQueryStarted: ({ locationId, noMemory }) => {
            if (!noMemory) Store.dispatch(setRecentLocation(locationId));
        }
    }),
    getTranscriptTagsByLocation: builder.query({
        providesTags: (res, err, { locationId }) => [{ type: 'TranscriptTagsByLocation', id: locationId }],
        query: ({ locationId }) => `/Reports/${locationId}/transcript_tags`
    }),
    getTranscriptTagsByEOM: builder.query({
        providesTags: (res, err, { locationId }) => [{ type: 'TranscriptTagsByEOM', id: locationId }],
        query: ({ locationId, eom }) => `/Reports/${locationId}/transcript_tags_by_eom/${eom}`
    }),
    getOrderTotalsByEOM: builder.query({
        providesTags: (res, err, { locationId }) => [{ type: 'OrderTotalsByEOM', id: locationId }],
        query: ({ locationId, eom }) => `/Reports/${locationId}/order_total_due_by_eom/${eom}`
    }),
    getWordCloudByEOM: builder.query({
        providesTags: (res, err, { locationId }) => [{ type: 'WordCloudByEOM', id: locationId }],
        query: ({ customerId, locationId, eom }) => `/Reports/${locationId}/wordcloud_by_eom/${eom}?customerId=${customerId}`
    }),
    setChatTranscriptTags: builder.mutation({
        invalidatesTags: (res, err, { locationId, conversationId }) => [
            { type: 'ChatTranscriptById', id: conversationId },
            { type: 'ChatTranscriptsByLocation' },
            { type: 'TranscriptTagsByLocation' },
            { type: 'TranscriptTagsByEOM' },
            { type: 'OrderTotalsByEOM' },
        ],
        query: ({ locationId, conversationId, guestHash, tags }) => ({
            url: `/Transcript/${locationId}/messages/${conversationId}/${guestHash}`,
            method: 'POST',
            body: tags
        })
    }),
    getTagsByZoneId: builder.query({
        providesTags: (res, err, { zoneId }) => [{ type: 'TagsByZone', id: zoneId }],
        query: ({ zoneId }) => `/Zone/${zoneId}/tags`,
        onQueryStarted: ({ zoneId, noMemory }) => {
            if (!noMemory) Store.dispatch(setRecentZone(zoneId));
        }
    }),
    saveTags: builder.mutation({
        invalidatesTags: (res, err, { zoneId }) => [{ type: 'TagsByZone', id: zoneId }],
        query: ({ zoneId, tags }) => ({
            url: `/Zone/${zoneId}/tags`,
            method: 'POST',
            body: tags
        })
    }),
    deleteTags: builder.mutation({
        invalidatesTags: (res, err, { zoneId }) => [{ type: 'TagsByZone', id: zoneId }],
        query: ({ zoneId, tags }) => ({
            url: `/Zone/${zoneId}/delete/tags`,
            method: 'PUT',
            body: tags
        })
    }),
    getRepliesByZoneId: builder.query({
        providesTags: (res, err, { zoneId }) => [{ type: 'RepliesByZone', id: zoneId }],
        query: ({ zoneId }) => `/Zone/${zoneId}/responses`,
        onQueryStarted: ({ zoneId, noMemory }) => {
            if (!noMemory) Store.dispatch(setRecentZone(zoneId));
        }
    }),
    saveReply: builder.mutation({
        invalidatesTags: (res, err, { zoneId }) => [{ type: 'RepliesByZone', id: zoneId }],
        query: ({ zoneId, reply }) => ({
            url: `/Zone/${zoneId}/response`,
            method: 'POST',
            body: reply
        })
    }),
    deleteReplies: builder.mutation({
        invalidatesTags: (res, err, { zoneId }) => [{ type: 'RepliesByZone', id: zoneId }],
        query: ({ zoneId, replyIds }) => ({
            url: `/Zone/${zoneId}/delete/responses`,
            method: 'PUT',
            body: replyIds
        })
    }),
    getButtonsByZoneId: builder.query({
        providesTags: (res, err, { zoneId }) => [{ type: 'ButtonsByZone', id: zoneId }],
        query: ({ zoneId }) => `/Zone/${zoneId}/buttons`,
        onQueryStarted: ({ zoneId, noMemory }) => {
            if (!noMemory) Store.dispatch(setRecentZone(zoneId));
        },
        transformResponse: (res) => {
            if (!!res.length) {
                let initOrdinal = 0;
                const withOrdinal = res.map(button => {
                    initOrdinal++;
                    const newOrdinal = !!button.ordinal ? button.ordinal : initOrdinal;
                    return { ...button, newOrdinal };
                });
                return withOrdinal;
            }
            return res;
        }
    }),
    saveButton: builder.mutation({
        invalidatesTags: (res, err, { zoneId }) => [{ type: 'ButtonsByZone', id: zoneId }],
        query: ({ zoneId, button }) => ({
            url: `/Zone/${zoneId}/button`,
            method: 'POST',
            body: {
                ...button,
                category: "Default"
            }
        })
    }),
    saveButtonsOrder: builder.mutation({
        invalidatesTags: (res, err, { zoneId }) => [{ type: 'ButtonsByZone', id: zoneId }],
        query: ({ zoneId, buttons }) => ({
            url: `/Zone/${zoneId}/buttons`,
            method: 'POST',
            body: buttons
        })
    }),
    deleteButtons: builder.mutation({
        invalidatesTags: (res, err, { zoneId }) => [{ type: 'ButtonsByZone', id: zoneId }],
        query: ({ zoneId, buttonIds }) => ({
            url: `/Zone/${zoneId}/delete/buttons`,
            method: 'PUT',
            body: buttonIds
        })
    }),
    getMarketingCampaigns: builder.query({
        providesTags: (res, err, { locationId }) => [{ type: 'MarketingCampaignsByLocation', id: locationId }],
        query: ({ locationId }) => `/Marketing/campains/${locationId}`,
    }),
    createMarketingCampaign: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'MarketingCampaignsByLocation', id: locationId }],
        query: (campaignObject) => ({
            url: `/Marketing/campain`,
            method: 'POST',
            body: campaignObject
        })
    }),
    startMarketingCampaign: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'MarketingCampaignsByLocation', id: locationId }],
        query: ({ locationId, campaignId }) => `/Marketing/campain/${locationId}/start/${campaignId}`
    }),
    stopMarketingCampaign: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'MarketingCampaignsByLocation', id: locationId }],
        query: ({ locationId, campaignId }) => `/Marketing/campain/${locationId}/stop/${campaignId}`
    }),
    getMarketingCohorts: builder.query({
        providesTags: (res, err, { locationId }) => [{ type: 'MarketingCohortsByLocation', id: locationId }],
        query: ({ locationId }) => `/Marketing/cohorts/${locationId}`,
    }),
    getMarketingCohortsByGuest: builder.query({
        providesTags: (res, err, { guestId }) => [{ type: 'MarketingCohortsByGuest', id: guestId }],
        query: ({ locationId, guestId }) => `/Marketing/cohorts/${locationId}/by_guest/${guestId}`,
    }),
    getMarketingCohortSample: builder.query({
        query: ({ locationId, cohortGenerators }) => ({
            url: `/Marketing/cohort/sample`,
            method: 'PUT',
            body: {
                locationId,
                includeOptedout: true,
                cohortGenerators
            }
        })
    }),
    createMarketingCohort: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'MarketingCohortsByLocation', id: locationId }],
        query: (cohortObject) => ({
            url: `/Marketing/cohort`,
            method: 'POST',
            body: cohortObject
        })
    }),
    deleteMarketingCohort: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'MarketingCohortsByLocation', id: locationId }],
        query: ({ locationId, cohortId }) => ({
            url: `/Marketing/cohort/${locationId}/delete/${cohortId}`,
            method: 'PUT'
        })
    }),
    addToMarketingCohort: builder.mutation({
        invalidatesTags: () => ['MarketingCohortsByLocation', 'MarketingCohortsByGuest'],
        query: ({ locationId, guestIds, cohortId }) => ({
            url: `/Marketing/cohort/${locationId}/add_guests/${cohortId}`,
            method: 'PUT',
            body: guestIds
        })
    }),
    removeFromMarketingCohort: builder.mutation({
        invalidatesTags: () => ['MarketingCohortsByLocation', 'MarketingCohortsByGuest'],
        query: ({ locationId, guestIds, cohortId }) => ({
            url: `/Marketing/cohort/${locationId}/remove_guests/${cohortId}`,
            method: 'PUT',
            body: guestIds
        })
    }),
    getUserSettings: builder.query({
        providesTags: () => ['UserSettings'],
        query: () => `/User/settings`,
    }),
    saveUserSettings: builder.mutation({
        invalidatesTags: () => ['UserSettings'],
        query: (settingsObject) => ({
            url: `/User/settings`,
            method: 'POST',
            body: settingsObject
        })
    }),
    getCustomerSettings: builder.query({
        providesTags: (res, err, { customerId }) => [{ type: 'CustomerSettings', id: customerId }],
        query: ({ customerId }) => `/Customer/settings?customerId=${customerId}`
    }),
    saveCustomerSettings: builder.mutation({
        invalidatesTags: (res, err, { customerId }) => [{ type: 'CustomerSettings', id: customerId }],
        query: ({ customerId, settingsObject }) => ({
            url: `/Customer/settings?customerId=${customerId}`,
            method: 'POST',
            body: settingsObject
        })
    }),
    getLocationSettings: builder.query({
        providesTags: (res, err, { locationId }) => [{ type: 'LocationSettings', id: locationId }],
        query: ({ locationId }) => `/Location/settings?locationId=${locationId}`
    }),
    saveLocationSettings: builder.mutation({
        invalidatesTags: (res, err, { locationId }) => [{ type: 'LocationSettings', id: locationId }],
        query: ({ locationId, settingsObject }) => ({
            url: `/Location/settings?locationId=${locationId}`,
            method: 'POST',
            body: settingsObject
        })
    }),
    getSquareStatus: builder.query({
        providesTags: () => ['SquareOauth'],
        query: ({ locationId }) => `/Square/oauth/status/${locationId}`
    }),
    getSquareAuthUrl: builder.query({
        providesTags: () => ['SquareOauth'],
        query: ({ locationId }) => `/Square/oauth/authorize_url/${locationId}`
    }),
    getSquareLocations: builder.query({
        providesTags: () => ['SquareOauth'],
        query: ({ locationId }) => `/Square/locations/${locationId}`
    }),
    getSquareLocationInfo: builder.query({
        providesTags: () => ['SquareOauth'],
        query: ({ locationId }) => `/Square/locations/status/${locationId}`
    }),
    setSquareConnection: builder.mutation({
        invalidatesTags: () => ['SquareOauth'],
        query: ({ locationId, code }) => ({
            url: `/Square/oauth/code/${locationId}`,
            method: 'POST',
            body: {
                "code": code
            }
        })
    }),
    associateSquareLocation: builder.mutation({
        invalidatesTags: () => ['SquareOauth'],
        query: ({ locationId, squareLocationId }) => ({
            url: `/Square/locations/init/${locationId}`,
            method: 'POST',
            body: {
                "squareLocationId": squareLocationId
            }
        })
    }),
    getIACMLocation: builder.query({
        query: (code) => ({
            url: `/IACM/oauth`,
            method: 'POST',
            body: { code }
        })
    }),
    setIACMAssociation: builder.mutation({
        query: ({ locationId, IACMLocationId }) => ({
            url: `/IACM/setlocation`,
            method: 'POST',
            body: {
                "IACMLocationId": IACMLocationId,
                "OhWaiterLocationId": locationId
            }
        })
    })
});

const accessTokenRequest = {
    ...loginRequest,
    account: AccountEntity
};

const setRole = (response) => {
    if (response.idTokenClaims) {
        if (response.idTokenClaims.role) {
            const accountRole = response.idTokenClaims.role.toString();
            Store.dispatch(setAccountRole(accountRole));
        } else {
            // Role was lost. This used to be an issue.
            // It shouldn't be an issue anymore, but leaving it just in case.
            msalApp.logoutRedirect(logoutRequest);
        }
    }
}

const baseQuery = fetchBaseQuery({
    baseUrl: REACT_APP_API_ENDPOINT,
    prepareHeaders: async (headers) => {
        const customerId = Store.getState().adminSlice.customerId;
        if (!!customerId) headers.set('x-customer-id', customerId.toString());
        await msalApp.acquireTokenSilent(accessTokenRequest)
            .then((response) => {
                setRole(response);
                headers.set('Authorization', `Bearer ${response.accessToken}`);
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    msalApp.acquireTokenRedirect(accessTokenRequest)
                        .then(function (response) {
                            setRole(response);
                            headers.set('Authorization', `Bearer ${response.accessToken}`);
                        })
                }
            });
        return headers;
    }
});

export const portalApi = createApi({
    reducerPath: "svc",
    baseQuery,
    endpoints,
    tagTypes
});

export const {
    useGetCustomersQuery,
    useGetLocationsQuery,
    useGetLocationByIdQuery,
    useSaveZoneMutation,
    useDeleteZoneMutation,
    useGetTagsByZoneIdQuery,
    useSaveTagsMutation,
    useDeleteTagsMutation,
    useGetSignsByLocationIdQuery,
    useReplaceSignImageMutation,
    useSaveSignTemplateMutation,
    useDeleteSignTemplateMutation,
    useGetPDFsByLocationIdQuery,
    useGeneratePDFMutation,
    useDeletePDFMutation,
    useGetMenuItemsByLocationIdQuery,
    useGetMenuMetaByLocationIdQuery,
    useGetModifierItemsByLocationIdQuery,
    useSaveSynonymsMutation,
    useSaveModifierSynonymsMutation,
    useGetGuestsQuery,
    useGetGuestsByLocationIdQuery,
    useGetGuestByIdQuery,
    useGetChatTranscriptsByLocationIdQuery,
    useGetChatTranscriptQuery,
    useGetTranscriptTagsByLocationQuery,
    useGetTranscriptTagsByEOMQuery,
    useGetOrderTotalsByEOMQuery,
    useGetWordCloudByEOMQuery,
    useSetChatTranscriptTagsMutation,
    useGetRepliesByZoneIdQuery,
    useSaveReplyMutation,
    useDeleteRepliesMutation,
    useGetButtonsByZoneIdQuery,
    useSaveButtonMutation,
    useSaveButtonsOrderMutation,
    useDeleteButtonsMutation,
    useGetMarketingCampaignsQuery,
    useCreateMarketingCampaignMutation,
    useStartMarketingCampaignMutation,
    useStopMarketingCampaignMutation,
    useGetMarketingCohortsQuery,
    useGetMarketingCohortsByGuestQuery,
    useLazyGetMarketingCohortSampleQuery,
    useCreateMarketingCohortMutation,
    useDeleteMarketingCohortMutation,
    useAddToMarketingCohortMutation,
    useRemoveFromMarketingCohortMutation,
    useGetUserSettingsQuery,
    useSaveUserSettingsMutation,
    useGetCustomerSettingsQuery,
    useSaveCustomerSettingsMutation,
    useGetLocationSettingsQuery,
    useSaveLocationSettingsMutation,
    useGetSquareStatusQuery,
    useGetSquareAuthUrlQuery,
    useGetSquareLocationsQuery,
    useGetSquareLocationInfoQuery,
    useSetSquareConnectionMutation,
    useAssociateSquareLocationMutation,
    useGetIACMLocationQuery,
    useSetIACMAssociationMutation
} = portalApi;