import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Reply } from '../Types';
import { linearMap } from '../Helpers/NumberMapping';
import { daysOfWeek } from '../Constants';

import './Calendar.scss';

type CalendarProps = {
    replies: Reply[];
    handleNew: () => void;
    handleEdit: (id: number) => void;
};

const Calendar: React.FC<CalendarProps> = ({ replies, handleNew, handleEdit }) => {

    // Bucket replies into a dictionary with day index (0-6) as the key...
    // ...And map times to pixel positions based on 35px hours (set in Calendar.scss):
    interface Dictionary<Type> { [key: number]: Type[]; }
    type ReplyExtended = Reply & {
        secondsStart?: number;
        secondsDuration?: number;
        yPos?: number;
        height?: number;
    };
    const secondsToPixels = (s: number) => linearMap({ in: s, x1: 0, y1: 86400, x2: 0, y2: (35 * 24) });
    const mmssToSeconds = (input: string) => {
        const timeParts: number[] = input.split(":").map(n => parseInt(n));
        const seconds: number = ((+timeParts[0]) * 60 * 60 + (+timeParts[1]) * 60);
        return seconds;
    };
    const repliesByDay: Dictionary<ReplyExtended> = {};
    replies.map(reply => {
        const dayIndexes = reply.daysOfWeek?.split(",").map(index => parseInt(index));
        return dayIndexes?.forEach(day => {
            const replyObj: ReplyExtended = { ...reply };
            if (!!replyObj.startTime && !!replyObj.endTime) {
                replyObj.secondsStart = mmssToSeconds(replyObj.startTime);
                replyObj.secondsDuration = mmssToSeconds(replyObj.endTime) - mmssToSeconds(replyObj.startTime);
                replyObj.yPos = secondsToPixels(replyObj.secondsStart);
                replyObj.height = secondsToPixels(replyObj.secondsDuration);
            }
            if (!repliesByDay.hasOwnProperty(day)) repliesByDay[day] = [];
            repliesByDay[day].push(replyObj);
        });
    });

    const clickCalendar = (day: number, hour: number) => {
        // TODO Capture day/hour and pre-populate... 
        console.log(`Clicked ${day} ${hour}`);
        handleNew();
    }

    return <Grid container spacing={1}>
        {daysOfWeek.map((day, d) => <Grid key={d} item xs>
            <Box p={1} className="calendar-column">

                {/* Calendar Header: */}
                <Typography variant="button" className="calendar-header">{day}</Typography>

                {/* TODO: Best way to pass reply.responseId to the reply editor panel 🤔 */}

                {/* Replies: */}
                {repliesByDay[d] && repliesByDay[d].map(reply => {
                    const id = reply.responseId as string;
                    let yPosInt = 35 + 8; // Header + Margin
                    let heightInt = 0;
                    if (reply.yPos) yPosInt += reply.yPos;
                    if (reply.height) heightInt += reply.height;
                    const dx = {
                        top: yPosInt.toString() + "px",
                        height: heightInt.toString() + "px",
                        minHeight: secondsToPixels(900).toString() + "px" // 15 Minutes
                    };
                    return <Box key={id} className="calendar-item" style={dx} onClick={() => handleEdit(parseInt(id))}>
                        <Box p={1}>
                            <Typography variant="subtitle2">{reply.name}</Typography>
                            <Typography variant="body2">{`${reply.startTime} - ${reply.endTime}`}</Typography>
                        </Box>
                    </Box>
                })}

                {/* Background Grid: */}
                {[...Array(24)].map((_, h) =>
                    <Box key={h} className={`calendar-hour hour-${h}`}>
                        <div onClick={() => clickCalendar(d, h)}>{h}:00</div>
                    </Box>
                )}

            </Box>
        </Grid>)}
    </Grid>

};

export default Calendar;