import React, { useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useGetLocationByIdQuery } from '../Redux/Services/OhWaiter';
import { useGetUserSettingsQuery, useSaveUserSettingsMutation } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { UserSettings, Location as LocationType } from '../Types';
import { autoZone } from '../Helpers/AutoLocation';

const ZoneSelector: React.FC<{ locationId: string; selectionHandler: (locationId: string) => void; }> = ({ locationId, selectionHandler }) => {

    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [zoneId, setZoneId] = useState<string>('');
    const recentZoneId = useSelector((state: any) => state.recentLocation.zoneId);

    // Redux Selectors
    const { data: locationData = null, isLoading: locationLoading } = useGetLocationByIdQuery({ locationId }, { skip: !!!locationId });
    const location = locationData as LocationType || {};

    // Save to / Restore from User Settings
    const { data: userSettingsData = null } = useGetUserSettingsQuery(null);
    const userSettings = useMemo(() => userSettingsData as UserSettings || {}, [userSettingsData]);
    const [saveUserSettings] = useSaveUserSettingsMutation();

    // Loader Queue
    useEffect(() => {
        dispatch(addLoader({ loader: "Location", status: locationLoading }));
    }, [dispatch, locationLoading]);

    // Auto-Select Zone:
    useEffect(() => {
        const savedZoneId = (userSettings && userSettings.recentZoneId) ? userSettings.recentZoneId : null;
        setZoneId(autoZone(
            zoneId,
            recentZoneId,
            savedZoneId,
            location.zones,
            searchParams.get("zone")
        ));
    }, [location, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    // Affect Parent
    useEffect(() => {
        selectionHandler(zoneId);
        if (!!zoneId) saveUserSettings({ "recentZoneId": zoneId });
    }, [zoneId]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleZoneSelect = (event: SelectChangeEvent) => setZoneId(event.target.value);

    const { zones } = location;

    return <React.Fragment>
        {zones && <FormControl variant="standard" sx={{ minWidth: 140 }}>
            <InputLabel>Zone</InputLabel>
            <Select label="Zone" value={zoneId?.toString()} onChange={handleZoneSelect}>
                {zones.map(zone =>
                    <MenuItem key={zone.zoneId} value={zone.zoneId}>{zone.name}</MenuItem>
                )}
            </Select>
        </FormControl>}
    </React.Fragment>

}

export default ZoneSelector;