import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiSuccess = (message: string) => {
  toast.success(message, {
    autoClose: 1000,
    theme: "colored",
    hideProgressBar: true
  });
};

const apiError = (trace?: any | null, error?: string) => {
  if (!error) error = "API Error!";
  if (trace) console.error(trace);
  toast.error(error, {
    autoClose: 5000,
    theme: "colored",
    hideProgressBar: true
  });
};

export { apiSuccess, apiError };