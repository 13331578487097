import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import './HelperText.scss';

type Props = {
    children?: React.ReactNode;
    variant?: string;
};

type HelperStyle = { [s: string]: any };

const HelperText: React.FC<Props> = ({ children, variant }) => {

    const HelperTextStyle = {
        color: "#1564b3",
        borderColor: "#1976d2",
        backgroundColor: "#dfedfb"
    };

    const altStyles: HelperStyle = {
        success: {
            color: "#248f24",
            borderColor: "#248f24",
            backgroundColor: "#c6f1c6"
        },
        error: {
            color: "#ff3333",
            borderColor: "#ff3333",
            backgroundColor: "#ffe6e6"
        }
    }

    const classes = "helper-text " + (variant ? variant.toString() : "");

    let boxStyle = HelperTextStyle;
    if (!!variant && altStyles.hasOwnProperty(variant)) {
        boxStyle = {
            ...HelperTextStyle,
            ...Object.assign({}, altStyles[variant])
        }
    }

    return <Box p={2} style={boxStyle} className={classes}>
        <Stack spacing={1}>
            {children}
        </Stack>
    </Box>

};

export default HelperText;