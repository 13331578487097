import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const VALIDATION_ENDPOINT = process.env.REACT_APP_VALIDATION_ENDPOINT;

const tagTypes = [];

const endpoints = (builder) => ({
    launchTest: builder.mutation({
        query: (validation_request) => ({
            url: `/queue`,
            method: 'POST',
            body: validation_request
        })
    })
});

const baseQuery = fetchBaseQuery({
    baseUrl: VALIDATION_ENDPOINT
});

export const validationSvc = createApi({
    reducerPath: "validation",
    baseQuery,
    endpoints,
    tagTypes
});

export const {
    useLaunchTestMutation
} = validationSvc;