import { useState, useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import InventoryIcon from '@mui/icons-material/Inventory';
import WidthFullIcon from '@mui/icons-material/WidthFull';
import WidthNormalIcon from '@mui/icons-material/WidthNormal';
import FlagIcon from '@mui/icons-material/Flag';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import linkifyHtml from "linkify-html";
import { useGetLocationsQuery, useGetLocationByIdQuery } from '../Redux/Services/OhWaiter';
import { useGetChatTranscriptsByLocationIdQuery } from '../Redux/Services/OhWaiter';
import { useGetChatTranscriptQuery, useSetChatTranscriptTagsMutation } from '../Redux/Services/OhWaiter';
import { useGetUserSettingsQuery } from '../Redux/Services/OhWaiter';
import { useGetCustomerSettingsQuery } from '../Redux/Services/OhWaiter';
import { setCustomerId } from '../Redux/Slices/Admin';
import { addLoader } from '../Redux/Slices/Loading';
import { Location, TranscriptsResponse } from '../Types';
import { TranscriptRequest, TranscriptResponse, ChatHeader } from '../Types';
import { UserSettings, TranscriptsViewState } from '../Types';
import Main from '../Layouts/Main';
import ChatTagsEdit from '../Components/Modals/ChatTagsEdit';
import { getDateTimeFromGMTISO, getEOMFromDateString } from '../Helpers/Temporal';
import { transcriptSystemTags, stdDateFormatSeconds } from '../Constants';

import './Transcript.scss';

export default function Transcript() {

    let { location_id, guest_id, conversation_id } = useParams();

    const dispatch = useDispatch();
    const [transcriptRequest, setTranscriptRequest] = useState<TranscriptRequest | null>(null);
    const [transcript, setTranscript] = useState<TranscriptResponse | null>(null);
    const [transcripts, setTranscripts] = useState<ChatHeader[]>([]);
    const [viewState, setViewState] = useState<TranscriptsViewState | null>(null);
    const [prevMessageURL, setPrevMessageURL] = useState<string | null>(null);
    const [nextMessageURL, setNextMessageURL] = useState<string | null>(null);
    const [tagEditorOpen, setTagEditorOpen] = useState<boolean>(false);
    const [transcriptUserTags, setTranscriptUserTags] = useState<string[]>([]);
    const [mobileWidth, setMobileWidth] = useState<boolean>(false);
    const openTagEditor = () => setTagEditorOpen(true);
    const closeTagEditor = () => setTagEditorOpen(false);

    // Redux Selectors
    const { customerId } = useSelector((state: any) => state.userSlice);
    const { locationLookup } = useSelector((state: any) => state.adminSlice);
    const { data: locationData = null, isLoading: locationLoading } = useGetLocationByIdQuery({ locationId: location_id }, { skip: !!!location_id });
    const { data: locationsData = null, isLoading: locationsLoading } = useGetLocationsQuery(customerId);
    const { data: transcriptData = null, isLoading: transcriptLoading } = useGetChatTranscriptQuery(transcriptRequest, { skip: !!!transcriptRequest });
    const [setChatTranscriptTags] = useSetChatTranscriptTagsMutation();
    const { data: userSettingsData = null } = useGetUserSettingsQuery(null);
    const userSettings = useMemo(() => userSettingsData as UserSettings || {}, [userSettingsData]);
    const { data: customerSettingsData = null } = useGetCustomerSettingsQuery({ customerId }, { skip: !!!customerId });
    const customerSettings = useMemo(() => customerSettingsData as UserSettings || {}, [customerSettingsData]);
    const location = useMemo(() => locationData as Location || [], [locationData]);
    const locations = useMemo(() => locationsData as Location[] || [], [locationsData]);
    const { data: transcriptsData = null, isLoading: transcriptsLoading } = useGetChatTranscriptsByLocationIdQuery({ ...viewState }, { skip: !viewState });

    // Load/Init Transcripts List View State
    useEffect(() => {
        if (!!location_id && !!transcript && transcript.messages) {
            let eom = "";
            const lastMessageTime = transcript.messages[transcript.messages.length - 1].timestampUtc;
            if (lastMessageTime) eom = getEOMFromDateString(lastMessageTime);
            let viewStateToLoad: TranscriptsViewState = { // Baseline
                locationId: location_id,
                eom,
                guestFilter: null,
                tagsExclude: [],
                tagsInclude: [],
                limit: 60,
                offset: 0,
                page: 0
            };
            if (!!userSettings["transcriptsViewState"]) { // Load Existing
                const parsedState = JSON.parse(userSettings["transcriptsViewState"]);
                if (parsedState.locationId && location_id === parsedState.locationId) {
                    viewStateToLoad = {
                        ...viewStateToLoad,
                        ...parsedState
                    };
                }
            }
            setViewState(viewStateToLoad);
        }
    }, [location_id, transcript, userSettings]); // eslint-disable-line react-hooks/exhaustive-deps

    // Get Full Transcripts List
    useEffect(() => {
        if (transcriptsData) {
            const response = transcriptsData as TranscriptsResponse || null;
            if (response) setTranscripts(response.items || []);
            if (response.totalItemCount && viewState && response.totalItemCount < viewState.limit) {
                setViewState({ ...viewState, limit: response.totalItemCount });
            }
        }
    }, [transcriptsData]); // eslint-disable-line react-hooks/exhaustive-deps

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Location", status: locationLoading }));
        dispatch(addLoader({ loader: "Locations", status: locationsLoading }));
        dispatch(addLoader({ loader: "Transcripts", status: transcriptsLoading }));
        dispatch(addLoader({ loader: "Transcript", status: transcriptLoading }));
    }, [dispatch, locationLoading, locationsLoading, transcriptsLoading, transcriptLoading]);

    // Load Conversation
    useEffect(() => {
        if (!!location_id) {
            const availableLocationIds = locations.map(location => location.id) || [];
            if (!availableLocationIds.includes(location_id)) { // For staff, switch customer if necessary:
                if (locationLookup[location_id]) dispatch(setCustomerId(parseInt(locationLookup[location_id])));
            }
            if (!!guest_id && !!conversation_id) {
                setTranscriptRequest({
                    customerId,
                    locationId: location_id,
                    guestHash: guest_id,
                    conversationId: conversation_id
                });
            }
        }
    }, [locations, location_id, guest_id, conversation_id]); // eslint-disable-line react-hooks/exhaustive-deps

    // Render Loaded Conversation
    useEffect(() => {
        if (transcriptData) {
            const response = transcriptData as TranscriptResponse || null;
            if (response) setTranscript(response);
        }
    }, [customerId, locations, transcriptData, transcriptLoading]);

    useEffect(() => {
        if (customerSettings && 'transcriptUserTags' in customerSettings) {
            const userTagsArray = JSON.parse(customerSettings['transcriptUserTags']);
            if (Array.isArray(userTagsArray)) setTranscriptUserTags(userTagsArray);
        }
    }, [customerSettings]);

    // Find Prev/Next Messages
    useEffect(() => {
        if (!!transcripts.length) {
            const thisTranscriptIndex = transcripts.findIndex((chat: ChatHeader) => {
                return (chat.guestHash === guest_id && chat.conversationId === conversation_id);
            });
            if (thisTranscriptIndex > -1) {
                if (!!transcripts[thisTranscriptIndex - 1]) {
                    const pm = transcripts[thisTranscriptIndex - 1];
                    setPrevMessageURL(`/transcripts/${location_id}/${pm.guestHash}/${pm.conversationId}`);
                } else setPrevMessageURL(null);
                if (!!transcripts[thisTranscriptIndex + 1]) {
                    const nm = transcripts[thisTranscriptIndex + 1];
                    setNextMessageURL(`/transcripts/${location_id}/${nm.guestHash}/${nm.conversationId}`);
                } else setNextMessageURL(null);
            }
        }
    }, [transcripts, location_id, guest_id, conversation_id]);

    const toggleView = () => setMobileWidth(!mobileWidth);

    const toggleBucket = (bucket: string) => {
        if (transcript) {
            const existingTags = transcript.tags || [];
            let newTags: string[] = [];
            if (existingTags.includes(bucket)) newTags = existingTags.filter(tag => tag !== bucket);
            else newTags = [...existingTags, bucket];
            newTags = newTags.map(bucket => bucket.toLowerCase());
            setChatTranscriptTags({
                locationId: location_id,
                conversationId: conversation_id,
                guestHash: guest_id,
                tags: newTags
            });
        }
    };

    const shortId = conversation_id ? conversation_id.slice(0, 8) : null;

    return <Main header={`Chat Transcript: ${shortId}`}>

        {(transcript && location_id) && <ChatTagsEdit
            transcript={{
                locationId: location_id,
                guestHash: guest_id,
                conversationId: conversation_id
            }}
            currentTags={transcript.tags || []}
            open={tagEditorOpen}
            handleClose={closeTagEditor}
        />}

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Button component={Link} to={`/transcripts`} variant="outlined">
                            All Transcripts
                        </Button>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            <IconButton color="primary" onClick={toggleView}>{mobileWidth
                                ? <WidthNormalIcon />
                                : <WidthFullIcon />
                            }
                            </IconButton>
                            {prevMessageURL &&
                                <Button component={Link} to={prevMessageURL} variant="contained" startIcon={<ArrowCircleLeftIcon />}>
                                    Previous
                                </Button>
                            }
                            {nextMessageURL &&
                                <Button component={Link} to={nextMessageURL} variant="contained" endIcon={<ArrowCircleRightIcon />}>
                                    Next
                                </Button>
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>

                    {transcript && transcript.summary && <Grid item xs={12} display="flex" flexDirection="row">
                        <Typography variant="body1"><strong>Summary:</strong> {transcript.summary}</Typography>
                    </Grid>}

                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            {transcript && transcript.messages && transcript.messages.slice(0).reverse().map(message => {
                                const nl2br = message.text?.split("\n").filter(n => !!n).join("<br/>");
                                const timestamp = getDateTimeFromGMTISO(message.timestampUtc as string, location.timeZone, stdDateFormatSeconds);
                                const widthClass = mobileWidth ? "mobileWidth" : "desktopWidth";
                                return <Box key={message.id} px={2} py={1} className={`chatMessage ${message.from} ${widthClass}`}>
                                    {(typeof nl2br === "string")
                                        ? <span dangerouslySetInnerHTML={{ __html: linkifyHtml(nl2br, { target: "_blank" }) }} />
                                        : <span>{message.text}</span>
                                    }
                                    <span className="timestamp">
                                        <br /><span className="from">{message.from}</span>, {timestamp}
                                    </span>
                                </Box>
                            })}
                        </Stack>
                    </Grid>

                </Grid>
            </Grid>

            {/* Footer */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={1} display="flex" flexDirection="row">
                        <Stack direction="row" spacing={1}>
                            <Button
                                variant="outlined"
                                onClick={openTagEditor}
                                startIcon={<InventoryIcon />}>
                                Buckets...
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={11} display="flex" flexDirection="row-reverse">
                        {!!transcript && <Stack direction="row" spacing={1}>

                            {transcriptUserTags.map((tagName, index) => <Button
                                key={index}
                                variant={(transcript.tags && transcript.tags.includes(tagName)) ? "contained" : "outlined"}
                                onClick={() => toggleBucket(tagName)}
                                startIcon={<InventoryIcon />}>
                                {tagName}
                            </Button>)}

                            {transcriptSystemTags.map((tagName, index) => <Button
                                key={index}
                                variant={(transcript.tags && transcript.tags.includes(tagName)) ? "contained" : "outlined"}
                                onClick={() => toggleBucket(tagName)}
                                startIcon={<InventoryIcon />}>
                                {tagName}
                            </Button>)}

                            <Button
                                variant={(transcript.tags && transcript.tags.includes("flagged")) ? "contained" : "outlined"}
                                color="error"
                                onClick={() => toggleBucket("flagged")}
                                startIcon={<FlagIcon />}>
                                Flag
                            </Button>
                            <Button
                                variant={(transcript.tags && transcript.tags.includes("reviewed")) ? "contained" : "outlined"}
                                color="success"
                                onClick={() => toggleBucket("reviewed")}
                                startIcon={<FactCheckIcon />}>
                                Reviewed
                            </Button>

                        </Stack>}
                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    </Main >

};