import React from 'react';
import { useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import CloseIcon from '@mui/icons-material/Close';
import md5 from "md5";
import Gravatar from "react-gravatar";
import GuestDetails from '../../Components/GuestDetails';
import { detailSlideSx } from '../../Constants';

type ModalProps = {
    guestId: string | null;
    open: boolean;
    handleClose: () => void;
};

const GuestView: React.FC<ModalProps> = ({ guestId, open, handleClose }) => {

    const navigate = useNavigate();

    const loadGuestPage = () => {
        handleClose();
        navigate(`/guests/${guestId}`);
    }

    return <Drawer anchor="right" open={open} onClose={handleClose}>
        <Box sx={detailSlideSx}>

            <Grid container spacing={3}>

                {/* Header */}
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={4} display="flex" flexDirection="row">
                            <Box component="form" noValidate autoComplete="off">
                                {guestId && <Gravatar
                                    size={80}
                                    style={{ margin: "0 0 0 0", borderRadius: "50%" }}
                                    md5={md5(guestId)}
                                />}
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ textAlign: "center" }}>
                                <Typography variant="h6" className="bold-header">
                                    {`Guest Detail: ${guestId}`}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} display="flex" flexDirection="row-reverse">
                            <Box sx={{ textAlign: "right" }}>
                                <Stack spacing={1} direction="row-reverse">
                                    <Box><IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton></Box>
                                    <Box><IconButton onClick={loadGuestPage}>
                                        <LaunchIcon />
                                    </IconButton></Box>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Main */}
                <Grid item lg={12} md={12} sm={12}>
                    {guestId && <GuestDetails guestId={guestId} allCollapsed={true} />}
                </Grid>

            </Grid>

        </Box>
    </Drawer >

};

export default GuestView;