import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useGetUserSettingsQuery, useSaveUserSettingsMutation } from '../Redux/Services/OhWaiter';
import { useGetCustomerSettingsQuery, useSaveCustomerSettingsMutation } from '../Redux/Services/OhWaiter';
import { useGetLocationSettingsQuery, useSaveLocationSettingsMutation } from '../Redux/Services/OhWaiter';
import { UserSettings } from '../Types';
import HelperText from '../Components/HelperText';
import { explodeInput } from '../Helpers/StringUtils';
import { smallModalSx } from '../Constants';

import './ObjectBuilder.scss';

type SettingProp = {
    settingName: string;
    description?: React.ReactNode;
    locationId?: string;
    settingType?: string;
};

type ModalProps = {
    open: boolean;
    multiline?: boolean;
    value?: string | null;
    handleSave: (value: string) => void;
    handleDelete: (value: string) => void;
    handleClose: (open: boolean) => void;
};

const ItemEdit: React.FC<ModalProps> = ({ open, multiline, value, handleSave, handleDelete, handleClose }) => {

    const [newValue, setNewValue] = useState('');

    useEffect(() => {
        !!value ? setNewValue(value) : setNewValue('');
    }, [value, open]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setNewValue(e.target.value);

    return <Modal open={open} onClose={handleClose}>
        <Box sx={smallModalSx}>
            <Stack spacing={2}>
                <Typography variant="h6" component="h2" className="bold-header">
                    Edit Item
                </Typography>
                <Box component="form" sx={{ my: 3 }} noValidate autoComplete="off">
                    <TextField
                        label="Item"
                        variant="outlined"
                        fullWidth
                        multiline={!!multiline}
                        value={newValue}
                        onChange={handleChange}
                    />
                </Box>
                <Grid container sx={{ mt: 3 }}>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" color="error" onClick={() => handleDelete(newValue)}>
                                Remove
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" onClick={() => handleClose(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={() => handleSave(newValue)}>
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    </Modal>

};

const ObjectBuilder: React.FC<SettingProp> = ({ settingName, description, locationId, settingType }) => {

    const customerId = useSelector((state: any) => state.userSlice.customerId);
    const [objectJSON, setObjectJSON] = useState<any>({});
    const [groups, setGroups] = useState<string[]>([]);
    const [activeGroup, setActiveGroup] = useState<string>('');
    const [activeList, setActiveList] = useState<string[]>([]);
    const [saveReady, setSaveReady] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    // Redux Selectors (User Settings)
    const { data: userSettingsData = null } = useGetUserSettingsQuery(null);
    const userSettings = useMemo(() => userSettingsData as UserSettings || {}, [userSettingsData]);
    const [saveUserSettings] = useSaveUserSettingsMutation();

    // Redux Selectors (Customer Settings)
    const { data: customerSettingsData = null } = useGetCustomerSettingsQuery({ customerId }, { skip: !!!customerId });
    const customerSettings = useMemo(() => customerSettingsData as UserSettings || {}, [customerSettingsData]);
    const [saveCustomerSettings] = useSaveCustomerSettingsMutation();

    // Redux Selectors (Location Settings)
    const { data: locationSettingsData = null } = useGetLocationSettingsQuery({ locationId }, { skip: !!!locationId });
    const locationSettings = useMemo(() => locationSettingsData as UserSettings || {}, [locationSettingsData]);
    const [saveLocationSettings] = useSaveLocationSettingsMutation();

    useEffect(() => updateJSON(), [groups, activeList]); // eslint-disable-line react-hooks/exhaustive-deps
    const openModal = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    useEffect(() => {
        setActiveGroup('');
        setActiveList([]);
    }, [locationId]);

    useEffect(() => {
        setSaveReady(false);
        let foundExisting: any = null;
        if (!!!settingType || settingType.toLowerCase() === 'user') {
            if (settingName in userSettings) foundExisting = JSON.parse(userSettings[settingName]);
        } else if (settingType.toLowerCase() === 'customer' && !!customerId) {
            if (settingName in customerSettings) foundExisting = JSON.parse(customerSettings[settingName]);
        } else if (settingType.toLowerCase() === 'location' && !!locationId) {
            if (settingName in locationSettings) foundExisting = JSON.parse(locationSettings[settingName]);
        }
        if (foundExisting) {
            setObjectJSON(foundExisting);
            setGroups(Object.keys(foundExisting).sort());
            setSaveReady(true);
        } else {
            setSaveReady(true);
        }
    }, [userSettings, customerSettings, locationSettings]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { if (saveReady) storeObject(); }, [objectJSON]); // eslint-disable-line react-hooks/exhaustive-deps
    const storeObject = async () => {
        if (JSON.parse(JSON.stringify(objectJSON))) {
            const objString = JSON.stringify(objectJSON);
            if (!!!settingType || settingType.toLowerCase() === 'user') await saveUserSettings({ [settingName]: objString });
            else if (settingType.toLowerCase() === 'customer' && !!customerId)
                await saveCustomerSettings({ customerId, settingsObject: { [settingName]: objString } });
            else if (settingType.toLowerCase() === 'location' && !!locationId)
                await saveLocationSettings({ locationId, settingsObject: { [settingName]: objString } });
        }
    }

    const handleSave = (newValue: string) => {
        if (!!!activeGroup) {
            const newGroups = Array.from(new Set([...groups, newValue])).sort();
            setGroups(newGroups);
        } else {
            const newItems = explodeInput(newValue);
            const newList = Array.from(new Set([...activeList, ...newItems])).sort();
            setActiveList(newList);
        }
        setModalOpen(false);
    }

    const handleDelete = (newValue: string) => {
        if (!!!activeGroup) {
            const newGroups = groups.filter(item => item !== newValue);
            setGroups(newGroups);
        } else {
            const newList = activeList.filter(item => item !== newValue);
            setActiveList(newList);
        }
        setModalOpen(false);
    }

    const selectGroup = (group: string) => {
        if (activeGroup !== group) {
            setActiveGroup(group);
            setActiveList(objectJSON[group]);
        } else {
            setActiveGroup('');
            setActiveList([]);
        }
    }

    const updateJSON = () => {
        const newJSON = Object.assign({}, objectJSON);
        if (!!activeGroup && activeGroup in newJSON) newJSON[activeGroup] = activeList;
        groups.forEach(group => { if (!(group in newJSON)) newJSON[group] = []; });
        Object.keys(newJSON).forEach(group => { if (!groups.includes(group)) delete newJSON[group]; });
        if (JSON.stringify(objectJSON) !== JSON.stringify(newJSON)) setObjectJSON(newJSON);
    }

    return <React.Fragment>

        <ItemEdit
            open={modalOpen}
            multiline={!!activeGroup}
            handleSave={handleSave}
            handleDelete={handleDelete}
            handleClose={handleClose}
        />

        <Stack direction="column" spacing={1}>

            <Box p={2} className="objectBuilderWrapper">

                <Grid container>
                    <Grid item xs={3} display="flex" flexDirection="column" p={2} className="listContainer">

                        <List className="objectList">
                            {groups.map((value) => <ListItem
                                key={value}
                                className={value === activeGroup ? "active" : ""}
                                disablePadding
                                secondaryAction={<IconButton edge="end" color="error" onClick={() => handleDelete(value)}>
                                    <RemoveCircleOutlineIcon />
                                </IconButton>}>
                                <ListItemButton dense onClick={() => selectGroup(value)}>
                                    <ListItemText id={value} primary={value} />
                                </ListItemButton>
                            </ListItem>)}
                        </List>
                        <Box mt={2}>
                            <Button variant="outlined" size="small" onClick={openModal} disabled={!!activeGroup}>
                                New Group
                            </Button>
                        </Box>

                    </Grid>
                    <Grid item xs={9} display="flex" flexDirection="column" p={2} className="listContainer">

                        <List className="childList">
                            {activeList.map((value) => <ListItem
                                key={value}
                                disablePadding
                                secondaryAction={<IconButton edge="end" color="error" onClick={() => handleDelete(value)}>
                                    <RemoveCircleOutlineIcon />
                                </IconButton>}>
                                <ListItemButton dense>
                                    <ListItemText id={value} primary={value} />
                                </ListItemButton>
                            </ListItem>)}
                        </List>
                        <Box mt={2}>
                            <Button variant="outlined" size="small" onClick={openModal} disabled={!!!activeGroup}>
                                Add Items
                            </Button>
                        </Box>

                    </Grid>
                </Grid>

            </Box>

            {!!description && <HelperText>{description}</HelperText>}

            {/* <pre>{JSON.stringify(objectJSON, null, 4)}</pre> */}

            {/* <pre>{JSON.stringify({ userSettings }, null, 4)}</pre> */}

            {/* <pre>{JSON.stringify({ locationId, locationSettings }, null, 4)}</pre> */}

        </Stack>

    </React.Fragment>

};

export default ObjectBuilder;