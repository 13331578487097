import React from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CohortEditor from '../../Components/CohortEditor';
import { mediumModalSx } from '../../Constants';

type ModalProps = {
    guestId: string | null;
    locationId: string;
    open: boolean;
    handleClose: (open: boolean) => void;
};

const CohortEdit: React.FC<ModalProps> = ({ guestId, locationId, open, handleClose }) => {

    return <Modal open={open} onClose={handleClose}>
        <Box sx={mediumModalSx}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Grid item xs={6} display="flex" flexDirection="row">
                        {/* Placeholder */}
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack spacing={1} direction="row-reverse">
                            <Box><IconButton onClick={() => handleClose(false)}>
                                <CloseIcon />
                            </IconButton></Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            <CohortEditor
                guestId={guestId}
                locationId={locationId as string}
            />
        </Box>
    </Modal>

};

export default CohortEdit;