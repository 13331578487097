import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loginRequest } from '../msalConfig';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularProgress from '@mui/material/CircularProgress';
import Centered from '../Layouts/Centered';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import Alerts from '../Components/Alerts';
import LoadingBar from '../Components/LoadingBar';
import { staffRoles } from '../Constants';

const drawerWidth = 250;

type MainProps = {
    header: string,
    loadings?: string[] | undefined,
    children: React.ReactNode
};

type InputProps = {
    open: boolean
};

const Structure = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<InputProps>(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        minHeight: "100vh",
        paddingBottom: 0,
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })<InputProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const NoCustomer = () => {
    return <Typography variant="h6" className="bold-header">
        Please select a customer ID to impersonate first.
    </Typography>
}

const Main: React.FC<MainProps> = ({
    header,
    children,
}) => {

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const username = (accounts[0] && !!accounts[0].idTokenClaims) ? accounts[0].idTokenClaims.email || '' : '';

    const accountRole = useSelector((state: any) => state.adminSlice.accountRole);
    const customerId = useSelector((state: any) => state.userSlice.customerId);

    useEffect(() => {
        if (!isAuthenticated && inProgress === 'none') instance.loginRedirect(loginRequest);
    }, [isAuthenticated, inProgress]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDrawerOpen = () => {
        setOpen(true);
    }

    const handleDrawerClose = () => {
        setOpen(false);
    }

    let validCustomer = true;
    if (!!accountRole) {
        if (staffRoles.includes(accountRole) && !!!customerId) validCustomer = false;
    }

    return <React.Fragment>

        <AuthenticatedTemplate>

            <Alerts />

            <LoadingBar />

            {(inProgress !== 'none') &&
                <Centered>
                    <CircularProgress />
                </Centered>
            }

            {(inProgress === 'none') &&
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar position="fixed" open={open}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{ mr: 2, ...(open && { display: 'none' }) }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="bold-header">
                                {header}
                            </Typography>
                            <p>{username.toString()}</p>
                        </Toolbar>
                    </AppBar>
                    <Drawer variant="persistent" anchor="left" open={open} className="nav-drawer" sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}>
                        <DrawerHeader>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </DrawerHeader>
                        <Divider />
                        <Navigation accountRole={accountRole} />
                    </Drawer>
                    <Structure open={open}>
                        <DrawerHeader />
                        {validCustomer && children}
                        {!validCustomer && <NoCustomer />}
                        <Footer />
                    </Structure>
                </Box>
            }

        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
            <Centered>
                <CircularProgress />
            </Centered>
        </UnauthenticatedTemplate>

    </React.Fragment>

}

export default Main;