import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { GridCellParams, GridRowOrderChangeParams } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import { useGetButtonsByZoneIdQuery } from '../Redux/Services/OhWaiter';
import { useSaveButtonsOrderMutation } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { Button as ButtonType } from '../Types';
import Main from '../Layouts/Main';
import LocationSelector from '../Components/LocationSelector';
import ZoneSelector from '../Components/ZoneSelector';
import ButtonEdit from '../Components/Modals/ButtonEdit';
import { reorderButtonGrid, filterButtonsPayload } from '../Helpers/ButtonsOrder';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_KEY as string);

export default function Buttons() {

    const dispatch = useDispatch();
    const [editId, setEditId] = useState<number | null>(null);
    const [locationId, setLocationId] = useState<string>('');
    const [zoneId, setZoneId] = useState<string>('');
    const [buttonsOrdered, setButtonsOrdered] = useState<ButtonType[] | null>(null);
    const [nextOrdinal, setNextOrdinal] = useState<number>(0);

    const [buttonEditOpen, setButtonEditOpen] = useState(false);
    const openButtonEdit = () => setButtonEditOpen(true);
    const closeButtonEdit = () => setButtonEditOpen(false);

    // Redux Selectors
    const { data: buttonsData = null, isLoading: buttonsLoading } = useGetButtonsByZoneIdQuery({ zoneId }, { skip: !!!zoneId });
    const [saveButtonsOrder] = useSaveButtonsOrderMutation();
    const buttons = useMemo(() => buttonsData as ButtonType[] || [], [zoneId, buttonsData]); // eslint-disable-line react-hooks/exhaustive-deps

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Buttons", status: buttonsLoading }));
    }, [dispatch, buttonsLoading]);

    useEffect(() => {
        if (!!buttons.length) setNextOrdinal(buttons.length + 1);
        if (!!buttons.length) setButtonsOrdered(buttons);
        else setButtonsOrdered([]);
    }, [buttons]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateButtonOrder = async (params: GridRowOrderChangeParams) => {
        if (buttonsOrdered) {
            const buttonsReorderedSorted = reorderButtonGrid(buttonsOrdered, params) as ButtonType[];
            saveButtonsOrder({ zoneId, buttons: filterButtonsPayload(buttonsReorderedSorted) });
            setButtonsOrdered(buttonsReorderedSorted);
        }
    };

    const handleNewButton = () => {
        setEditId(null);
        openButtonEdit();
    };

    const handleButtonEdit = (id: number) => {
        setEditId(id);
        openButtonEdit();
    };

    const actionsCell = (params: GridCellParams) => {
        const clickAction = () => handleButtonEdit(parseInt(params.row.buttonId));
        return <Grid container justifyContent="flex-end" mx={1}>
            <Stack direction="row" alignItems="center" spacing={3}>
                <ModeEditIcon fontSize="small" style={{ cursor: "pointer" }} onClick={clickAction} />
            </Stack>
        </Grid>
    }

    const buttonColumns: GridColDef[] = [
        {
            field: 'title',
            headerName: 'Button',
            width: 200,
        }, {
            field: 'response',
            headerName: 'Message',
            flex: 1
        }, {
            //     field: 'ordinal',
            //     headerName: 'DB Order',
            //     width: 80
            // }, {
            //     field: 'newOrdinal',
            //     headerName: 'Order',
            //     width: 80
            // }, {
            field: 'buttonId',
            headerName: '',
            width: 120,
            renderCell: actionsCell
        }
    ];

    function noRowsOverlay() {
        return <Box style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
        }}>{(!locationId || !zoneId) ? "Please select a location and zone." : "No buttons created."}</Box>;
    }

    return <Main header="Quick Reply Buttons">

        {/* Button Edit Drawer */}
        {zoneId && <ButtonEdit zoneId={parseInt(zoneId)} buttonId={editId} nextOrdinal={nextOrdinal} open={buttonEditOpen} handleClose={closeButtonEdit} />}

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector selectionHandler={setLocationId} filter="tablet" />
                                <ZoneSelector locationId={locationId} selectionHandler={setZoneId} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Button variant="contained" onClick={handleNewButton} startIcon={<AddCircleIcon />} disabled={!(!!locationId && !!zoneId)}>
                            Add New Button
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <DataGridPro
                            columns={buttonColumns}
                            rows={(buttonsOrdered && !!buttonsOrdered.length && !!zoneId) ? buttonsOrdered : []}
                            getRowId={(row) => row.buttonId}
                            autoHeight
                            hideFooter
                            rowReordering
                            onRowOrderChange={updateButtonOrder}
                            components={{
                                RowReorderIcon: DragHandleIcon,
                                NoRowsOverlay: noRowsOverlay
                            }}
                        />

                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    </Main>

};