import { createSlice } from '@reduxjs/toolkit';

export const adminSlice = createSlice({
    name: "adminSlice",
    initialState: {
        accountRole: "user",
        customerId: null,
        locationLookup: {}
    },
    reducers: {
        setAccountRole: (state, action) => {
            state.accountRole = action.payload;
        },
        setCustomerId: (state, action) => {
            state.customerId = action.payload;
        },
        setLocationLookup: (state, action) => {
            state.locationLookup = action.payload;
        }
    }
});

export const { setAccountRole, setCustomerId, setLocationLookup } = adminSlice.actions;

export default adminSlice.reducer;