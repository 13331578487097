import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGridPro, GridToolbar, GridColDef, GridCellParams } from '@mui/x-data-grid-pro';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Tooltip from '@mui/material/Tooltip';
import { useGetModifierItemsByLocationIdQuery } from '../Redux/Services/OhWaiter';
import { addLoader } from '../Redux/Slices/Loading';
import { ModifierItem as ModifierItemType } from '../Types';
import Main from '../Layouts/Main';
import LocationSelector from '../Components/LocationSelector';
import ModifierSynonymEdit from '../Components/Modals/ModifierSynonymEdit';
import { moneyFormat } from '../Helpers/Money';

export default function Modifiers() {

    const dispatch = useDispatch();
    const [locationId, setLocationId] = useState<string>('');

    // Redux Selectors
    const { data: modifierData = null, isLoading: modifierLoading } = useGetModifierItemsByLocationIdQuery({ locationId }, { skip: !!!locationId });
    const modifier = useMemo(() => modifierData as ModifierItemType[] || [], [locationId, modifierData]); // eslint-disable-line react-hooks/exhaustive-deps

    // Loader Dispatches
    useEffect(() => {
        dispatch(addLoader({ loader: "Modifer Items", status: modifierLoading }));
    }, [dispatch, modifierLoading]);

    const [editModifierItem, setEditModifierItem] = useState<string>('');
    const [editSynonyms, setEditSynonyms] = useState<string[]>([]);
    const [editOpen, setEditOpen] = useState(false);
    const openEdit = () => setEditOpen(true);
    const closeEdit = () => setEditOpen(false);

    const priceCell = (params: GridCellParams) => moneyFormat(params.row.priceInCents);
    const synonymsCell = (params: GridCellParams) => params.row.synonyms.join(", ");

    const actionsCell = (params: GridCellParams) => {
        const editAction = () => {
            setEditModifierItem(params.row.name);
            setEditSynonyms(params.row.synonyms);
            openEdit();
        }
        return <Grid container justifyContent="flex-end" mx={1}>
            <Stack direction="row" alignItems="center" spacing={3}>
                <Tooltip title="Edit Synonyms" placement="top" arrow>
                    <ModeEditIcon fontSize="small" style={{ cursor: "pointer" }} onClick={editAction} />
                </Tooltip>
            </Stack>
        </Grid>
    };

    const modifierColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Modifier Item',
            width: 350
        },
        {
            field: 'priceInCents',
            headerName: 'Price',
            width: 80,
            renderCell: priceCell
        },
        {
            field: 'synonyms',
            headerName: 'Synonyms',
            flex: 1,
            renderCell: synonymsCell
        },
        {
            field: 'templateId',
            headerName: '',
            width: 120,
            renderCell: actionsCell
        }
    ];

    return <Main header="Menu Modifiers">

        {locationId && <ModifierSynonymEdit
            locationId={parseInt(locationId)}
            modifierItem={editModifierItem}
            existingSynonyms={editSynonyms}
            open={editOpen}
            handleClose={closeEdit}
        />}

        <Grid container spacing={3}>

            {/* Header */}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6} display="flex" flexDirection="row">
                        <Box component="form" noValidate autoComplete="off">
                            <Stack direction="row" spacing={2}>
                                <LocationSelector selectionHandler={setLocationId} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" flexDirection="row-reverse">
                        <Stack direction="row" spacing={1}>
                            {/* Placeholder */}
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            {/* Main Column */}
            <Grid item lg={12} md={12} sm={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <DataGridPro
                            columns={modifierColumns}
                            rows={(!locationId) ? [] : modifier}
                            getRowId={(row) => row.name}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: "name", sort: "asc" }],
                                },
                            }}
                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 250 },
                                    csvOptions: { disableToolbarButton: true },
                                    printOptions: { disableToolbarButton: true }
                                }
                            }}
                            disableColumnSelector
                            disableColumnFilter
                            disableDensitySelector
                            autoHeight
                            hideFooter
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Main >
};